<template>
  <div style="max-height: calc(100vh - 176px); height: calc(100vh - 176px)">
    <!-- style="height: 100%" -->
    <v-container v-if="meeting.topics.length == 0" style="height: 100%">
      <v-row style="height: 100%" align="center" justify="center" class="pt-0">
        <div>
          <v-col cols="12" class="text-center">
            <v-img
              class="mx-auto"
              contain
              max-width="600px"
              src="/images/svg_images/meetingHome.svg"
            />
          </v-col>
          <!-- <v-col cols="12" align="center">
            <v-btn
              color="button_blue"
              rounded
              class="white--text mx-6"
              depressed
              @click="addFirstTopic()"
            >
              <v-icon small left>mdi-plus</v-icon>
              {{ $t("meetings.addFirstTopic") }}
            </v-btn>
            <v-btn
              color="button_blue"
              text
              rounded
              class="white--text mx-6"
              depressed
              @click="openParticipantsPanel()"
            >
              <v-icon small left>mdi-account-group</v-icon>
              {{ $t("meetings.inviteParticipants") }}
            </v-btn> -->
          <!--
            <v-btn
              color="button_blue"
              text
              rounded
              large
              class="white--text mx-6"
              depressed
              @click="openTemplatePanel()"
            > -->
          <!-- <span :class="px4px0" style="line-height: 0">
              <customIcon color="#d2dbf7" name="template"><iconTemplate /></customIcon>
            </span> -->
          <!-- <v-icon small left>mdi-account-group</v-icon> -->
          <!-- {{ $t("templates.loadATemplate") }}
            </v-btn> -->
          <!-- </v-col> -->
          <!-- <v-col class="text-center pt-8">
            <h5 class="grey--text" v-html="$t('meetings.startToPrepare')"></h5>

            <v-btn
              class="ma-4"
              color="accent darken-2"
              depressed
              @click="openTemplatePanel()"
            >
              {{ $t("meetings.templateLoading") }}
            </v-btn>
          </v-col> -->

          <v-card class="mx-auto" color="transparent" flat max-width="300" tile>
            <h5  class="text-center text-uppercase mb-4">{{
                $t("meetings.prepareMeeting")}}</h5>
            <v-list dense color="transparent" rounded>

              <v-list-item @click="addFirstTopic()" style="background-color: white" class="mb-2 ">
                <v-list-item-icon>
                  <v-icon small color="#395A9E">mdi-plus-thick</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("meetings.addFirstTopic")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="openParticipantsPanel()"  style="background-color: white"class="mb-2 ">
                <v-list-item-icon>
                  <v-icon small  color="#395A9E">mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("meetings.inviteParticipants")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- <v-list-item @click="openTemplatePanel()"  style="background-color: white" class="mb-2 ">
                <v-list-item-icon class="pt-1">
                  <customIcon
                    width="16"
                    height="16"
                    class=""
                    color="#395A9E"
                    name="template"
                    ><iconTemplate
                  /></customIcon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("meetings.templateLoading")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list>
          </v-card>
        </div>
      </v-row>
    </v-container>

    <v-container
      style="height: 100%"
      v-if="
        meeting.topics.length && showMeetingHome && meeting.status == 'pending'
      "
    >
      <v-row align="center" justify="center" style="height: 100%">
        <v-col cols="12" class="text-center" align="center" justify="center">
          <v-card
            max-width="360"
            class="text-center mx-auto rounded-xl meetingStartInElevation"
          >
            <v-card-text class="pt-0">
              <!-- <div>start at :  {{ new Date(meeting.starts_at).toISOString() }} </div> -->
              <div v-if="isMeetingInThePast">
                <div class="py-4">
                  <span class="meetingStartInBig">
                    {{ $t("meetings.meetingShouldHaveStartedIn") }}
                  </span>
                </div>
                <span :key="startsAtRefresh">{{
                  meeting.starts_at | timeAgo
                }}</span>
              </div>
              <div v-show="!isMeetingInThePast">
                <div class="py-4">
                  <span class="meetingStartInBig">
                    {{ $t("meetings.meetingStartIn") }}
                  </span>
                </div>
                <countdown
                  :key="startsAtRefresh"
                  :endtime="meetingStartCountdown"
                  template="meetingHome"
                />
              </div>
            </v-card-text>
            <!-- <v-card-text class="pt-0">
              <div
                style="width: fit-content; display: -webkit-inline-box"
                class="mx-auto"
              >
                <v-switch
                  class="mx-auto"
                  v-model="autoStart"
                  :label="$t('meetings.autoStart')"
                ></v-switch>
              </div>
            </v-card-text> -->
            <v-card-text class="pt-0 pb-8 px-16">
              <v-btn
                large
                depressed
                color="primary"
                block
                class="white--text"
                @click="startMeeting()"
                >{{ $t("meetings.startNow") }}</v-btn
              >
            </v-card-text>
          </v-card>
          <v-btn
            color="button_blue"
            text
            rounded
            large
            class="white--text mx-6 my-8"
            depressed
            @click="openObjectivePanel()"
          >
            <v-icon small left>mdi-format-list-checks</v-icon>
            {{ $t("meetings.defineMeetingObjectives") }}
          </v-btn>
        </v-col></v-row
      >
    </v-container>

    <!-- FINISHED MEETING -->
    <v-container
      style="height: 100%"
      v-if="
        meeting.topics.length && showMeetingHome && meeting.status == 'finished'
      "
    >
      <v-row align="center" justify="center" style="height: 100%">
        <v-col cols="12" class="text-center" align="center" justify="center">
          <v-card
            max-width="460"
            class="text-center mx-auto rounded-xl meetingStartInElevation"
          >
            <div class="pt-4 pb-4">
              <span class="meetingStartInBig">
                <v-icon size="28" left color="success"
                  >mdi-text-box-check-outline</v-icon
                >{{ $t("meetings.meetingFinished") }}</span
              >
            </div>

            <v-expansion-panels
              v-if="meeting.objectives.length"
              flat
              class="text-left pa-4 px-10 pt-0"
              style="max-height: 400px"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="caption">
                  {{
                    meeting.objectives.filter((mo) => mo.done != false).length +
                    "/" +
                    meeting.objectives.length +
                    " " +
                    $t("meetings.meetingObjectivesCompleted")
                  }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  style="max-height: 360px; overflow-y: auto"
                >
                  <v-list
                    subheader
                    flat
                    v-if="meeting.objectives.length"
                    style="max-height: 320px; overflow-y: auto"
                  >
                    <template v-for="(todo, i) in meeting.objectives">
                      <v-hover v-slot:default="{}" :key="'obj_' + i">
                        <v-list-item class="objectiveItem">
                          <v-list-item-icon class="mr-2">
                            <v-icon
                              small
                              :color="todo.done ? 'success' : 'mid_grey'"
                            >
                              mdi-check-bold
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content class="py-0">
                            <v-list-item-subtitle
                              :class="{
                                lineThrought: todo.done,
                                'font-weight-bold': todo.done,
                              }"
                              class="body-2 pl-0 objectiveItemTitle caption"
                              >{{ todo.content }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-hover>
                    </template>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-card-text class="pt-0 pb-8 px-16">
              <v-btn
                large
                depressed
                color="primary"
                block
                class="white--text"
                @click="openMinutePanel"
                :disabled="loading"
              >
                <!-- @click="showMeetingMinute = true" -->
                <v-icon small left>mdi-clipboard-text-search</v-icon
                >{{ $t("misc.meetingMinutes") }}</v-btn
              >
              <v-row class="mt-4">
                <v-col cols="12" align="center" v-if="meeting.minute_sended_at">
                  <v-icon size="14" class="pr-1" color="button_blue"
                    >mdi-checkbox-marked-circle</v-icon
                  >
                  <span class="caption">
                    {{ $t("meetings.sharedByEmail") }}
                    <b> {{ meeting.minute_sended_at | timeAgo }}</b>
                  </span>
                </v-col>
                <v-col cols="12" align="center" v-else>
                  <span class="caption">
                    {{ $t("meetings.minuteNotSharedYet") }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- <div class="mx-6 my-8">
            <span class="caption">
              <v-icon size="16" color="mid_grey darken-2" left
                >mdi-information</v-icon
              >
              {{ $t("templates.templateDescription") }}
            </span>
            <br />
            <v-btn
              color="button_blue"
              text
              rounded
              large
              class="white--text"
              depressed
              @click="useAsTemplate()"
            >
              <v-icon small left>mdi-content-copy</v-icon>
              {{ $t("templates.saveAsTemplate") }}
            </v-btn>
          </div> -->
        </v-col></v-row
      >
    </v-container>
    <!-- <useMeetingAsTemplate
      :meeting="meeting"
      :display="useMeetingAsTemplate_dialog"
      @close="closeUseMeetingAsDialog"
      :redirectToTemplatesIndex="false"
    /> -->

    <!-- NEW TOPIC DIALOG -->
    <!-- <v-dialog v-model="newTopicDialog" :max-width="480" persistent>
      <template v-slot:default>
        <v-card color="white">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ $t("meetings.newTopic") }}</span>
            <v-btn icon @click="newTopicDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-0">
            <topicFormFields
              v-if="newTopicDialog"
              :displayBackButton="false"
              @close="newTopicDialog = false"
            />
          </v-card-text>
        </v-card>
      </template>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import topicFormFields from "@/components/topics/topicFormFields";
import countdown from "@/components/shared/countdown";
import useMeetingAsTemplate from "../templates/useMeetingAsTemplate";
import date from "@/mixins/date.js";
import customIcon from "@/components/custom_icons/customIcon";
import iconTemplate from "@/components/custom_icons/iconTemplate";

export default {
  name: "meetingHome",
  mixins: [date],
  components: {
    customIcon,
    iconTemplate,
    topicFormFields,
    countdown,
    useMeetingAsTemplate,
  },
  data() {
    return {
      newTopicDialog: false,
      autoStart: true,
      useMeetingAsTemplate_dialog: false,
      useMeetingAsTemplate_meeting: null,
      loading: false,
      startsAtRefresh: 0,
      meetingStartCountdown: null,
    };
  },
  props: {
    showMeetingHome: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "meeting",
      "loadingMeeting",
      "meetingFetchFailed",
      "selectedTopicId",
      "selectedTopic",
    ]),
    isMeetingInThePast() {
      this.startsAtRefresh;
      return this.isDateInThePast(this.meeting.starts_at);
    },
  },
  methods: {
    openTemplatePanel() {
      this.$emit("openTemplatePanel");
    },
    openMinutePanel() {
      this.$emit("openMinutePanel");
    },
    startMeeting() {
      this.$emit("startMeeting");
    },
    addFirstTopic() {
      this.$emit("openNewTopicDialog");
    },
    openObjectivePanel() {
      this.$emit("openObjectivePanel");
    },
    openParticipantsPanel() {
      this.$emit("openParticipantsPanel");
    },
    closeUseMeetingAsDialog() {
      this.useMeetingAsTemplate_dialog = false;
      this.useMeetingAsTemplate_meeting = null;
    },
    useAsTemplate(meeting) {
      this.useMeetingAsTemplate_dialog = true;
      this.useMeetingAsTemplate_meeting = meeting;
    },
    meetingStartCounterHander() {
      this.meetingStartCountdown = new Date(
        this.UTCDate(this.meeting.starts_at)
      ).getTime();
      let now = new Date().getTime();
      let diff = this.meetingStartCountdown - now;
      var self = this;
      if (diff > 0)
        setTimeout(() => {
          self.startsAtRefresh++;
        }, diff + 500);
      this.startsAtRefresh++;
    },
  },
  watch: {
    "meeting.starts_at"() {
      this.meetingStartCounterHander();
    },
  },
  created() {
    this.meetingStartCounterHander();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.objectiveItem {
  min-height: min-content !important;
  height: min-content !important;
}
</style>

