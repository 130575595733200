<template>
  <v-dialog content-class="rounded-lg" v-model="dialog" width="500">
    <v-card class="rounded-lg">
      <v-toolbar flat dense :color="color" dark>
        <v-icon size="20">{{ icon }}</v-icon>
        <v-toolbar-title class="pl-2 subtitle-1">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-4 body-2" v-html="message" />

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn small depressed @click="cancel()">
          {{ cancelText }}
        </v-btn>

        <v-btn
          class="white--text"
          small
          :color="color"
          depressed
          @click="action()"
        >
          <v-icon v-if="actionIcon" small class="pr-2">{{actionIcon}}</v-icon
          >{{ actionText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "customDialog",
  props: {
    actionText: {
      type: String,
      
      required: true,
    },
    cancelText: {
      type: String,
      
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "info",
      required: false,
    },
    icon: {
      type: String,
      default: "mdi-information",
      required: false,
    },
    actionIcon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    action() {
      this.$emit("action");
      this.dialog = false;
    },
    cancel() {
      this.$emit("cancel");
      this.dialog = false;
    },
  },
};
</script>
