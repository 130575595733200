<template>
  <v-card
    flat
    style="height: 100%; display: flex !important; flex-direction: column"
  >
    <!-- Meeting settings -->
    <v-card-title class="title px-0 font-weight-regular py-0">
      <v-subheader style="width: 100%" class="font-weight-bold pr-0">
        <v-icon small class="pr-2">mdi-clipboard-list-outline</v-icon>
        {{ $t("meetings.meetingAgenda") + " (" + meeting.topics.length + ")" }}
        <v-spacer></v-spacer>
        <v-list dense class="py-0 px-0">
          <v-list-item class="pr-0">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("meetings.timedTopics")
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="mr-4">
              <v-switch
                class="getcollective_switch"
                dense
                inset
                v-model="timedTopics"
                color="primary"
                @change="updateMeeting('timed_topics', timedTopics)"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-subheader>
      <v-container class="pa-0">
        <!-- Meeting's start time and duration -->
        <v-row class="px-4" wrap no-gutters>
          <!-- Start Date -->
          <v-col cols="3" class="pl-0 pr-1">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="true"
              :return-value.sync="meetingDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="
                    meetingDate == todaysDate
                      ? $t('misc.today')
                      : timeToDate(meetingDate)
                  "
                  :label="$t('meetings.startDate')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  filled
                  dense
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="meetingDate"
                no-title
                scrollable
                @change="updateMeetingDate"
              >
                <!-- <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateMenu = false">
                  {{ $t("misc.cancel") }}
                </v-btn>
                <v-btn text color="primary" @click="updateMeetingDate">
                  {{ $t("misc.ok") }}
                </v-btn> -->
              </v-date-picker>
            </v-menu>
          </v-col>

          <!-- Start Time -->
          <v-col cols="3" class="px-1">
            <div
              class="
                v-input
                v-input--is-label-active
                v-input--is-dirty
                v-input--is-readonly
                v-input--dense
                theme--light
                v-text-field
                v-text-field--filled
                v-text-field--is-booted
                v-text-field--enclosed
              "
            >
              <div class="v-input__control">
                <div class="v-input__slot">
                  <div class="v-text-field__slot">
                    <label
                      for="timeInput"
                      class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; position: absolute"
                      >{{ $t("meetings.startTime") }}</label
                    >
                    <vue-timepicker
                      name="timeInput"
                      :minute-interval="5"
                      manual-input
                      input-width="100%"
                      input-class="timePicker"
                      height="52px"
                      v-model="meetingStartTime"
                      hide-clear-button
                      drop-offset-height="48px"
                      @v-click-outside="updateMeeting('starts_at', updatedStartsAt)"
                      @close="updateMeeting('starts_at', updatedStartsAt)"
                    >
                    </vue-timepicker>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <!-- Duration -->
          <v-col cols="6" class="pl-1">
            <v-text-field
              v-model.number="meetingDuration"
              :label="$t('meetings.durationMinutesOptional')"
              placeholder=""
              filled
              dense
              autocomplete="off"
              type="number"
              :rules="durationRules"
              hide-details="auto"
              min="0"
              step="1"
              @change="roundDuration"
              @blur="updateMeeting('duration', meetingDuration)"
              @keyup.enter="updateMeeting('duration', meetingDuration)"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text class="pt-0 pb-0 px-2" style="flex-grow: 1; overflow: auto">
      <v-list nav dense>
        <v-list-item-group
          :mandatory="selectedTopicIndex != null"
          color="primary"
          :value="selectedTopicIndex"
        >
          <draggable
            v-model="topics"
            @start="drag = true"
            @end="drag = false"
            v-bind="dragOptions"
            @change="updateTopicsOrder($event)"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <template v-for="(topic, topicIndex) in meeting.topics">
                <v-hover :key="topicIndex" :disabled="drag" v-slot="{ hover }">
                  <v-list-item @click="setSelectedTopicId(topic.id)">
                    <v-list-item-avatar>
                      <v-btn
                        small
                        icon
                        class="handle"
                        color="mid_grey darken-1"
                        v-if="hover && !drag"
                      >
                        <v-icon>mdi-drag-horizontal</v-icon>
                      </v-btn>
                      <div v-else>
                        <div v-if="!topic.user">
                          <v-btn icon small
                            ><v-icon>mdi-account-group</v-icon></v-btn
                          >
                        </div>
                        <userAvatar
                          v-else
                          :size="24"
                          :show_name_in_tooltip="false"
                          :first_name="topic.user.first_name"
                          :last_name="topic.user.last_name"
                          :avatar_url="topic.user.avatar_url"
                          :avatar_border="false"
                          :email="topic.user.email"
                        />
                      </div>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip
                          class="px-0 py-0 timeline_lighter_blue--text"
                          small
                          color="transparent"
                          v-if="topic.follow_up_id"
                        >
                          <!-- <customIcon
                            v-if="topic.follow_up_id"
                            color="#545c74"
                            name="followup"
                            height="12"
                            ><iconFollowup
                          /></customIcon> -->
                        </v-chip>
                        {{ topicName(topic) }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="caption">
                        <v-icon
                          size="13"
                          class="pr-1"
                          style="padding-bottom: 1px"
                          >mdi-account-voice
                        </v-icon>
                        {{ topicSpeaker(topic) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action style="display: inline">
                      <span
                        v-if="meeting.timed_topics"
                        class="caption font-weight-bold"
                        ><v-icon
                          size="12"
                          class="pr-1"
                          style="padding-bottom: 2px"
                          >mdi-progress-clock</v-icon
                        >{{ topic.duration }}{{ $t("meetings.minutes") }}
                      </span>

                      <template v-if="hover && !drag">
                        <v-btn
                          icon
                          small
                          class="ma-0"
                          color="button_blue"
                          @click.stop="editTopic(topic)"
                          ><v-icon small> mdi-pencil </v-icon></v-btn
                        >
                        <v-btn
                          icon
                          small
                          class="ma-0"
                          color="error"
                          @click.stop="deleteTopic(topic.id)"
                          ><v-icon small> mdi-delete </v-icon></v-btn
                        >
                      </template>
                    </v-list-item-action>
                  </v-list-item>
                </v-hover>
              </template>
            </transition-group>
          </draggable>
        </v-list-item-group>
      </v-list>

      <template v-if="meeting && !meeting.topics.length">
        <v-row no-gutters class="pa-8">
          <v-alert
            color="button_blue"
            dark
            dense
            icon="mdi-information"
            border="left"
            class="caption"
          >
            {{ $t("meetings.noTopics") }}</v-alert
          >
        </v-row>
      </template>
    </v-card-text>
    <v-divider></v-divider>

    <!-- Close and new topîc button -->
    <v-card-actions>
      <!-- <v-btn
          small
          text
          @click="showMeetingAgenda = false"
          color="almost_black"
        >
          <v-icon class="pr-1" small>mdi-close</v-icon>
          {{ $t("misc.close") }}
        </v-btn> -->

      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="white--text button_blue"
        @click.stop="$emit('openNewTopicDialog')"
      >
        <v-icon small class="pr-2">mdi-plus</v-icon>
        {{ $t("meetings.addATopicOrFollowup") }}
      </v-btn>
    </v-card-actions>

    <!-- NEW TOPIC DIALOG -->
    <!-- <v-dialog v-model="newTopicDialog" :max-width="480" persistent>
      <template v-slot:default>
        <v-card color="white">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ $t("meetings.newTopic") }}</span>
            <v-btn icon @click="newTopicDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-0">
            <topicFormFields
              v-if="newTopicDialog"
              :displayBackButton="false"
              @close="newTopicDialog = false"
            />
          </v-card-text>
        </v-card>
      </template>
    </v-dialog> -->
    <!-- EDIT TOPIC DIALOG -->
    <v-dialog v-model="editTopicDialog" :max-width="480" persistent>
      <template v-slot:default>
        <v-card color="white">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{
              topicUpdateFollowupId
                ? $t("meetings.followupUpdate")
                : $t("meetings.topicUpdate")
            }}</span>
            <v-btn icon @click="editTopicDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-4 pt-0">
            <v-text-field
              v-if="topicUpdateFollowupName"
              :value="topicUpdateFollowupName"
              :label="$t('misc.title')"
              placeholder=""
              class="px-2 pt-4"
              dense
              autocomplete="off"
              disabled
            />

            <!--
            <v-text-field
              v-model="topicUpdateSubject"
              :label="$t('misc.title')"
              placeholder=""
              filled
              dense
              autocomplete="off"
              v-show="!topicUpdateFollowupId"
            />

            <v-textarea
              v-model="topicUpdateDetails"
              :label="$t('misc.description')"
              placeholder=""
              filled
              dense
              autocomplete="off"
              rows="2"
              v-show="!topicUpdateFollowupId"
            /> -->

            <v-autocomplete
              v-model="topicUpdateUserId"
              :items="meetingUsers"
              :item-text="userName"
              item-value="id"
              dense
              filled
              :label="$t('meetings.topicSpeaker')"
              clearable
            >
              <template v-slot:item="data">
                <div class="mr-4">
                  <userAvatar
                    :size="20"
                    :show_name_in_tooltip="false"
                    :first_name="data.item.first_name"
                    :last_name="data.item.last_name"
                    :avatar_url="data.item.avatar_url"
                    :email="data.item.email"
                  />
                </div>
                {{ userName(data.item) }}
              </template>
            </v-autocomplete>

            <v-text-field
              v-model.number="topicUpdateDuration"
              v-if="meeting.timed_topics"
              :label="$t('meetings.durationMinutes')"
              placeholder=""
              filled
              dense
              autocomplete="off"
              type="number"
              :rules="[cantBeNegative(true), cantBeNull(meeting.timed_topics)]"
              hide-details="auto"
              min="0"
              step="1"
              :disabled="!meeting.timed_topics"
              :hint="
                !meeting.timed_topics ? $t('meetings.youNeedTimedTopics') : ''
              "
              :persistent-hint="!meeting.timed_topics"
              @change="topicUpdateDuration = Math.round(topicUpdateDuration)"
            />
            <v-text-field
              v-else
              :value="$t('meetings.noDuration')"
              :label="$t('meetings.durationMinutes')"
              placeholder="no duration"
              filled
              dense
              disabled
              :hint="$t('meetings.youNeedTimedTopics')"
              :persistent-hint="true"
            >
            </v-text-field>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              text
              @click="editTopicDialog = false"
              color="almost_black"
              small
              depressed
            >
              {{ $t("misc.cancel") }}
            </v-btn>
            <v-btn
              small
              text
              color="error"
              @click.stop="deleteTopic(topicUpdateId)"
              ><v-icon small left> mdi-delete </v-icon
              >{{ $t("followUps.removeFromMeeting") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="white--text"
              depressed
              small
              :disabled="!canSaveTopicUpdate"
              @click="updateTopic"
              color="success"
            >
              {{ $t("meetings.udpate") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import date from "@/mixins/date";
import userAvatar from "@/components/shared/userAvatar";
import { mapActions, mapGetters } from "vuex";
import UPDATE_MEETING from "@/graphql/meetings/update.gql";
import UPDATE_TOPIC from "@/graphql/topics/update.gql";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import topicFormFields from "@/components/topics/topicFormFields";
import draggable from "vuedraggable";
import rules from "@/mixins/rules.js";
import customIcon from "@/components/custom_icons/customIcon";
import iconFollowup from "@/components/custom_icons/iconFollowup";
import meeting from "@/mixins/meeting.js";
import users from "@/mixins/users.js";

export default {
  name: "meetingAgenda",
  mixins: [date, rules, meeting, users],
  components: {
    userAvatar,
    VueTimepicker,
    topicFormFields,
    draggable,
    customIcon,
    iconFollowup,
  },
  props: {},
  data() {
    return {
      drag: false,
      topicUpdateSubject: "",
      topicUpdateDetails: "",
      topicUpdateUserId: "",
      topicUpdateDuration: 15,
      topicUpdateId: "",
      topicUpdateFollowupId: false,
      topicUpdateFollowupName: "",
      editTopicDialog: false,
      newTopicDialog: false,
      showMeetingAgenda: false,
      dateMenu: false,
      todaysDate: new Date().toISOString().substr(0, 10),
      meetingDate: new Date().toISOString().substr(0, 10),
      meetingStartTime: "",
      meetingDuration: 0,
      timedTopics: true,
      loading: false,
      durationRules: [
        (value) => value >= 0 || this.$t("inputRules.cantBeNegative"),
      ],
    };
  },
  computed: {
    ...mapGetters([
      "meeting",
      "loadingMeeting",
      "meetingUsers",
      "meetingFetchFailed",
      "selectedTopic",
      "selectedTopicId",
      "selectedTopicIndex",
      "currentTopicIndex",
      "topicsRefresh",
    ]),
    canSaveTopicUpdate() {
      if (this.meeting.timed_topics && this.topicUpdateDuration < 1)
        return false;
      if (!this.topicUpdateFollowupId) return this.topicUpdateSubject != "";
      return true;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    updatedStartsAt() {
      return this.formatStrDate(`${this.meetingDate} ${this.meetingStartTime}`);
    },
    minPosition() {
      let min = Infinity;
      this.meeting.topics.forEach((e) => {
        if (e.position < min) min = e.position;
      });
      return min;
    },
    topics: {
      get() {
        this.topicsRefresh;
        return this.meeting.topics;
      },
      set(value) {
        let topics_positions = [];

        for (var i = 0; i < value.length; i++)
          topics_positions.push({
            id: value[i].id,
            position: i + this.minPosition,
          });

        this.setTopicsPositions({ topics_positions: topics_positions });
      },
    },
  },
  methods: {
    ...mapActions([
      "openDestroyDialog",
      "setTopicsPositions",
      "setSelectedTopicId",
      "destroyResourceWithDialog",
    ]),
    updateMeetingDate() {
      this.$refs.dateMenu.save(this.meetingDate);
      this.updateMeeting("starts_at", this.updatedStartsAt);
    },
    validHoursMinutes(timestr) {
      return /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(timestr);
    },
    roundDuration() {
      console.log(this.meetingDuration, Math.round(this.meetingDuration));
      this.meetingDuration = Math.round(this.meetingDuration);
    },
    editTopic(topic) {
      this.topicUpdateSubject = topic.subject;
      this.topicUpdateDetails = topic.details;
      this.topicUpdateUserId = topic.user_id;
      this.topicUpdateDuration = topic.duration;
      this.topicUpdateId = topic.id;

      this.topicUpdateFollowupId = topic.follow_up_id;

      if (topic.follow_up_id)
        this.topicUpdateFollowupName = this.topicName(topic);
      else this.topicUpdateFollowupName = "";

      this.editTopicDialog = true;
    },
    deleteTopic(topicId) {
      this.destroyResourceWithDialog({
        id: topicId,
        model: "topic",
        onDestroy: () => {
          this.editTopicDialog = false;
        },
      });
    },
    updateTopic() {
      if (this.loading) return;
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: UPDATE_TOPIC,
          variables: {
            id: this.topicUpdateId,
            subject: this.topicUpdateSubject,
            details: this.topicUpdateDetails,
            user_id: this.topicUpdateUserId,
            duration: this.topicUpdateDuration,
          },
        })
        .then(({ data }) => {
          this.loading = false;
          console.log(data.update_topic);
          this.editTopicDialog = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    updateTopicsOrder({ added, removed, moved }) {
      if (!moved) return;
      this.$apollo
        .mutate({
          mutation: UPDATE_TOPIC,
          variables: {
            id: moved.element.id,
            position: moved.newIndex + this.minPosition,
          },
        })
        .then(() => {})
        .catch((error) => {
          this.sendError(error);
        });
    },
    updateMeeting(field, value) {
      if (this.loadingMeeting || this.loading) return;
      if (this.meeting[field] === value) return;
      this.loading = true;
      let vars = { id: this.meeting.id };
      vars[field] = value;

      this.$apollo
        .mutate({
          mutation: UPDATE_MEETING,
          variables: vars,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.sendError(e);
          console.log(e);
        });
    },
    resetMeeting() {
      this.meetingDate = this.simpleDate(
        new Date(this.UTCDate(this.meeting.starts_at))
      );
      let temp = new Date(this.UTCDate(this.meeting.starts_at));
      this.meetingStartTime =
        String(temp.getHours()).padStart(2, "0") +
        ":" +
        String(temp.getMinutes()).padStart(2, "0");
      this.meetingDuration = this.meeting.duration;
      this.timedTopics = this.meeting.timed_topics;
    },
  },
  created() {
    this.resetMeeting();
  },
  watch: {
    "meeting.starts_at"() {
      this.resetMeeting();
    },
    "meeting.duration"() {
      this.resetMeeting();
    },
    "meeting.timed_topics"() {
      this.resetMeeting();
    },
  },
  beforeDestroy() {},
};
</script>

<style>
.flip-list {
  transition: transform 0.3s;
}
</style>


