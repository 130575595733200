<template>
  <v-container fluid style="height: 100%" class="pa-0">
    <v-card
      color="transparent"
      flat
      class="mx-auto"
      tile
      style="height: calc(100vh - 124px); max-width: 820px"
    >
      <v-app-bar class="mx-0 mb-1" dense color="transparent" flat>
        <v-btn @click="close()" text color="almost_black">
          <v-icon class="pr-1" small>mdi-arrow-left-thick</v-icon>
          {{ $t("meetingMinutes.close") }}
        </v-btn>
        <v-spacer></v-spacer>



        <template v-if="meeting.minute_sended_at">
          <v-icon size="14" class="pr-1" color="button_blue"
            >mdi-checkbox-marked-circle</v-icon
          >
          <span class="caption">
            {{ $t("meetings.sharedByEmail") }}
            <b> {{ meeting.minute_sended_at | timeAgo }}</b>
          </span>
        </template>
        <template v-else>
          <span class="caption">
            {{ $t("meetings.minuteNotSharedYet") }}
          </span>
        </template>

        <v-btn
          depressed
          small
          color="button_blue"
          class="white--text ml-4"
          @click="sendMeetingMinutes"
          :disabled="loading"
        >
          <v-icon small left>mdi-share-variant</v-icon>
          {{ $t("meetingMinutes.share") }}
        </v-btn>

          <v-btn
          depressed
          small
          color="error"
          class="white--text ml-4"
          @click="printMinute()"><v-icon left>mdi-download</v-icon>{{$t("followUps.exportPDF")}}</v-btn>
      </v-app-bar>
      <v-card
        color="white"
        class="pa-8 pt-6 mx-auto"
        tile
        style="height: calc(100vh - 200px); overflow-y: auto; max-width: 820px"
        id="printContent"
      >
        <div id="reset-this-root" class=".reset-this">
          <div style="table-layout: fixed; "   v-html="meetingMinuteHtml"></div>
        </div>
      </v-card>
    </v-card>
    <iframe
      name="print_frame"
      width="0"
      height="0"
      frameborder="0"
      src="about:blank"
    ></iframe>

    <shareMinute ref="shareMinute" :content="meetingMinuteHtml" />
  </v-container>
  <!-- <v-card color="transparent">
    <v-btn @click="printMinute()">Print</v-btn>
    <v-card
      color="white"
      class="px-8 mb-16 elevation-0 mx-auto"
      tile
      style="height: calc(100vh - 220px); overflow-y: auto; max-width: 720px"
    >
      <v-toolbar color="transparent">
        <v-toolbar-title>{{
          $t("meetingMinutes.meetingMinutePreview")
        }}</v-toolbar-title>
      </v-toolbar>

      <div id="printContent" v-html="meetingMinuteHtml"></div>
    </v-card>
    <iframe
      name="print_frame"
      width="0"
      height="0"
      frameborder="0"
      src="about:blank"
    ></iframe>
  </v-card> -->
</template>

<script>
import { mapGetters } from "vuex";
import minute_header from "@/assets/templates_html/minutes/minute_header.html";
import minute_logo from "@/assets/templates_html/minutes/minute_logo.html";
import minute_summary from "@/assets/templates_html/minutes/minute_summary.html";
import minute_card_title from "@/assets/templates_html/minutes/minute_card_title.html";
import minute_summary_decisions from "@/assets/templates_html/minutes/minute_summary_decisions.html";
import minute_summary_tasks from "@/assets/templates_html/minutes/minute_summary_tasks.html";
import minute_participants from "@/assets/templates_html/minutes/minute_participants.html";
import minute_objectives from "@/assets/templates_html/minutes/minute_objectives.html";
import minute_objective_item from "@/assets/templates_html/minutes/minute_objective_item.html";
import minute_topic from "@/assets/templates_html/minutes/minute_topic.html";
import minute_topic_feed_item_task from "@/assets/templates_html/minutes/minute_topic_feed_item_task.html";
import minute_card_content from "@/assets/templates_html/minutes/minute_card_content.html";
import minute_topic_feed_item_decision from "@/assets/templates_html/minutes/minute_topic_feed_item_decision.html";
import minute_row_spacer from "@/assets/templates_html/minutes/minute_row_spacer.html";
import date from "@/mixins/date.js";
import users from "@/mixins/users.js";
import meeting from "@/mixins/meeting.js";
import shareMinute from "@/components/minutes/share_minute";

export default {
  name: "minute",
  mixins: [date, users, meeting],
  components: { shareMinute },
  data() {
    return {
      loading: false,
      topics: [],
      minute_header,
      minute_logo,
      minute_summary,
      minute_summary_decisions,
      minute_summary_tasks,
      minute_participants,
      minute_objectives,
      minute_objective_item,
      minute_card_title,
      minute_card_content,
      minute_feed_item: {
        task: minute_topic_feed_item_task,
        decision: minute_topic_feed_item_decision,
      },
      minute_topic,
      minute_row_spacer,
      minuteSettings: {
        show_logo: true,
        show_summary_decisions: true,
        show_summary_tasks: true,
        show_meeting_objectives: false,
        show_participants_list: true,
        show_attendees_record: true,
      },
      logo_url: null,
      topicsQuery: false,
    };
  },
  computed: {
    ...mapGetters([
      "meetingId",
      "meeting",
      "selectedOrganisation",
      "objectivesRefresh",
      "meetingUsers",
      "followUpCards",
      "topicCards",
      "cardRefresh",
    ]),
    meetingMinuteHtml() {
      this.cardRefresh;
      return (
        this.meetingMinuteHeaderHtml +
        this.meetingSummaryHtml +
        this.meetingParticipants +
        this.meetingObjectives +
        this.meetingTopics
      );
    },
    meetingMinuteHeaderHtml() {
      let html = this.minute_header.replace(
        "[MEETING_NAME]",
        this.meeting.title
      );
      html = html.replace(
        "[MEETING_DATE]",
        this.friendlyDate(this.meeting.starts_at)
      );

      if (this.minuteSettings.show_logo) {
        if (!this.logo_url && this.selectedOrganisation.picture_url) {
          this.logo_url = this.selectedOrganisation.picture_url;
        }
        if (this.logo_url) {
          let imgHtml = this.minute_logo.replace("[LOGO_URL]", this.logo_url);
          html = html.replace("[LOGO]", imgHtml);
        }
      } else html = html.replace("[LOGO]", "");
      return html;
    },
    meetingSummaryHtml() {
      if (
        !this.minuteSettings.show_summary_decisions &&
        !this.minuteSettings.show_summary_tasks
      )
        return "";

      let summary_wrapper_html = this.minute_summary;
      let summary_decisions_items_html = "";
      let summary_tasks_items_html = "";

      this.meeting.topics.forEach((topic) => {
        let cards;
        if (topic.follow_up_id)
          cards = this.followUpCards(topic.follow_up_id).sort(
            this.sortByCreatedAt
          );
        else cards = this.topicCards(topic.id).sort(this.sortByCreatedAt);

        cards = cards.filter(
          (e) => e.last_update_at_meeting_id === this.meetingId
        );
        cards.forEach((card) => {
          card.feed_items.forEach((fi) => {
            if (fi.feed_item_type == "decision")
              summary_decisions_items_html += this.minute_feed_item.decision
                .replace(
                  "[FEED_ITEM_CONTENT]",
                  this.inlineFeedItemsTablesCss(fi.content)
                )
                .replace("[USER]", this.userInitials(fi.user));

            // Task
            if (fi.feed_item_type == "task") {
              let users_html = fi.users.length
                ? "<p><span style='font-size: 12px; color: #888'>[USERS]</span></p>"
                : "";
              summary_tasks_items_html += this.minute_feed_item.task
                .replace("[CHECKED]", fi.done ? "checked" : "")
                .replace(
                  "[TASK_CONTENT]",
                  fi.content.replaceAll(
                    "<p>",
                    "<p style='margin-bottom: 6px'>"
                  ) +
                    users_html.replace(
                      "[USERS]",
                      "<b>Assigned to: </b>" +
                        this.usersAsStringList(fi.users, ", ")
                    )
                )
                .replace(
                  "text-decoration: inherit; ",
                  fi.done ? "text-decoration: line-through; " : ""
                );
            }
          });
        });
      });

      let summary_decisions_section_html =
        this.minute_summary_decisions.replace(
          "[CONTENT]",
          summary_decisions_items_html
            ? summary_decisions_items_html
            : '<p style="font-family: Heebo, sans-serif; font-size: 14px;">No decisions taken.</p>'
        );
      let summary_tasks_section_html = this.minute_summary_tasks.replace(
        "[CONTENT]",
        summary_tasks_items_html
          ? summary_tasks_items_html
          : '<p style="font-family: Heebo, sans-serif; font-size: 14px;">No actions taken.</p>'
      );

      let html = summary_wrapper_html.replace(
        "[DECISIONS_BLOC]",
        this.minuteSettings.show_summary_decisions
          ? summary_decisions_section_html
          : ""
      );

      html = html.replace(
        "[TASKS_BLOC]",
        this.minuteSettings.show_summary_tasks ? summary_tasks_section_html : ""
      );

      if (
        this.minuteSettings.show_summary_decisions &&
        this.minuteSettings.show_summary_tasks
      )
        html = html.replace("[SUMMARY_TITLE]", "Actions and decisions summary");

      if (
        this.minuteSettings.show_summary_decisions &&
        !this.minuteSettings.show_summary_tasks
      )
        html = html.replace("[SUMMARY_TITLE]", "Decisions summary");

      if (
        !this.minuteSettings.show_summary_decisions &&
        this.minuteSettings.show_summary_tasks
      )
        html = html.replace("[SUMMARY_TITLE]", "Actions summary");

      return html;
    },
    meetingParticipants() {
      if (!this.minuteSettings.show_participants_list) return "";

      let html = this.minute_participants.replace(
        "[PARTICIPANTS]",
        this.usersAsStringList(this.meetingUsers, ", ")
      );

      return html;
    },
    meetingObjectives() {
      this.objectivesRefresh;

      if (!this.minuteSettings.show_meeting_objectives) return "";

      let objectives_html = "No meeting objectives were set for the meeting.";

      if (this.meeting.objectives.length) {
        objectives_html = "";

        this.meeting.objectives.forEach((obj) => {
          let objective_item_html = this.minute_objective_item
            .replace("[CHECKED]", obj.done ? "checked" : "")
            .replace("[OBJECTIVE]", obj.content)
            .replace(
              "text-decoration: inherit; ",
              obj.done ? "text-decoration: line-through; " : ""
            );
          objectives_html += objective_item_html;
        });
      }

      let html = this.minute_objectives.replace(
        "[MEETING_OBJECTIVES]",
        objectives_html
      );

      return html;
    },
    meetingTopics() {
      let html = "";
      this.meeting.topics.forEach((topic) => {
        html += this.minute_topic.replace(
          "[TOPIC_NAME]",
          this.topicName(topic)
        );

        let cards;
        if (topic.follow_up_id)
          cards = this.followUpCards(topic.follow_up_id).sort(
            this.sortByCreatedAt
          );
        else cards = this.topicCards(topic.id).sort(this.sortByCreatedAt);

        cards = cards.filter(
          (e) => e.last_update_at_meeting_id === this.meetingId
        );

        if (!cards.length)
          html = html.replace("[TOPIC_CONTENT]", "No notes taken.");
        else {
          let cards_html = "";
          let card_html = "";

          cards.forEach((card, cardIndex) => {
            card_html = "";
            /* Card Title */
            if (card.title) {
              card_html += this.minute_card_title.replace(
                "[CARD_TITLE]",
                card.title
              );

              card_html += this.minute_row_spacer;
            }
            /* Card Decisions */
            card.decisions.forEach((fi) => {
              card_html += this.minute_feed_item[fi.feed_item_type]
                .replace(
                  "[FEED_ITEM_CONTENT]",
                  this.inlineFeedItemsTablesCss(fi.content)
                )
                .replace("[USER]", this.userInitials(fi.user));
            });

            if (card.decisions.length) card_html += this.minute_row_spacer;

            /* Card Content */
            if (card.content && card.content != "<p></p>") {
              card_html += this.minute_card_content.replace(
                "[FEED_ITEM_CONTENT]",
                this.inlineFeedItemsTablesCss(card.content || "")
              );
            }

            /* Card Tasks */
            card.tasks.forEach((fi) => {
              let users_html = fi.users.length
                ? "<p style='margin-bottom: 0px'><span style='font-size: 12px; color: #888'>[USERS]</span></p>"
                : "";
              let task_html = this.minute_feed_item.task
                .replace("[CHECKED]", fi.done ? "checked" : "")
                .replace(
                  "[TASK_CONTENT]",
                  fi.content.replaceAll(
                    "<p>",
                    "<p style='margin-bottom: 6px'>"
                  ) +
                    users_html.replace(
                      "[USERS]",
                      "<b>Assigned to: </b>" +
                        this.usersAsStringList(fi.users, ", ")

                    )
                )
                .replace(
                  "text-decoration: inherit; ",
                  fi.done ? "text-decoration: line-through; " : ""
                );
              card_html += task_html;
            });

            if (card.tasks.length ) card_html += this.minute_row_spacer;

            // Divider
            if (card_html && cardIndex < cards.length-1)
              card_html +=
                "<hr style='width: 120px; border: 1px solid #fff;    border-bottom-color: #ddd; margin-left: 0px; margin-bottom: 16px'/>";
            //  margin-bottom: 28px; margin-top: 12px
            cards_html += card_html;
          });
          html = html.replace("[TOPIC_CONTENT]", cards_html);
        }
        //  html = html.replace("[TOPIC_CONTENT]",topic.feed_items.map((fi) => fi.content));
      });
      return html;
    },
  },
  methods: {
    sortByCreatedAt(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    },
    close() {
      this.$emit("close");
    },
    inlineFeedItemsTablesCss(content) {
      let inlineTABLE =
        '<table style="border-collapse: collapse; table-layout: fixed; width: 100%; margin: 0; overflow: hidden;"';
      let inlineTD =
        '<td style="min-width: 1em; border: 1px solid #ddd; padding: 5px 5px; vertical-align: top; box-sizing: border-box; position: relative;"';
      let inlinedCss = content.replaceAll("<td", inlineTD);
      inlinedCss = inlinedCss.replaceAll("<table", inlineTABLE);
      return inlinedCss;
    },
    printMinute() {
      window.frames["print_frame"].document.body.innerHTML =
        document.getElementById("printContent").innerHTML;
      window.frames["print_frame"].window.focus();
      window.frames["print_frame"].window.print();
    },
    sendMeetingMinutes() {
      this.$refs.shareMinute.dialog = true;
    },
    userInitials(user) {
      if (!user) return "";

      let firstname = user.first_name || "";
      let last_name = user.last_name || "";
      return firstname.charAt(0) + last_name.charAt(0);
    }
  },
};
</script>

<style  lang="scss" >
#reset-this-root {
  all: initial;
  * {
    all: revert;
  }
  p {
    margin: 0;
    margin-bottom: 15px;
  }
}
</style>

