<template>
  <v-hover :disabled="dragging" v-slot="{ hover }">
    <v-row class="pb-1 my-0 px-4" justify="start" align="start">
      <v-col cols="12" class="px-0">
        <v-card
          tile
          flat
          :color="
            hover &&
            (item.feed_item_type == 'task' ||
              item.feed_item_type == 'note' ||
              item.feed_item_type == 'decision')
              ? 'light_grey'
              : 'transparent'
          "
          class="px-2 py-1"
          :style="
            'border-left: 4px solid ' +
            borderColor(item.feed_item_type) +
            ' !important'
          "
        >
          <!-- hover menu edition -->
          <v-card
            class="elevation-4"
            v-if="hover"
            style="position: absolute; right: 12px; top: -8px; z-index: 2"
          >
            <v-btn
              v-if="
                item.feed_item_type == 'task' ||
                item.feed_item_type == 'note' ||
                item.feed_item_type == 'decision'
              "
              icon
              small
              color="button_blue"
              @click="$emit('editItem', item)"
              ><v-icon small> mdi-pencil </v-icon></v-btn
            >
            <v-btn icon small color="error" @click="deleteFeedItem(item)"
              ><v-icon small> mdi-delete </v-icon></v-btn
            >
          </v-card>

          <!-- DECISION, NOTE OR TASK -->
          <div
            :class="wrapperClass(item.feed_item_type)"
            v-if="
              item.feed_item_type == 'task' ||
              item.feed_item_type == 'note' ||
              item.feed_item_type == 'decision'
            "
          >
            <div v-if="item.feed_item_type == 'task'">
              <v-simple-checkbox
                v-model="checkbox"
                @input="toggleTaskDone($event)"
                color="item_task"
                class="pa-0 ma-0"
              ></v-simple-checkbox>
            </div>

            <div
              class="body-2 editor__content feed_item_html"
              :class="{
                lineThrought: item.feed_item_type == 'task' && checkbox,
                caption: item.feed_item_type == 'task',
              }"
              v-html="item.content"
              style="font-size: 0.9em !important"
            ></div>
          </div>

          <!-- FILE -->
          <template v-if="item.feed_item_type == 'file'">
            <fileItem
              :mobileVersion="mobileVersion"
              :file="item"
              @openFile="openFile"
            />
          </template>

          <!-- URL -->
          <template v-if="item.feed_item_type == 'url'">
            <assetUrlItem :mobileVersion="mobileVersion" :url="item" />
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import fileItem from "@/components/shared/fileItem";
import assetUrlItem from "@/components/shared/assetUrlItem";
import UPDATE_FEED_ITEM from "@/graphql/feed_items/update.gql";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import { mapActions } from "vuex";
import date from "@/mixins/date.js";

export default {
  name: "feed_item",
  mixins: [date],
  data() {
    return {
      checkbox: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    dragging: {
      type: Boolean,
      required: false,
      default: false,
    },
    mobileVersion: {
      type: Boolean,
      required: false,
    },
  },
  components: { fileItem, assetUrlItem, userAvatarGroup },
  computed: {},
  methods: {
    ...mapActions(["openDestroyDialog"]),
    deleteFeedItem(item) {
      this.openDestroyDialog({
        id: item.id,
        model: "feed_item",
        onDestroy: () => {},
        title: this.$t("feedItems.removeAlertTitle").replace(
          "<feed_item_type>",
          item.feed_item_type
        ),
        message: this.$t("feedItems.removeAlertMessage").replace(
          "<feed_item_type>",
          item.feed_item_type
        ),
      });
    },
    wrapperClass(type) {
      return "grid_wrapper_mobile " + type + "_wrapper_mobile";
    },
    borderColor(type) {
      switch (type) {
        case "task":
          return "#46a4bf";
        case "decision":
          return "#ff356a";

        case "note":
          return "#454e68";
      }

      return "white";
    },
    toggleTaskDone(evt) {
      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: {
            id: this.item.id,
            done: evt,
          },
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          this.sendError(error);
        });
    },
    openFile(event) {
      this.$emit("openFile", event);
    },
  },
  watch: {
    item() {
      this.checkbox = this.item.done;
    },
  },
  created() {
    this.checkbox = this.item.done;
  },
};
</script>

<style>
.grid_wrapper_mobile {
  display: grid;
  grid-template-columns: 78px 1fr;
  grid-gap: 10px;
  grid-auto-rows: auto;
  padding-bottom: 8px;
}

.note_wrapper_mobile {
  grid-template-columns: 1fr;
}

.decision_wrapper_mobile {
  grid-template-columns: 1fr;
}

.task_wrapper_mobile {
  grid-template-columns: 22px 1fr;
}

.feed_item_html p:last-of-type {
  margin-bottom: 0px;
}
</style>

