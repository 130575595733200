var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{attrs:{"disabled":_vm.dragging},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"pb-1 my-1 px-4",attrs:{"justify":"start","align":"start"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-2",attrs:{"flat":"","color":hover &&
          (_vm.item.feed_item_type == 'task' ||
            _vm.item.feed_item_type == 'note' ||
            _vm.item.feed_item_type == 'decision')
            ? 'light_grey'
            : 'transparent'}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"handle",staticStyle:{"position":"absolute","left":"-28px"},attrs:{"small":"","icon":"","color":"mid_grey darken-1"}},[_c('v-icon',[_vm._v("mdi-drag-horizontal")])],1),(hover)?_c('v-card',{staticClass:"elevation-4",staticStyle:{"position":"absolute","right":"12px","top":"-8px","z-index":"2"}},[(
              _vm.item.feed_item_type == 'task' ||
              _vm.item.feed_item_type == 'note' ||
              _vm.item.feed_item_type == 'decision'
            )?_c('v-btn',{attrs:{"icon":"","small":"","color":"button_blue"},on:{"click":function($event){return _vm.$emit('editItem', _vm.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteFeedItem(_vm.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e(),(
            _vm.item.feed_item_type == 'task' ||
            _vm.item.feed_item_type == 'note' ||
            _vm.item.feed_item_type == 'decision'
          )?_c('div',{class:_vm.wrapperClass(_vm.item.feed_item_type)},[_c('div',[_c('div',{class:_vm.chipWrapperClass(_vm.item.feed_item_type)}),_c('v-chip',{class:'item_' + _vm.item.feed_item_type + '--text ml-3 mr-4',attrs:{"color":'item_' + _vm.item.feed_item_type + '_background',"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t("feedItems." + _vm.item.feed_item_type))+" ")])],1),(_vm.item.feed_item_type == 'task')?_c('div',[_c('v-simple-checkbox',{staticClass:"pa-0 ma-0",attrs:{"color":"item_task"},on:{"input":function($event){return _vm.toggleTaskDone($event)}},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1):_vm._e(),_c('div',{staticClass:"body-2 editor__content feed_item_html",class:{
              lineThrought: _vm.item.feed_item_type == 'task' && _vm.checkbox,
              caption: _vm.item.feed_item_type == 'task',
            },domProps:{"innerHTML":_vm._s(_vm.item.content)}}),(_vm.item.feed_item_type == 'task')?_c('v-row',{staticClass:"justify-end",attrs:{"wrap":"","no-gutters":""}},[(_vm.item.users && _vm.item.users.length)?_c('userAvatarGroup',{staticClass:"mt-1",attrs:{"limit":2,"users":_vm.item.users,"size":22,"disabledMenu":false,"label":_vm.$t('feedItems.taskMember')}}):_vm._e(),(_vm.item.due_date)?_c('v-btn',{attrs:{"small":"","rounded":"","depressed":"","text":"","color":"mid_grey darken-1"}},[_vm._v(" "+_vm._s(_vm.friendlyDate(_vm.item.due_date))+" ")]):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.item.feed_item_type == 'file')?[_c('fileItem',{attrs:{"mobileVersion":_vm.mobileVersion,"file":_vm.item},on:{"openFile":_vm.openFile}})]:_vm._e(),(_vm.item.feed_item_type == 'url')?[_c('assetUrlItem',{attrs:{"mobileVersion":_vm.mobileVersion,"url":_vm.item}})]:_vm._e()],2)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }