<template>
  <v-hover :disabled="dragging" v-slot="{ hover }">
    <v-row class="pb-1 my-1 px-4" justify="start" align="start">
      <v-col cols="12" class="pa-0">
        <v-card
          flat
          :color="
            hover &&
            (item.feed_item_type == 'task' ||
              item.feed_item_type == 'note' ||
              item.feed_item_type == 'decision')
              ? 'light_grey'
              : 'transparent'
          "
          class="pa-2"
        >
          <v-btn
            small
            icon
            class="handle"
            color="mid_grey darken-1"
            v-show="hover"
            style="position: absolute; left: -28px"
          >
            <v-icon>mdi-drag-horizontal</v-icon></v-btn
          >

          <!-- hover menu edition -->
          <v-card
            class="elevation-4"
            v-if="hover"
            style="position: absolute; right: 12px; top: -8px; z-index: 2"
          >
            <v-btn
              v-if="
                item.feed_item_type == 'task' ||
                item.feed_item_type == 'note' ||
                item.feed_item_type == 'decision'
              "
              icon
              small
              color="button_blue"
              @click="$emit('editItem', item)"
              ><v-icon small> mdi-pencil </v-icon></v-btn
            >
            <v-btn icon small color="error" @click="deleteFeedItem(item)"
              ><v-icon small> mdi-delete </v-icon></v-btn
            >
          </v-card>

          <!-- DECISION, NOTE OR TASK -->
          <div
            :class="wrapperClass(item.feed_item_type)"
            v-if="
              item.feed_item_type == 'task' ||
              item.feed_item_type == 'note' ||
              item.feed_item_type == 'decision'
            "
          >
            <div>
              <div :class="chipWrapperClass(item.feed_item_type)"></div>
              <v-chip
                :color="'item_' + item.feed_item_type + '_background'"
                :class="'item_' + item.feed_item_type + '--text ml-3 mr-4'"
                small
                label
              >
                {{ $t("feedItems." + item.feed_item_type) }}
              </v-chip>
            </div>

            <div v-if="item.feed_item_type == 'task'">
              <v-simple-checkbox
                v-model="checkbox"
                @input="toggleTaskDone($event)"
                color="item_task"
                class="pa-0 ma-0"
              ></v-simple-checkbox>
            </div>

            <div
              class="body-2 editor__content feed_item_html"
              :class="{
                lineThrought: item.feed_item_type == 'task' && checkbox,
                caption: item.feed_item_type == 'task',
              }"
              v-html="item.content"
            ></div>

            <v-row
              wrap
              no-gutters
              v-if="item.feed_item_type == 'task'"
              class="justify-end"
            >
              <userAvatarGroup
                :limit="2"
                :users="item.users"
                :size="22"
                :disabledMenu="false"
                class="mt-1"
                :label="$t('feedItems.taskMember')"
                v-if="item.users && item.users.length"
              />

              <v-btn
                small
                v-if="item.due_date"
                rounded
                depressed
                text
                color="mid_grey darken-1"
              >
                {{ friendlyDate(item.due_date) }}
              </v-btn>
            </v-row>
          </div>

          <!-- FILE -->
          <template v-if="item.feed_item_type == 'file'">
            <fileItem :mobileVersion="mobileVersion" :file="item" @openFile="openFile" />
          </template>

          <!-- URL -->
          <template v-if="item.feed_item_type == 'url'">
            <assetUrlItem :mobileVersion="mobileVersion" :url="item" />
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import fileItem from "@/components/shared/fileItem";
import assetUrlItem from "@/components/shared/assetUrlItem";
import UPDATE_FEED_ITEM from "@/graphql/feed_items/update.gql";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import { mapActions } from "vuex";
import date from "@/mixins/date.js";

export default {
  name: "feed_item",
  mixins: [date],
  data() {
    return {
      checkbox: false,
    };
  },
  props: {
    item: {
      type: Object,
    },
    dragging: {
      type: Boolean,
      required: false,
      default: false,
    },
      mobileVersion: {
      type: Boolean,
      required: false,
    },
  },
  components: { fileItem, assetUrlItem, userAvatarGroup },
  computed: {},
  methods: {
    ...mapActions(["openDestroyDialog"]),
    deleteFeedItem(item) {
      this.openDestroyDialog({
        id: item.id,
        model: "feed_item",
        onDestroy: () => {},
        title: this.$t("feedItems.removeAlertTitle").replace(
          "<feed_item_type>",
          item.feed_item_type
        ),
        message: this.$t("feedItems.removeAlertMessage").replace(
          "<feed_item_type>",
          item.feed_item_type
        ),
      });
    },
    wrapperClass(type) {
      return "grid_wrapper " + type + "_wrapper";
    },
    chipWrapperClass(type) {
      switch (type) {
        case "task":
          return "itemTask itemChip";
        case "decision":
          return "itemDecision itemChip";
        case "note":
          return "itemNote itemChip";
      }
      return "";
    },
    toggleTaskDone(evt) {
      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: {
            id: this.item.id,
            done: evt,
          },
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          this.sendError(error);
        });
    },
    openFile(event) {
     
      this.$emit("openFile", event);
    },
  },
  watch: {
    item() {
      this.checkbox = this.item.done;
    },
  },
  created() {
    this.checkbox = this.item.done;
  },
};
</script>

<style>
.grid_wrapper {
  display: grid;
  grid-template-columns: 78px 1fr;
  grid-gap: 10px;
  grid-auto-rows: auto;
}

.note_wrapper {
  grid-template-columns: 68px 1fr 32px;
}

.decision_wrapper {
  grid-template-columns: 92px 1fr 32px;
}

.task_wrapper {
  grid-template-columns: 68px 26px 1fr 160px;
}

.feed_item_html p:last-of-type {
  margin-bottom: 0px;
}
</style>

