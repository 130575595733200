<template>
  <v-card tile>
    <v-card-title class="title font-weight-regular" :class="{ 'px-2': true }">
      <v-container class="pa-0" fluid>
        <v-row wrap no-gutters align="center">
          <div :class="chipWrapperClass(feed_item.feed_item_type)"></div>
          <v-chip
            :color="'item_' + feed_item.feed_item_type + '_background'"
            :class="'item_' + feed_item.feed_item_type + '--text ml-3 mr-4'"
            small
            label
          >
            {{ $t("feedItems." + feed_item.feed_item_type) }}
          </v-chip>

          <span>{{ $t("textEditor.editing") }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-container>

      <template v-if="feed_item.feed_item_type == 'task'">
        <template v-if="users.length">
          <userAvatarGroup
            :limit="2"
            :users="users"
            :size="22"
            :disabledMenu="true"
            class="mb-2"
          />
        </template>

        <!-- Assign task -->
        <v-menu
          offset-y
          :close-on-content-click="false"
          :nudge-width="200"
          max-height="400px"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  small
                  icon
                  rounded
                  class="mr-2 mb-1"
                  color="mid_grey darken-1"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon small>mdi-account-plus</v-icon>
                </v-btn>
              </template>

              <span> {{ $t("textEditor.assignTask") }}</span>
            </v-tooltip>
          </template>
          <v-card flat>
            <v-subheader>{{ $t("textEditor.assignTask") }}:</v-subheader>

            <searchAndSelectUsers
              :selectedUsersIds="users.map((u) => u.id)"
              :selectedMessage="$t('textEditor.assignedToTask')"
              @onUserSelected="assignUserToTask"
              @onUserUnselected="unAssignUserToTask($event.id)"
            />
            <v-list dense two-lines class="py-0">
              <userItem
                v-for="taskUser in users"
                :key="taskUser.id"
                :user="taskUser"
              >
                <template v-slot:action>
                  <v-btn icon @click="unAssignUserToTask(taskUser.id)">
                    <v-icon color="error" small>mdi-account-minus</v-icon>
                  </v-btn>
                </template>
              </userItem></v-list
            >
          </v-card>
        </v-menu>

        <!-- Due Date -->
        <v-menu
          v-model="showTaskDueDateMenu"
          offset-y
          ref="menu_date"
          :close-on-content-click="false"
          :return-value.sync="due_date"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  small
                  :icon="!due_date"
                  rounded
                  depressed
                  text
                  class="mr-4 mb-1"
                  color="mid_grey darken-1"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon
                    small
                    :class="{
                      'pr-2': due_date,
                    }"
                    >mdi-calendar-check</v-icon
                  >
                  {{ due_date ? friendlyDate(due_date) : "" }}
                </v-btn>
              </template>

              <span> {{ $t("textEditor.dueDate") }}</span>
            </v-tooltip>
          </template>
          <v-date-picker
            v-model="due_date"
            no-title
            scrollable
            :min="todaysDate"
            @change="$refs.menu_date.save(due_date)"
          >
            <!-- <v-spacer></v-spacer>
            <v-btn text small @click="showTaskDueDateMenu = false">
              {{ $t("misc.cancel") }}
            </v-btn>
            <v-btn
              small
              depressed
              class="white--text"
              color="button_blue"
              @click="$refs.menu_date.save(due_date)"
            >
              {{ $t("misc.ok") }}
            </v-btn> -->
          </v-date-picker>
        </v-menu>
      </template>
    </v-card-title>

    <v-card-text :class="{ 'pt-4': true, 'px-0': true }">
      <!-- <v-simple-checkbox
        v-if="feed_item.feed_item_type == 'task'"
        v-model="checkbox"
        @input="toggleTaskDone($event)"
        color="item_task"
        class="shrink pa-0 ma-0"
      ></v-simple-checkbox> -->

      <v-row wrap no-gutters class="my-2 mx-3" style="word-break: break-word">
        <div
          v-if="feed_item.feed_item_type == 'task'"
          style="width: 32px; flex-shrink: 0"
          class=""
        >
          <v-simple-checkbox
            v-model="checkbox"
            color="item_task"
            class="shrink py-0 ma-0 pr-3"
            style="display: inline-block; top: 4px"
          ></v-simple-checkbox>
        </div>
        <div style="flex: 1" class="pt-1 pb-8">
          <editor-content
            class="editor__content body-2"
            :class="{
              lineThrought: checkbox,
              caption: feed_item.feed_item_type == 'task',
            }"
            :editor="editor"
          >
          </editor-content>
        </div>
      </v-row>
    </v-card-text>

    <template>
      <v-card-actions class="px-0">
        <v-container class="pa-0">
          <v-row wrap no-gutters class="pa-0 pb-2">
            <v-col cols="12">
              <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                <div>
                  <textEditorToolbarButton
                    mobileVersion
                    :tooltip="$t('textEditor.bold')"
                    :active="isActive.bold()"
                    icon="mdi-format-bold"
                    @btnClic="commands.bold"
                  />

                  <textEditorToolbarButton
                    mobileVersion
                    :tooltip="$t('textEditor.italic')"
                    :active="isActive.italic()"
                    icon="mdi-format-italic"
                    @btnClic="commands.italic"
                  />

                  <textEditorToolbarButton
                    mobileVersion
                    :tooltip="$t('textEditor.underline')"
                    :active="isActive.underline()"
                    icon="mdi-format-underline"
                    @btnClic="commands.underline"
                  />

                  <template
                    v-if="
                      feed_item.feed_item_type == 'note' ||
                      feed_item.feed_item_type == 'decision'
                    "
                  >
                    <textEditorToolbarButton
                      mobileVersion
                      :tooltip="$t('textEditor.bulletedList')"
                      :active="isActive.bullet_list()"
                      icon="mdi-format-list-bulleted"
                      @btnClic="commands.bullet_list"
                    />
                    <textEditorToolbarButton
                      mobileVersion
                      :tooltip="$t('textEditor.numberedList')"
                      :active="isActive.ordered_list()"
                      icon="mdi-format-list-numbered"
                      @btnClic="commands.ordered_list"
                    />
                    <textEditorToolbarButton
                      mobileVersion
                      :tooltip="$t('textEditor.title')"
                      :active="isActive.heading({ level: 3 })"
                      icon="mdi-format-title"
                      @btnClic="commands.heading({ level: 3 })"
                    />

                    <textEditorToolbarButton
                      mobileVersion
                      :tooltip="$t('textEditor.highlight')"
                      :active="isActive.highlight()"
                      icon="mdi-format-color-highlight"
                      color="#ffee58"
                      @btnClic="commands.highlight({ color: '#ffee58' })"
                    />

                    <!-- Text color if needed -->
                    <textEditorToolbarButton
                      mobileVersion
                      :tooltip="$t('textEditor.table')"
                      :active="false"
                      icon="mdi-table"
                      @btnClic="
                        commands.createTable({
                          rowsCount: 3,
                          colsCount: 3,
                          withHeaderRow: false,
                        })
                      "
                    />

                    <v-card
                      v-if="isActive.table()"
                      flat
                      style="
                        position: fixed;
                        transform: translate(0px, -70px);
                        width: 300px;
                      "
                    >
                      <v-card-text class="px-0 py-2 text-center">
                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.deleteTable')"
                          :active="false"
                          icon="mdi-table-remove"
                          @btnClic="commands.deleteTable"
                        />

                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.addColumnBefore')"
                          :active="false"
                          icon="mdi-table-column-plus-before"
                          @btnClic="commands.addColumnBefore"
                        />

                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.addColumnAfter')"
                          :active="false"
                          icon="mdi-table-column-plus-after"
                          @btnClic="commands.addColumnAfter"
                        />

                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.deleteColumn')"
                          :active="false"
                          icon="mdi-table-column-remove"
                          @btnClic="commands.deleteColumn"
                        />

                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.addRowBefore')"
                          :active="false"
                          icon="mdi-table-row-plus-before"
                          @btnClic="commands.addRowBefore"
                        />

                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.addRowAfter')"
                          :active="false"
                          icon="mdi-table-row-plus-after"
                          @btnClic="commands.addRowAfter"
                        />

                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.deleteRow')"
                          :active="false"
                          icon="mdi-table-row-remove"
                          @btnClic="commands.deleteRow"
                        />

                        <textEditorToolbarButton
                          mobileVersion
                          :tooltip="$t('textEditor.toggleCellMerge')"
                          :active="false"
                          icon="mdi-table-merge-cells"
                          @btnClic="commands.toggleCellMerge"
                        />
                      </v-card-text>
                    </v-card>
                  </template>
                </div> </editor-menu-bar
            ></v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row wrap no-gutters class="px-0 pt-3 pb-2 pr-4">
            <v-spacer></v-spacer>
            <v-btn color="almost_black" text depressed @click="close">
              {{ $t("misc.cancel") }}
            </v-btn>
            <v-btn
              color="button_blue"
              class="white--text"
              depressed
              @click="updateFeedItem()"
              :disabled="loading"
            >
              <v-icon class="pr-2" small>mdi-content-save-edit</v-icon>
              {{ $t("misc.save") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import CREATE_FEED_ITEM_USER from "@/graphql/feed_item_users/create.gql";
import UPDATE_FEED_ITEM from "@/graphql/feed_items/update.gql";
// import editorHighlighting from "../../plugins/editorHighlighting.js";
// import editorTextColor from "../../plugins/editorTextColor.js";
import searchAndSelectUsers from "./../users/searchAndSelectUsers";
import textEditorToolbarButton from "../shared/textEditorToolbarButton";
import date from "@/mixins/date";
import userItem from "@/components/users/userItem";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import * as api from "@/services/no-meetings.api";

import { Editor, EditorContent } from "@tiptap/vue-2";
// import {
//   Placeholder,
//   Blockquote,
//   CodeBlock,
//   HardBreak,
//   Heading,
//   HorizontalRule,
//   OrderedList,
//   BulletList,
//   ListItem,
//   TodoItem,
//   TodoList,
//   Bold,
//   Code,
//   Italic,
//   Link,
//   Strike,
//   Underline,
//   History,
//   Table,
//   TableHeader,
//   TableCell,
//   TableRow,
// } from "tiptap-extensions";

export default {
  name: "feed_item_editor",
  mixins: [date],
  data() {
    return {
      checkbox: false,
      editor: null,
      loading: false,
      due_date: null,
      todaysDate: new Date().toISOString(),
      showTaskDueDateMenu: false,
      //users: [],
    };
  },
  props: {
    feed_item: {
      type: Object,
      required: true,
    },
  },
  components: {
    EditorContent,
    textEditorToolbarButton,
    userItem,
    userAvatarGroup,
    searchAndSelectUsers,
  },
  computed: {
    writeYourNote() {
      switch (this.feed_item.feed_item_type) {
        case "task":
          return this.$t("textEditor.writeYourTaskHere");
        case "note":
          return this.$t("textEditor.writeYourNoteHere");
        case "decision":
          return this.$t("textEditor.writeYourDecisionHere");

        default:
          return "";
      }
    },
    currentItem() {
      this.$store.state.topicStore.topicStoreRefresh;
      let topic = this.$store.state.topicStore.topicsSet[
        this.feed_item.topic_id
      ];
      return topic.feed_items.find((e) => e.id === this.feed_item.id);
    },
    users() {
      if (!this.currentItem) return [];
      return this.currentItem.users;
    },
  },
  methods: {
    assignUserToTask(event) {
      this.$apollo
        .mutate({
          mutation: CREATE_FEED_ITEM_USER,
          variables: {
            feed_item_id: this.feed_item.id,
            user_id: event.id,
          },
        })
        .then(() => {})
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    unAssignUserToTask(user_id) {
      let id = this.getFeedItemUserId(user_id);
      this.destroyFeedItemUser(id);
    },
    async destroyFeedItemUser(id) {
      try {
        this.loading = true;
        const { data } = await api.destroy(id, "feed_item_user");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.sendError(err);
        console.error(err);
      }
    },
    getFeedItemUserId(user_id) {
      return this.currentItem.feed_item_users.find((e) => e.user_id === user_id)
        .id;
    },
    close() {
      this.$emit("closeEditor");
    },
    editorExtensionsPresets(type) {
      var extensions = [
        new Placeholder({
          emptyEditorClass: "is-editor-empty",
          emptyNodeClass: "is-empty",
          emptyNodeText: this.writeYourNote,
          showOnlyWhenEditable: false,
          showOnlyCurrent: false,
        }),
        new Bold(),
        new Italic(),
        new Underline(),
      ];

      var additionnalExtensions = [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Code(),
        new Strike(),
        new History(),
        new editorHighlighting(),
        new editorTextColor(),
        new Table({
          resizable: true,
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
      ];
      if (type == "task") return extensions;
      else return extensions.concat(additionnalExtensions);
    },
    wrapperClass(type) {
      return "grid_wrapper " + type + "_wrapper";
    },
    chipWrapperClass(type) {
      switch (type) {
        case "task":
          return "itemTask itemChip";
        case "decision":
          return "itemDecision itemChip";
        case "note":
          return "itemNote itemChip";
      }
      return "";
    },
    updateFeedItem() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: {
            id: this.feed_item.id,
            content: this.editor.getHTML(),
            done: this.checkbox,
            due_date: new Date(this.due_date).getTime(),
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.sendError(error);
        });
    },
    init() {
      this.checkbox = this.feed_item.done;
      (this.editor = new Editor({
        itemType: this.feed_item.feed_item_type,
        autoFocus: true,
        extensions: this.editorExtensionsPresets(this.feed_item.feed_item_type),
      })),
        this.editor.setContent(this.feed_item.content);
      this.due_date = this.feed_item.due_date ? this.feed_item.due_date : null;
      //this.users = JSON.parse(JSON.stringify(this.feed_item.users));
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    // feed_item() {
    //   this.init()
    // }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
p.is-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: lightgray;
  pointer-events: none;
  height: 0;
}
</style>

