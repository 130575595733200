<template>
  <v-dialog
    v-model="dialog"
    width="720"
    transition="dialog-top-transition"
    scrollable
    persistent
  >
    <template v-slot:default>
      <v-card>
        <!-- Form Title and close button -->
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ $t("meetingMinutes.shareMeetingMinute") }}</span>
          <v-btn icon @click="closeForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <template v-if="!loading">
          <v-card-text>
            <v-subheader class="pl-2" style="height: 28px">
              {{ $t("email.to") }}: ({{ selectedItems.length }})
            </v-subheader>

            <v-combobox
              :placeholder="$t('email.selectRecipients')"
              persistent-placeholder
              @change="addedItem"
              v-model="selectedItems"
              :items="users"
              :item-text="userName"
              ref="selectEmailInput"
              multiple
              searcheable
              clearable
              auto-select-first
              @keydown.enter="adder"
              hide-selected
              small-chips
              dense
              solo
              flat
              :search-input.sync="search"
              deletable-chips
              :delimiters="delimiters"
              :loading="usersQueryLoading"
            >
              <template v-slot:prepend-item>
                <v-row no-gutters wrap class="pb-2 px-4">
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="button_blue"
                    @click="selectAllMeetingParticipant"
                  >
                    <v-icon small class="pr-2">mdi-account-multiple-plus</v-icon
                    >{{ $t("meetings.addAllMeetingParticipants") }}</v-btn
                  >
                </v-row>

                <v-divider></v-divider>
              </template>

              <template v-slot:item="data">
                <v-list-item-icon class="pt-0">
                  <userAvatar
                    :size="18"
                    :show_name_in_tooltip="false"
                    :first_name="data.item.first_name"
                    :last_name="data.item.last_name"
                    :avatar_url="data.item.avatar_url"
                    :email="data.item.email"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    userName(data.item)
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-slot:selection="data">
                <template
                  v-if="typeof data.item === 'object' && data.item !== null"
                >
                  <v-chip
                    v-if="JSON.stringify(data.item).trim()"
                    :key="data.index"
                    v-bind="data.attrs"
                    close
                    small
                    close-icon="mdi-close"
                    class="ma-1 py-0"
                    :class="{ recipientChip: true }"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <userAvatar
                      :size="10"
                      :show_name_in_tooltip="false"
                      :first_name="data.item.first_name"
                      :last_name="data.item.last_name"
                      :avatar_url="data.item.avatar_url"
                      :email="data.item.email"
                    />
                    {{ userName(data.item) }}
                  </v-chip>
                </template>

                <template v-else>
                  <v-chip
                    v-if="JSON.stringify(data.item).trim()"
                    :key="data.index"
                    v-bind="data.attrs"
                    close
                    small
                    close-icon="mdi-close"
                    class="ma-1 py-0"
                    :class="{
                      recipientChip: true,
                      recipientChipError: !emailIsValid(data.item),
                    }"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-icon small style="left: -3px" color="button_blue"
                      >mdi-email-outline</v-icon
                    >
                    {{ data.item }}
                  </v-chip>
                </template>
              </template>
            </v-combobox>
          </v-card-text>
        </template>
        <v-card-text v-else class="text-xs-center d-flex justify-center pa-8">
          <v-row wrap no-gutters>
            <v-col class="text-xs-center d-flex justify-center" cols="12"
              ><v-progress-circular color="button_blue" indeterminate size="48" width="4"></v-progress-circular></v-col>
            <v-col class="text-xs-center d-flex justify-center" cols="12">
              <v-card-title class="pa-0 pt-4">
                {{ $t("meetingMinutes.sharingMinute") }}
              </v-card-title></v-col
            >
            <v-col class="text-xs-center d-flex justify-center" cols="12">
              <v-card-subtitle class="pa-0">
                {{ $t("misc.pleaseWait") }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            text
            @click="closeForm()"
            color="almost_black"
            :disabled="loading"
          >
            <v-icon class="pr-1" small>mdi-close</v-icon>
            {{ $t("misc.cancel") }}
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="button_blue"
            class="white--text"
            depressed
            @click="sendMinute()"
          >
            <v-icon small left>mdi-send</v-icon>
            {{ $t("meetingMinutes.share") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import SEND_MINUTE_MEETING from "@/graphql/meetings/send_minute.gql";
import PAGINATED_ORGANISATION_MEMBERS_INDEX from "@/graphql/organisations/paginated_members_index.gql";
import users_mixin from "@/mixins/users.js";
import { mapGetters } from "vuex";
import userAvatar from "@/components/shared/userAvatar";
import selectEmails from "@/components/shared/select_emails";
export default {
  name: "shareMinute",
  mixins: [users_mixin],
  components: { userAvatar, selectEmails },
  data() {
    return {
      search: "",
      dialog: false,
      loading: false,
      selectedTab: "fromOrganisation",
      selectedItems: [],
      users: [],
      usersQueryLoading: false,
      delimiters: [" ", ";"],
      displayInvalidEmailsDialog: false,
    };
  },
  props: {
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      "meeting",
      "currentUser",
      "meetingUsers",
      "selectedOrganisation",
    ]),
    organisationMembersString() {
      return this.$t("meetings.fromOrganisation").replace(
        "<organisation>",
        this.selectedOrganisation.name
      );
    },
    organisationFilter() {
      if (!this.selectedOrganisationId) return "";
      return `organisation_id == '${this.selectedOrganisationId}'`;
    },
    selectedEmails() {
      var allEmails = this.selectedItems.map((item) => {
        return typeof item === "object" && item !== null ? item.email : item;
      });
      return [...new Set(allEmails)];
    },
  },
  methods: {
    allEmailsAreValid() {
      var ret = true;
      this.selectedEmails.forEach((sm) => {
        if (ret && !this.emailIsValid(sm)) {
          ret = false
        };
      });
      return ret;
    },
    emailIsValid(email) {
      return !!email
        .trim()
        .match(
          /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/
        );
    },
    addedItem(event) {
      this.search = "";
         this.selectedEmails.forEach((si,i) => {
          //  console.log("si", si)
           if (si == "") {
            //  console.log("SPLICE")
             this.selectedItems.splice(i, 1)
           }
        })
    },
    selectAllMeetingParticipant() {
      var allmails = this.selectedEmails;
      // console.log(allmails);
      this.meetingUsers.forEach((mu) => {
        if (allmails.includes(mu.email) == false) {
          this.selectedItems.push(mu);
        }
      });
      this.search = "";
      this.$refs["selectEmailInput"].blur();
      return allmails;
    },
    adder() {
      // console.log("adder");
      // Fix for combobox not populating array when pressing ENTER if autofocus first on search is enabled.
      //waiting for official bug fix.
      let searchText = this.search; //.trim();
      let itemNotFound = !this.$refs.selectEmailInput.filteredItems.length;
      if (itemNotFound && searchText.length && searchText != "") {
        this.delimiters.forEach((delimiter) => {
          searchText = searchText.replaceAll(delimiter, " ");
        });
        var mailsToPush = searchText.split(" ").filter((si) => {
          return !!si;
        });

        this.selectedItems = this.selectedItems.concat(
          mailsToPush.map((mtp) => mtp.trim())
        );
        this.search = "";

      }
    },
    getData() {
      this.usersQueryLoading = true;

      // Users
      this.$apollo
        .query({
          query: PAGINATED_ORGANISATION_MEMBERS_INDEX,
          variables: {
            filter: this.organisationFilter,
            page: 1,
            per_page: -1,
            order_by: "user.first_name",
          },
        })
        .then(({ data }) => {
          this.users = data.paginated_organisation_users.data.map(
            (du) => du.user
          );
          this.usersQueryLoading = false;
          // console.log(this.users);
        })
        .catch((e) => {
          // console.log(e);
          this.usersQueryLoading = false;
          this.sendError(e);
        });
    },
    closeForm() {
      this.dialog = false;
    },
    sendMinute() {
      if (!this.allEmailsAreValid())
        this.sendError(this.$t("email.checkForErrors"));
      else this.sendMinuteQuery();
    },
    sendMinuteQuery() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: SEND_MINUTE_MEETING,
          variables: {
            id: this.meeting.id,
            emails: this.selectedEmails,
            content: this.content,
          },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t('meetingMinutes.hasBeenShared'));
          this.closeForm();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.sendError(error);
        });
    },
  },
  watch: {},
  created() {
    this.selectedItems = this.meetingUsers.map((mu) => mu);
    this.getData();
  },
  beforeDestroy() {},
};
</script>

<style>
.recipientChip {
  color: #555;
  background-color: white !important;
  border: 1px solid #ddd;
  padding: 8px;
}

.recipientChip button {
  color: #666 !important;
}

.recipientChip .v-avatar {
  height: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
  left: -4px;
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}

.recipientChipError {
  color: white !important;
  background-color: var(--v-error) !important;
  border: none;
  padding: 8px;
}

.recipientChipError button {
  color: white !important;
}

.recipientChipError .v-icon {
  color: white !important;
}
</style>

