<template>
  <v-card-text class="text-center">
    <v-window v-model="emailsInvitationLoading">
      <!-- Emails to invite -->
      <v-window-item :value="0">
        <v-combobox
          ref="emailTextInput"
          v-model="emailsToInvite"
          :label="placeholderInvitation"
          multiple
          deletable-chips
          :delimiters="[',', ' ']"
          small-chips
          validate-on-blur
          filled
          chip-color="button_blue"
          solo
          flat
          dense
          prepend-icon="mdi-account-multiple-plus"
          @change="parseMails"
          :rules="[
            (e) =>
              !emailsToInvite ||
              validEmails ||
              $t('organisations.invalidEmailMsg'),
          ]"
        ></v-combobox>

        <span v-html="$t('meetingMinutes.youCanShareSeveral')"> </span>
      </v-window-item>

      <!-- Loading animation-->
      <v-window-item :value="1">
        <ripple-loader color="blue" />
        <h4>{{ $t("organisations.sendInvitations") }}</h4>
        <span>{{ $t("misc.pleaseWait") }}</span>
      </v-window-item>

      <!-- Success ! -->
      <v-window-item :value="2">
        <v-icon size="72" color="success">mdi-email-check</v-icon>
        <h4>{{ $t("organisations.invitationsSended") }}</h4>
      </v-window-item>

      <!-- Error! -->
      <v-window-item :value="3">
        <v-icon size="64" color="error">mdi-email-alert</v-icon>
        <h4>{{ $t("organisations.errorWhileSendingInvitation") }}</h4>
        <span>{{ $t("organisations.someInvitationError") }}</span>
      </v-window-item>
    </v-window>
  </v-card-text>
</template>
 

<script>
import { mapActions, mapGetters } from "vuex";
import userAvatar from "@/components/shared/userAvatar";

export default {
  name: "selectEmails",
  components: { userAvatar },
  data() {
    return {
      emailsToInvite: [],
      emailsToInviteErrors: [],
      emailsToInviteError: "",
      emailsInvitationLoading: 0,
      email: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "organisations",
      "selectedOrganisation",
      "selectedOrganisationId",
    ]),
    placeholderInvitation() {
      return this.$t("misc.emails");
    },
    validEmails() {
      return (
        this.emailsToInvite
          .map((e) => !!e.trim().match(/^(.+)@(.+)$/))
          .filter((e) => !e).length === 0
      );
    },
  },
  methods: {
    sendInvitations() {
      // Input box change event is trigger by the blur function so the parsemail event is working, then we wait the next tick to send invitations
      this.$refs.emailTextInput.blur();
      this.$nextTick(() => {
        if (this.loading === true) return;
        if (!this.validEmails)
          return this.sendError(this.$t("organisations.invalidEmailsMsg"));
        this.emailsInvitationLoading = 1;
        // this.emailsInvitationLoading = 0: not loading, 1: loading, 2: sucess, 3: error
        this.emailsToInviteErrors = [];
        this.$apollo
          .mutate({
            mutation: null,
            variables: {
              invited_user_emails: this.emailsToInvite,
              organisation_id: this.selectedOrganisationId,
            },
          })
          .then(({ data }) => {
            // if (data.bulk_create_organisation_invitation.errors.length == 0) {
            //   this.emailsInvitationLoading = 2;
            //   setTimeout(() => this.closeInviteDialog(), 2000);
            // } else {
            //   this.emailsInvitationLoading = 3;
            //   this.emailsToInviteErrors =
            //     data.bulk_create_organisation_invitation.errors;
            // }
          })
          .catch((error) => {
            this.emailsInvitationLoading = 3;
            this.emailsToInviteError = error;
          });
      });
    },

    parseMails(v) {
      const reducer = (a, e) => [...a, ...e.split(/[, ]+/)];
      this.emailsToInvite = [...new Set(v.reduce(reducer, []))];
    },
  },
};
</script>

