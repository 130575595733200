<template>
  <div style="display: inherit">
    <template v-if="template == 'full_countdown'">
      <span class="mr-4 ml-2 caption font-weight-bold">
        {{ days }} {{ $t("misc.timer." + (days > 1 ? "days" : "day")) }},
        {{ hours }} {{ $t("misc.timer." + (hours > 1 ? "hours" : "hour")) }},
        {{ minutes }} {{ $t("misc.timer.minutes") }}
      </span>
    </template>

    <template v-if="template == 'hide_zeros'">
      <span class="mr-4 ml-2 caption font-weight-bold">
        <template v-if="days">
          {{ days }} {{ $t("misc.timer." + (days > 1 ? "days" : "day")) }},
        </template>

        <template v-if="hours">
          {{ hours }} {{ $t("misc.timer." + (hours > 1 ? "hours" : "hour")) }},
        </template>

        <template> {{ minutes }} {{ $t("misc.timer.minutes") }} </template>
      </span>
    </template>

    <template v-if="template == 'meetingHome'">
      <span
        class="meetingStartInBigCountdown"
        :class="isLate ? 'error--text' : 'primary--text'"
      >
        {{ meetingHomeCountdown }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "countdown",
  components: {},
  props: {
    endtime: {
      type: Number,
    },
    template: {
      type: String,
      required: false,
      default: "full_countdown",
    },
  },
  data() {
    return {
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      isLate: false,
    };
  },
  computed: {
    meetingHomeCountdown() {
      let data = [];

      if (this.days > 0) {
        data.push(
          this.days +
            " " +
            this.$t("misc.timer." + (this.days > 1 ? "days" : "day"))
        );
      }

      if (this.hours > 0 && this.days < 3) {
        data.push(
          this.hours +
            " " +
            this.$t("misc.timer." + (this.hours > 1 ? "hours" : "hour"))
        );
      }

      if (this.days < 3) {
        if (this.days == 0 && this.minutes < 1) {
        } else {
          data.push(this.minutes + " " + this.$t("misc.timer.minutes"));
        }
      }

      if (this.days < 1 && this.hours == 0 && this.minutes < 6) {
        data.push(this.seconds + " " + this.$t("misc.timer.secondes"));
      }

      return data.join(", ");
    },
  },
  created() {},
  methods: {
    timerCount(end) {
      var now = new Date().getTime();
      var passTime = end - now;
      this.calcTime(passTime);
    },
    calcTime(dist) {
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);

      this.isLate = this.seconds < 0;
    },
  },
  beforeDestroy() {},
  mounted() {
    this.end = this.endtime;
    this.timerCount(this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.end);
    }, 1000);
  },
};
</script>