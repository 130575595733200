<template>
  <v-card flat style="height: 100%">
    <v-subheader style="width: 100%" class="font-weight-bold">
      <v-icon small class="pr-2">mdi-format-list-checks</v-icon>
      {{ $t("meetings.objectives") + " (" + panel_objectives.length + ")" }}
      <v-spacer></v-spacer>
      <v-btn
        depressed
        text
        color="almost_black"
        @click="editMode = true"
        class="caption"
        small
        rounded
        v-if="!editMode"
      >
        {{ $t("meetings.editObjectives") }}
        <v-icon color="button_blue" class="pl-2" size="14"
          >mdi-pencil</v-icon
        ></v-btn
      >

      <v-btn
        v-else
        depressed
        color="button_blue"
        c
        class="white--text caption"
        @click="editMode = false"
        small
        rounded
      >
        {{ $t("meetings.editDone") }}
        <v-icon class="pl-2" size="14">mdi-pencil</v-icon></v-btn
      ></v-subheader
    >

    <template v-if="!editMode">
      <v-card-text
        v-if="panel_objectives.length"
        style="overflow-y: auto; height: calc(100% - 48px)"
        class="px-4 pt-0"
      >
        <v-list style="width: 100%" subheader flat class="px-2">
          <template v-for="(todo, i) in panel_objectives">
            <v-hover v-slot:default="{}" :key="'obj_' + i">
              <v-list-item @click="toggleObjective(todo)" class="objectiveItem">
                <v-list-item-action class="mr-2" @click.stop>
                  <v-checkbox
                    v-model="todo.done"
                    color="button_blue"
                    @click="updateObjective(todo)"
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle
                    v-if="!editMode"
                    :class="{
                      lineThrought: todo.done,
                    }"
                    class="body-2 pl-0 objectiveItemTitle"
                    >{{ todo.content }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-hover>
          </template>
        </v-list>
      </v-card-text>
      <v-card
        v-else
        style="height: calc(100% - 48px)"
        class="d-flex align-center flex-column pa-4 justify-space-between"
        flat
        tile
      >
        <v-card class="pa-2" flat></v-card>
        <v-card class="pa-2 text-center" flat>
          <v-img
            class="mx-auto mb-8"
            width="auto"
            contain
            height="200px"
            src="/images/svg_images/sitdown_guy.svg"
          />

          <h5>{{ $t("meetings.objectivesHeadline") }}</h5>
          <span class="caption">
            {{ $t("meetings.objectivesHeadline2") }}
          </span></v-card
        >
        <v-card flat>
          <v-alert
            color="button_blue"
            dark
            dense
            icon="mdi-information"
            border="left"
            class="caption"
          >
            {{ $t("meetings.objectivesNotDefined") }}</v-alert
          >
        </v-card>
      </v-card>
    </template>
    <v-card-text
      style="overflow-y: auto; height: calc(100% - 48px)"
      class="px-4 pt-0"
    >
      <template v-if="editMode">
        <!-- UPDATE OBJECTIVES -->
        <v-text-field
          v-for="(objective, i) in panel_objectives"
          :key="'objedit_' + i"
          hide-details
          v-model="objective.content"
          dense
          solo
          filled
          flat
          full-width
          style="width: 100%"
          :placeholder="$t('meetings.objective')"
          @click:append="updateObjective(objective)"
          @blur="updateObjective(objective)"
          @keyup.enter="updateObjective(objective)"
          append-icon="mdi-plus-circle"
          class="body-2 py-2 px-6"
        >
          <template slot="append">
            <v-btn
              icon
              ripple
              depressed
              small
              color="error"
              @click.stop="removeObjective(objective)"
              :disabled="loading"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </template>

      <v-text-field
        v-if="editMode"
        v-model="newObjective"
        append-icon="mdi-plus-circle"
        filled
        dense
        solo
        flat
        class="py-2 px-6"
        :placeholder="$t('meetings.createObjective')"
        @keyup.enter="createObjective()"
        autocomplete="off"
      >
        <template slot="append">
          <v-btn
            icon
            ripple
            depressed
            small
            color="button_blue"
            @click="createObjective()"
            :disabled="loading"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CREATE_OBJECTIVE from "@/graphql/objectives/create.gql";
import UPDATE_OBJECTIVE from "@/graphql/objectives/update.gql";
import * as api from "@/services/no-meetings.api";

export default {
  name: "objectivesPanel",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      panel_objectives: [],
      newObjective: "",
      loading: false,
      editMode: false,
    };
  },
  computed: {
    ...mapGetters(["meeting", "objectives", "objectivesRefresh"]),
  },
  methods: {
    resetObjectives() {
      console.log("reset objectives");
      this.panel_objectives = [];
      this.objectives.forEach((e) => this.panel_objectives.push({ ...e }));
    },
    createObjective() {
      if (this.loading || !this.newObjective) return;
      this.loading = true;
      console.log("Create objective" + this.newObjective);
      this.$apollo
        .mutate({
          mutation: CREATE_OBJECTIVE,
          variables: {
            meeting_id: this.meeting.id,
            content: this.newObjective,
          },
        })
        .then((data) => {
          this.loading = false;
          this.$amplitude
            .getInstance()
            .logEvent("Create objective", { name: this.newObjective, id: data.create_objective.id });
          this.newObjective = "";

        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateObjective(objective) {
      if (this.loading || !objective.id) return;
      if (this.objectiveHasNotChanged(objective)) return;
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_OBJECTIVE,
          variables: objective,
        })
        .then(({ data }) => {
          this.loading = false;
          this.$emit("updatedObjectives");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    objectiveHasNotChanged(objective) {
      let original = this.objectives.find((e) => e.id === objective.id);
      return (
        original.content === objective.content &&
        original.done === objective.done &&
        original.position === objective.position
      );
    },
    async removeObjective(objective) {
      this.loading = true;
      try {
        this.loading = true;
        const { data } = await api.destroy(objective.id, "objective");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.sendError(err);
        console.error(err);
      }
    },
    toggleObjective(objective) {
      if (this.loading) return;

      objective.done = !objective.done;
      console.log(objective);
      this.updateObjective(objective);
    },
  },
  mounted() {
    this.resetObjectives();
  },
  watch: {
    "objectives.length"() {
      this.resetObjectives();
    },
    objectivesRefresh() {
      this.resetObjectives();
    },
  },
};
</script>

<style scoped>
.objectiveItem {
  min-height: max(36px, min-content);
  height: max(36px, min-content);
}
</style>

<style>
</style>

