<template>
  <div>
    <!-- Teams -->
    <!-- <meetingShowMobile v-if="$vuetify.breakpoint.xs" /> -->
    <!-- Regular template -->
    <template>
      <v-container
        fluid
        class="pa-0 light_grey_background"
        style="height: calc(100vh - 48px); width: 100%"
      >

      <!-- <span style="z-index: 6; position: absolute; left: 14px; bottom: 14px;  color: white; font-size: 54px;" class="clock font-weight-light body-2 mr-2" >
                  {{ clock }}</span
                >
 -->

        <!-- <headerToolbar color="timeline_blue">
      <template v-slot:toolbarTitle>
        <b>{{ $t("navigationMenu.upcomingMeeting") }}</b>
      </template>
    </headerToolbar> -->
        <v-hover v-slot="{ hover }">
          <div>
            <headerToolbar
              :hideSettings="drawerTeams"
              :preventOrgSwitch="true"
              :app="true"
              dark
              color="header_dark_blue"
              :height="42"
            >
              <template v-slot:toolbarIcon>
                <v-app-bar-nav-icon
                  v-if="!drawerTeams"
                  small
                  @click="goToMeetingsIndex()"
                >
                  <v-icon>mdi-arrow-left</v-icon>
                </v-app-bar-nav-icon>
              </template>

              <template v-slot:toolbarTitle>
                <v-row class="px-4 align-center">
                  <v-hover v-slot="{ hover }">
                    <v-btn small text @click="openEditTitle">

                      {{
                        meeting.title
                          ? meeting.title
                          : $t("meetings.unamedMeeting")
                      }}
                      <v-icon size="12" right v-show="hover">mdi-pencil</v-icon>
                    </v-btn>
                  </v-hover>

                  <v-btn
                    depressed
                    small
                    v-if="meeting.status == 'pending' && meeting.topics.length"
                    @click="startMeeting()"
                    color="success"
                    class="mx-4"
                  >
                    <v-icon size="12" left>mdi-play</v-icon>
                    {{ $t("meetings.startMeeting") }}
                  </v-btn>

                  <v-btn
                    depressed
                    small
                    v-if="currentTopicIndex != -1"
                    @click="isLastTopic ? endMeeting() : playNextTopic()"
                    :color="isLastTopic ? '#f77c68' : 'button_blue'"
                    class="mx-4"
                  >
                    <v-icon size="12" left
                      >{{ isLastTopic ? "mdi-stop" : "mdi-transfer-right" }}
                    </v-icon>
                    {{
                      isLastTopic
                        ? $t("meetings.endMeeting")
                        : $t("meetings.goToNextTopic")
                    }}
                  </v-btn>

                  <v-tooltip bottom color="timeline_blue">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                        v-show="
                          meeting.status != 'pending' && meeting.topics.length
                        "
                        @click="restartMeeting()"
                      >
                        <v-icon small>mdi-rotate-left</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("meetings.restartMeeting") }}</span>
                  </v-tooltip>
                  <!--
                    <span>
                    <b
                      class="ml-3"
                      :class="{
                        'button_blue--text text-lighten-2': hover
                      }"
                      style="font-size: 14px; cursor: pointer"
                      v-if="meeting && meeting.title"
                      @click="openEditTitle"
                    >
                      {{ meeting.title }}
                    </b>
                    <v-btn
                      @click="openEditTitle"
                      class="mx-2"

                      v-if="hover"
                      icon
                      small
                    >

                    </v-btn>
                    </span>
                  </v-hover> -->
                </v-row>

                <!-- <meetingStatusChip
                  class="ml-4"
                  style="transform: scale(0.8)"
                  v-if="meeting && meeting.status"
                  :meetingStatus="meeting.status"
                /> -->

                <!-- Meeting Agenda -->
              </template>
              <template slot="toolbarRight">
              </template>
            </headerToolbar>

            <!-- <v-divider class="timeline_blue lighten-1"></v-divider> -->
            <v-toolbar
              flat
              dark
              color="timeline_blue"
              height="96"
              class="timelineToolbar"
            >
              <!--   style="z-index: 20" -->
              <meetingTimeline
                v-if="!loadingMeeting && !meetingFetchFailed"
                @openMeetingAgenda="openMeetingAgenda"
                @openTopicDialog="openTopicDialog"
                :timelineHovered="hover"
                @openNewTopicDialog="$refs.select_or_create_followup.dialog = true"
              />
            </v-toolbar>
          </div>
        </v-hover>

        <template v-if="meetingFetchFailed">
          <!-- <v-container fluid fill-height>
        <v-row>
          <v-col> {{ $t("meeting.fetchFailed") }} </v-col></v-row
        >
      </v-container> -->
        </template>
        <loadingOverlay
          :message="$t('meetings.loadingMeeting')"
          :display="loadingMeeting"
        />

        <template v-if="meeting && !loadingMeeting && !meetingFetchFailed">
          <topicToolbar
            v-if="meeting.topics.length > 0 && selectedTopic"
            :subject="toolbarSubject"
            :description="topicDescription"
            :users="
              selectedTopic && selectedTopic.follow_up
                ? selectedTopic.follow_up.users
                : []
            "
            :owner="
              selectedTopic && selectedTopic.follow_up
                ? selectedTopic.follow_up.user
                : null
            "
            :meeting_topics="meeting.topics"
            :assetsFiles="[]"
            :assetsUrls="[]"
            isInMeeting
            :followUp="selectedTopic.follow_up"
            backIcon="mdi-close"
            @clickOnBackIcon="goToMeetingsIndex"
            :displayFollowupIcon="
              selectedTopic && selectedTopic.follow_up != null
            "
          />
          <meetingSideBar
            ref="meetingSidebar"
            :meeting="meeting"
            @updatedObjectives="updatedObjectives"
            @openNewTopicDialog="$refs.select_or_create_followup.dialog = true"
          />
          <v-container
            v-if="meeting.topics.length && selectedTopicId"
            style="
              margin-left: 56px;
              max-height: calc(100vh - 176px);
              height: calc(100vh - 176px);
              width: calc(100% - 56px);
            "
            fluid
            class="pa-0"
          >
            <template v-if="meeting.topics.length">
              <v-window :value="selectedTopicId" style="height: 100%">
                <v-window-item
                  v-show="selectedTopicId != null"
                  v-for="(topic, i) in meeting.topics"
                  :key="`topic-${topic.id}`"
                  :value="topic.id"
                  style="height: 100%"
                >
                  <topicShow :ref="'topic_show_' + i" :topic="topic" />
                </v-window-item>
              </v-window>
            </template>
          </v-container>

          <v-container
            v-if="!loadingMeeting && !selectedTopicId"
            style="max-height: calc(100vh - 176px); height: calc(100vh - 176px)"
            fluid
            class="pa-0"
          >
            <!-- @showMeetingSideBar="showMeetingSideBar" -->
            <!--   @inviteParticipants="showMeetingPartipant" -->
            <meetingHome
              :showMeetingHome="!selectedTopicId"
              @startMeeting="startMeeting"
              :key="'home_i' + refreshIndex"
              @openObjectivePanel="openObjectivePanel"
              @openParticipantsPanel="openParticipantsPanel"
              @openMinutePanel="openMinutePanel"
              @openTemplatePanel="openTemplatePanel"
              @openNewTopicDialog="$refs.select_or_create_followup.dialog = true"
            />
          </v-container>
        </template>
      </v-container>
    </template>

    <!-- <v-btn
      @click="drawerTeams = !drawerTeams"
      style="position: absolute; right: -8px; top: 48px; z-index: 5000"
      depressed
      color="amber darken-1"
      dark

      small
    >
      <v-icon> mdi-swap-horizontal </v-icon>
    </v-btn> -->

    <!-- <template v-if="showMeetingMinute">
      <v-row wraps no-gutters style="height: 100%">
        <v-col>
          <v-row wrap no-gutters class="text-center">
            <v-col>
              <minute ref="minute" :meeting="meeting" />
            </v-col>
          </v-row>
        </v-col>

        <v-col class="shrink">
          <meetingMinuteEditor @updateMinuteOptions="updateMinuteOptions" />
        </v-col>
      </v-row>
    </template> -->
    <customDialog
      v-if="alertUserIfDraftNotEmpty"
      :title="$t('meetings.exitMeeting')"
      color="amber darken-2"
      icon="mdi-alert"
      :actionText="$t('misc.yes')"
      :cancelText="$t('misc.cancel')"
      :message="$t('meetings.draftNotEmty')"
      @action="goToMeetingsIndex(true)"
      @cancel="alertUserIfDraftNotEmpty = false"
    />
    <v-dialog
      v-model="showTemplateDialog"
      max-width="480"
      transition="dialog-top-transition"
    >
      <applyTemplateForm @closeDialog="showTemplateDialog = false" />
    </v-dialog>

    <selectOrCreateFollowup ref="select_or_create_followup"/>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import searchAndSelectUsers from "@/components/users/searchAndSelectUsers";
import loadingOverlay from "../shared/loadingOverlay";
import meetingSideBar from "../meetings/sidebar/meetingSideBar";
import topicShow from "../topics/topic_show";
import meetingTimeline from "./meetingTimeline";
import meetingShowMobile from "./meeting_show_mobile";
import headerToolbar from "@/components/shared/headerToolbar";
import topicToolbar from "./../topics/topicToolbar";
import meetingHome from "./meetingHome";
import meetingStatusChip from "./meetingStatusChip";
import meetingAgenda from "./meetingAgenda_2";
import userItem from "@/components/users/userItem";
import UPDATE_MEETING from "@/graphql/meetings/update.gql";
import userAvatarGroup from "../shared/userAvatarGroup";
import meeting from "@/mixins/meeting.js";
import customDialog from "@/components/shared/customDialog";
import applyTemplateForm from "@/components/meetings/applyTemplateForm";
import selectOrCreateFollowup from "@/components/followups/selectOrCreateFollowup";

export default {
  name: "meeting_show",
  mixins: [meeting],
  components: {
    loadingOverlay,
    meetingSideBar,
    draggable,
    topicShow,
    meetingTimeline,
    topicToolbar,
    meetingHome,
    headerToolbar,
    meetingStatusChip,
    meetingAgenda,
    searchAndSelectUsers,
    userItem,
    userAvatarGroup,
    meetingShowMobile,
    customDialog,
    applyTemplateForm,
    selectOrCreateFollowup
  },
  data() {
    return {
      meetingId: "",
      alertUserIfDraftNotEmpty: false,
      clockInterval: null,
      clock: null,
      refreshIndex: 0,
      drawerTeams: false,
      forceQuit: false,
      showTemplateDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      "meeting",
      "loadingMeeting",
      "meetingFetchFailed",
      "selectedTopicId",
      "selectedTopic",
      "currentUser",
    ]),
    isLastTopic() {
      return this.currentTopicIndex === this.meeting.topics.length - 1;
    },
    toolbarSubject() {
      if (!this.selectedTopic) return "";

      if (this.selectedTopic.follow_up)
        return this.topicName(this.selectedTopic);
      return this.selectedTopic.subject;
    },
    topicDescription() {
      if (this.selectedTopic) {
        if (this.selectedTopic.follow_up_id) {
          if (this.selectedTopic.follow_up)
            return this.selectedTopic.follow_up.description;
          else return this.selectedTopic.follow_up_description;
        }
        return this.selectedTopic.details;
      }
      return "";
    },
  },
  methods: {
    ...mapActions([
      "getMeeting",
      "connectToMeetingChannel",
      "disconnectFromMeetingChannel",
      "disconnectFromFollowUpChannel",
      "openDestroyDialog",
      "cleanCards",
    ]),
    openTopicDialog(topic) {
      this.$refs.meetingSidebar.$refs.agenda_ref[0].editTopic(topic);
    },
    openEditTitle() {
      this.$refs.meetingSidebar.selectedPanel = "details";
      this.$refs.meetingSidebar.$refs.details_ref[0].startEdition();
      this.$refs.meetingSidebar.panelDrawer = true;
    },
    openObjectivePanel() {
      this.$refs.meetingSidebar.panelDrawer = true;
      this.$refs.meetingSidebar.selectedPanel = "objectives";
    },
    openParticipantsPanel() {
      this.$refs.meetingSidebar.panelDrawer = true;
      this.$refs.meetingSidebar.selectedPanel = "users";
    },
    openMinutePanel() {
      this.$refs.meetingSidebar.panelDrawer = true;
      this.$refs.meetingSidebar.selectedPanel = "minute";
    },
    openMeetingAgenda() {
      this.$refs.meetingSidebar.panelDrawer = true;
      this.$refs.meetingSidebar.selectedPanel = "agenda";
    },
    updatedObjectives() {
      console.log("updated objectives");
      this.refreshIndex++;
    },
    startMeeting() {
      this.$apollo.mutate({
        mutation: UPDATE_MEETING,
        variables: { id: this.meeting.id, status: "started" },
      });
    },
    goToMeetingsIndex(forcequit = false) {
      this.forceQuit = forcequit;
      this.$router.push({ name: "meetings_index" });
    },
    showMeetingSideBar() {
      this.$refs.meetingSidebar.drawer = true;
    },
    openTemplatePanel() {
      console.log("open template panel");
      this.showTemplateDialog = true;
    },
  },
  watch: {
    clock() {
      // console.log("clock changed !")
    },
  },
  created() {
    this.meetingId = this.$route.params.id;
    this.getMeeting(this.meetingId);
    this.connectToMeetingChannel(this.meetingId);
    this.clockInterval = setInterval(() => {
      // Concise way to format time according to system locale.
      // can returns "3:48:00 am" for locale that need it
      this.clock = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
      }).format();
    }, 1000);
    document.title = this.$t("meetings.loadingMeeting");
    this.drawerTeams = Object.keys(this.$router.currentRoute.query).includes(
      "teams"
    );
  },
  beforeDestroy() {
    // clear interval for the real time clock to prevent memory leak
    clearInterval(this.clockInterval);
    this.meeting.topics.forEach((topic) => {
      if (topic.follow_up_id && !topic.not_visible)
        this.disconnectFromFollowUpChannel(topic.follow_up_id);
    });
    this.disconnectFromMeetingChannel(this.meetingId);
    this.cleanCards();
  },
  /* beforeRouteLeave(to, from, next) {
    var allDraftAreEmpty = true;
    this.meeting.topics.forEach((topic, index) => {
      if (this.$refs["topic_show_" + index]) {
        // Seems like the user went into this topic, we can check it for unpublished items.

        if (
          this.$refs["topic_show_" + index][0].$refs.draftEditor.draftItems
            .map((di) => di.editor.getHTML().replaceAll("<p></p>", ""))
            .join().length != 0
        ) {
          allDraftAreEmpty = false;
        }
      }
    });

    if (allDraftAreEmpty || this.forceQuit) {
      //We can close the meeting
      next();
    } else {
      //Display alert dialog
      this.alertUserIfDraftNotEmpty = true;
      next(false);
    }
  }, */
};
</script>

<style>
.timelineToolbar .v-toolbar__content {
  padding: 0;
}

.timelineToolbar {
  z-index: 4;
}
</style>
