var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-height":"calc(100vh - 176px)","height":"calc(100vh - 176px)"}},[(_vm.meeting.topics.length == 0)?_c('v-container',{staticStyle:{"height":"100%"}},[_c('v-row',{staticClass:"pt-0",staticStyle:{"height":"100%"},attrs:{"align":"center","justify":"center"}},[_c('div',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","max-width":"600px","src":"/images/svg_images/meetingHome.svg"}})],1),_c('v-card',{staticClass:"mx-auto",attrs:{"color":"transparent","flat":"","max-width":"300","tile":""}},[_c('h5',{staticClass:"text-center text-uppercase mb-4"},[_vm._v(_vm._s(_vm.$t("meetings.prepareMeeting")))]),_c('v-list',{attrs:{"dense":"","color":"transparent","rounded":""}},[_c('v-list-item',{staticClass:"mb-2 ",staticStyle:{"background-color":"white"},on:{"click":function($event){return _vm.addFirstTopic()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"#395A9E"}},[_vm._v("mdi-plus-thick")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("meetings.addFirstTopic")))])],1)],1),_c('v-list-item',{staticClass:"mb-2 ",staticStyle:{"background-color":"white"},on:{"click":function($event){return _vm.openParticipantsPanel()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"#395A9E"}},[_vm._v("mdi-account-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("meetings.inviteParticipants")))])],1)],1)],1)],1)],1)])],1):_vm._e(),(
      _vm.meeting.topics.length && _vm.showMeetingHome && _vm.meeting.status == 'pending'
    )?_c('v-container',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-card',{staticClass:"text-center mx-auto rounded-xl meetingStartInElevation",attrs:{"max-width":"360"}},[_c('v-card-text',{staticClass:"pt-0"},[(_vm.isMeetingInThePast)?_c('div',[_c('div',{staticClass:"py-4"},[_c('span',{staticClass:"meetingStartInBig"},[_vm._v(" "+_vm._s(_vm.$t("meetings.meetingShouldHaveStartedIn"))+" ")])]),_c('span',{key:_vm.startsAtRefresh},[_vm._v(_vm._s(_vm._f("timeAgo")(_vm.meeting.starts_at)))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMeetingInThePast),expression:"!isMeetingInThePast"}]},[_c('div',{staticClass:"py-4"},[_c('span',{staticClass:"meetingStartInBig"},[_vm._v(" "+_vm._s(_vm.$t("meetings.meetingStartIn"))+" ")])]),_c('countdown',{key:_vm.startsAtRefresh,attrs:{"endtime":_vm.meetingStartCountdown,"template":"meetingHome"}})],1)]),_c('v-card-text',{staticClass:"pt-0 pb-8 px-16"},[_c('v-btn',{staticClass:"white--text",attrs:{"large":"","depressed":"","color":"primary","block":""},on:{"click":function($event){return _vm.startMeeting()}}},[_vm._v(_vm._s(_vm.$t("meetings.startNow")))])],1)],1),_c('v-btn',{staticClass:"white--text mx-6 my-8",attrs:{"color":"button_blue","text":"","rounded":"","large":"","depressed":""},on:{"click":function($event){return _vm.openObjectivePanel()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-format-list-checks")]),_vm._v(" "+_vm._s(_vm.$t("meetings.defineMeetingObjectives"))+" ")],1)],1)],1)],1):_vm._e(),(
      _vm.meeting.topics.length && _vm.showMeetingHome && _vm.meeting.status == 'finished'
    )?_c('v-container',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","align":"center","justify":"center"}},[_c('v-card',{staticClass:"text-center mx-auto rounded-xl meetingStartInElevation",attrs:{"max-width":"460"}},[_c('div',{staticClass:"pt-4 pb-4"},[_c('span',{staticClass:"meetingStartInBig"},[_c('v-icon',{attrs:{"size":"28","left":"","color":"success"}},[_vm._v("mdi-text-box-check-outline")]),_vm._v(_vm._s(_vm.$t("meetings.meetingFinished")))],1)]),(_vm.meeting.objectives.length)?_c('v-expansion-panels',{staticClass:"text-left pa-4 px-10 pt-0",staticStyle:{"max-height":"400px"},attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.meeting.objectives.filter(function (mo) { return mo.done != false; }).length + "/" + _vm.meeting.objectives.length + " " + _vm.$t("meetings.meetingObjectivesCompleted"))+" ")]),_c('v-expansion-panel-content',{staticStyle:{"max-height":"360px","overflow-y":"auto"}},[(_vm.meeting.objectives.length)?_c('v-list',{staticStyle:{"max-height":"320px","overflow-y":"auto"},attrs:{"subheader":"","flat":""}},[_vm._l((_vm.meeting.objectives),function(todo,i){return [_c('v-hover',{key:'obj_' + i,scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-list-item',{staticClass:"objectiveItem"},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":"","color":todo.done ? 'success' : 'mid_grey'}},[_vm._v(" mdi-check-bold ")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',{staticClass:"body-2 pl-0 objectiveItemTitle caption",class:{
                              lineThrought: todo.done,
                              'font-weight-bold': todo.done,
                            }},[_vm._v(_vm._s(todo.content))])],1)],1)]}}],null,true)})]})],2):_vm._e()],1)],1)],1):_vm._e(),_c('v-card-text',{staticClass:"pt-0 pb-8 px-16"},[_c('v-btn',{staticClass:"white--text",attrs:{"large":"","depressed":"","color":"primary","block":"","disabled":_vm.loading},on:{"click":_vm.openMinutePanel}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-clipboard-text-search")]),_vm._v(_vm._s(_vm.$t("misc.meetingMinutes")))],1),_c('v-row',{staticClass:"mt-4"},[(_vm.meeting.minute_sended_at)?_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-icon',{staticClass:"pr-1",attrs:{"size":"14","color":"button_blue"}},[_vm._v("mdi-checkbox-marked-circle")]),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("meetings.sharedByEmail"))+" "),_c('b',[_vm._v(" "+_vm._s(_vm._f("timeAgo")(_vm.meeting.minute_sended_at)))])])],1):_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("meetings.minuteNotSharedYet"))+" ")])])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }