<template>
  <v-container fluid style="height: 100%; overflow-y: hidden" class="pa-0">
    <feedItemsWrapper
      mobileVersion
      :currentTopic="topic"
      v-if="displayDraft == false"
    />

    <v-btn v-if="!displayDraft" style="z-index: 40; position: absolute; right: 24px; bottom: 24px;" @click="displayDraft = true" v-model="newButton" color="button_blue" dark fab>
         
          <v-icon> mdi-playlist-edit </v-icon>
        </v-btn>


    <!-- <v-speed-dial
      v-model="newButton"
      absolute
      bottom
      small
      right
      direction="top"
      open-on-hover
      offset-x="48"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-if="!displayDraft" v-model="newButton" color="button_blue" dark fab>
          <v-icon v-if="newButton"> mdi-close </v-icon>
          <v-icon v-else> mdi-plus </v-icon>
        </v-btn>
      </template>
      <v-btn
        style="right: 12px"
        dark
        small
        class="white--text item_note"
        rounded
        @click="newFeedItem('note')"
        
      >
        <v-icon small class="pr-2"> mdi-note-plus </v-icon>
        {{ $t("feedItems.note") }}
      </v-btn>

      <v-btn
        style="right: 12px"
        dark
        small
        class="white--text item_decision"
        rounded
        @click="newFeedItem('decision')"
      >
        <v-icon small class="pr-2"> mdi-account-voice </v-icon>
        {{ $t("feedItems.decision") }}
      </v-btn>

      <v-btn
        style="right: 12px"
        dark
        small
        class="white--text item_task"
        rounded
        @click="newFeedItem('task')"
      >
        <v-icon small class="pr-2"> mdi-checkbox-marked-outline </v-icon>
        {{ $t("feedItems.task") }}
      </v-btn>
    </v-speed-dial> -->

    <feed-items-draft-mobile @close="displayDraft = false" v-show="displayDraft" :newFeedItemType="newFeedItemType"/>
  </v-container>
</template>

<script>
import feedItemsWrapper from "@/components/feed_items/feed_items_wrapper";
import feedItemsDraft from "@/components/feed_items/feed_items_draft";
import feedItemsDraftMobile from '@/components/feed_items/feed_items_draft_mobile.vue';

export default {
  name: "topicShowMobile",
  components: {
    feedItemsWrapper,
    feedItemsDraft,
    feedItemsDraftMobile,
  },
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      displayDraft: false,
      newFeedItemType: "note",
      newButton: false,
    };
  },
  computed: {},
  methods: {
    newFeedItem(type) {
      this.displayDraft = true;
      this.newFeedItemType = type;
    }
  },
  watch: {},
};
</script>

<style scoped>
.v-btn--floating {
  position: relative;
  left: 30px;
}
</style>
