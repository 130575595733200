<template>
  <div style="height: 100%">
    <!-- Feed items -->
    <v-card
      flat
      :tile="mobileVersion"
      color="white"
      height="100%"
      :class="{
        'mx-8': !noMargins && !mobileVersion,
        'rounded-lg': !mobileVersion,

        'pa-0': mobileVersion,
      }"
      v-show="displayFileViewer == false"
    >
      <v-toolbar dense flat color="transparent">
        <!-- Filter -->
        <v-col
          shrink
          style="max-width: 140px"
          :class="{ 'pa-0': mobileVersion }"
        >
          <v-select
            :items="filterItems"
            item-text="text"
            item-value="type"
            dense
            v-model="selectedFilter"
            hide-details
          >
          </v-select>
        </v-col>
        <v-spacer v-if="!mobileVersion"></v-spacer>
        <v-col
          shrink
          style="min-width: 150px; max-width: 300px"
          :class="{ 'pa-0': mobileVersion }"
        >
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            :label="$t('misc.search')"
            solo
            filled
            flat
            v-model="search"
            dense
            clearable
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-toolbar>
      <v-divider />
      <v-progress-linear
        indeterminate
        color="button_blue"
        :active="loadingQuery"
      ></v-progress-linear>
      <v-card-text
        class="pt-0 px-0"
        id="items_scroll_area"
        style="overflow-y: auto; height: calc(100% - 48px)"
      >
        <!-- *ton Loader -->
        <template v-if="loadingQuery">
          <div v-for="n in 4" :key="'skl_' + n" class="mt-4">
            <v-skeleton-loader
              type="card-heading"
              width="120px"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="paragraph"
              class="px-4"
            ></v-skeleton-loader>
          </div>
        </template>

        <!-- <jsonViewer :value="filteredFeedItemsByMeeting" /> -->
        <v-expansion-panels
          multiple
          v-model="expandedTopics"
          flat
          accordion
          class="pt-0"
          v-if="filteredFeedItemsByMeeting.length > 0"
        >
          <v-expansion-panel
            v-for="(topicItem, topicIndex) in filteredFeedItemsByMeeting"
            :key="topicIndex"
            :id="'topic_' + topicItem.topic_id"
          >
            <v-expansion-panel-header class="py-0">
              <span class="caption">
                {{ topicItem.meeting_name }}
                <!-- - {{ topicItem.topic_name }} -->
                <v-chip
                  label
                  v-if="currentTopic && topicItem.topic_id == currentTopic.id"
                  class="caption pa-0 px-2 ml-4 mb-1"
                  dark
                  small
                  color="timeline_lighter_blue"
                  ><span style="font-size: 0.8em">{{
                    $t("meetings.currentTopic")
                  }}</span></v-chip
                >
              </span>

              <v-spacer></v-spacer>

              <span v-show="!mobileVersion" class="caption" style="padding-right: 8px; flex: none">
                <v-icon size="12" class="pr-1">mdi-calendar-check</v-icon>
                {{ friendlyDate(topicItem.meeting_date) }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content :class="{mobileFeedItemsExpansion: mobileVersion}">
              <feedItemsList
                :feed_items="topicItem.feed_items"
                :topic_id="topicItem.topic_id"
                @openFile="openFile"
                :mobileVersion="mobileVersion"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <template v-else>
          <v-row no-gutters :class="{'px-16' :!mobileVersion, 'pt-8':!mobileVersion, 'px-2': mobileVersion, 'pt-2': mobileVersion}" v-if="!loadingQuery">
            <v-col cols="12">
              <v-alert
                color="button_blue"
                dark
                icon="mdi-information"
                border="left"
                class="caption"
              >
                {{
                  selectedFilter == "all" && !search
                    ? $t("feedItemsFilter.noItems")
                    : $t("feedItemsFilter.noItemsFiltered")
                }}
              </v-alert>
            </v-col>
          </v-row>
        </template>
        <!-- Show that more items are available but hidden -->
        <v-row
          class="pb-8"
          no-gutters
          v-if="showOnlyCurrentTopicItems && topics.length > 1"
        >
          <v-col cols="12" justify="center" align="center">
            <v-icon class="pr-2" small color="button_blue"
              >mdi-information</v-icon
            >
            <span class="caption">{{ $t("meetings.historyHidden") }}</span>
            <br />
            <v-btn
              class="mt-4"
              @click="showOnlyCurrentTopicItems = false"
              color="button_blue"
              small
              dark
              depressed
              >{{ $t("meetings.displayHistoryData") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- File viewer -->
    <div
      style="height: 100%"
      v-if="displayFileViewer == true"
      :class="{ 'mx-8': !noMargins }"
    >
      <fileViewerWrapper
        @close="displayFileViewer = false"
        :filesByMeeting="filtedFilesByMeeting"
        :openedFile="fileToOpen"
        :mobileVersion="mobileVersion"
      />
    </div>
  </div>
</template>

<script>
import feedItemsList from "../feed_items/feed_items_list";
import fileViewerWrapper from "../file_viewer/file_viewer_wrapper";
import date from "@/mixins/date";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "feedItemIndex",
  mixins: [date],
  components: { feedItemsList, fileViewerWrapper },
  props: {
    noMargins: { type: Boolean, default: false },
    currentTopic: {
      type: Object,
      required: false,
    },
    followUpId: {
      type: String,
      required: false,
    },
    mobileVersion: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      displayFileViewer: false,
      fileToOpen: null,
      search: "",
      showOnlyCurrentTopicItems: false,
      selectedFilter: "all",
      loadingQuery: true,
      filterItems: [
        {
          type: "all",
          text: this.$t("feedItemsFilter.all"),
        },
        {
          type: "note",
          text: this.$t("feedItemsFilter.notes"),
        },
        {
          type: "decision",
          text: this.$t("feedItemsFilter.decisions"),
        },
        {
          type: "task",
          text: this.$t("feedItemsFilter.tasks"),
        },
        {
          type: "file",
          text: this.$t("feedItemsFilter.files"),
        },
        {
          type: "url",
          text: this.$t("feedItemsFilter.urls"),
        },
      ],
      expandedTopics: [],
    };
  },
  computed: {
    ...mapGetters(["topicLists", "topicListByFilter", "topicStoreRefresh"]),
    filterQuery() {
      if (this.currentTopic) {
        if (this.currentTopic.follow_up_id)
          return `follow_up_id == '${this.currentTopic.follow_up_id}'`;
        else return `topics.id == '${this.currentTopic.id}'`;
      } else if (this.followUpId) {
        return `follow_up_id == '${this.followUpId}'`;
      }
      return "";
    },
    topics() {
      /*
        The following line make this getter cache
        update whenever topicStoreRefresh change.
        Do not remove it.
      */
      this.topicStoreRefresh;

      return this.topicListByFilter(this.filterQuery) || [];
    },
    filteredFeedItemsByMeeting() {
      if (this.loadingQuery) return [];

      let _topics = this.topics.map((t) => {
        return {
          topic_id: t.id,
          topic_name: t.subject,
          meeting_name: t.meeting_title,
          meeting_date: t.meeting_starts_at,
          feed_items: t.feed_items.filter((fi) =>
            this.feedItemsTypeAndSearchFilter(fi)
          ),
        };
      });

      //filter out those who don't have any feed events inside.
      _topics = _topics.filter((t) => t.feed_items.length > 0);

      if (this.currentTopic && this.showOnlyCurrentTopicItems)
        return _topics.filter((t) => t.topic_id == this.currentTopic.id);

      return _topics;
    },
    filtedFilesByMeeting() {
      if (this.loadingQuery) return [];

      let _topics = this.topics.map((t) => {
        return {
          topic_id: t.id,
          topic_name: t.subject,
          meeting_name: t.meeting_title,
          meeting_date: t.meeting_starts_at,
          feed_items: t.feed_items.filter((fi) => fi.feed_item_type == "file"),
        };
      });

      //filter out those who don't have any feed events inside.
      _topics = _topics.filter((t) => t.feed_items.length > 0);

      if (this.currentTopic && this.showOnlyCurrentTopicItems)
        return _topics.filter((t) => t.topic_id == this.currentTopic.id);

      return _topics;
    },
  },
  methods: {
    ...mapActions([
      //"getTopics",
      "removeTopics",
      "connectToFollowUpChannel",
      "disconnectFromFollowUpChannel",
    ]),
    toggleOnlyCurrentMeeting() {
      this.scrollToTop();
    },
    scrollToCurrentTopic() {
      if (!this.currentTopic) return;

      let id = `topic_${this.currentTopic.id}`;
      if (!document.getElementById(id)) return;

      console.log("scroll to top");
      this.$vuetify.goTo(`#${id}`, {
        duration: 1000,
        container: "#items_scroll_area",
      });
    },
    scrollToTop() {
      // Marche poas
      this.$vuetify.goTo(0, {
        duration: 1000,
        container: "#items_scroll_area",
      });
    },
    feedItemsTypeAndSearchFilter(fi) {
      return this.feedItemsTypeFilter(fi) && this.feedItemsSearchFilter(fi);
    },
    feedItemsTypeFilter(fi) {
      if (this.selectedFilter == "all") return true;
      return fi.feed_item_type == this.selectedFilter;
    },
    feedItemsSearchFilter(fi) {
      if (!this.search) return true;

      switch (fi.feed_item_type) {
        case "note":
        case "decision":
        case "task":
          return (
            fi.content.toLowerCase().search(this.search.toLowerCase()) != -1
          );
        case "url":
        case "file":
          // Temp
          return false;
          return (
            fi.filename.toLowerCase().search(this.search.toLowerCase()) != -1
          );
      }
    },
    expandAllTopic() {
      this.expandedTopics = Array.from(
        Array(this.filteredFeedItemsByMeeting.length).keys()
      );
    },
    //queryItems() {
    //  if (!this.currentTopic && !this.followUpId) return;
//
    //  this.loadingQuery = true;
    //  this.getTopics(this.filterQuery)
    //    .then(() => {
    //      this.loadingQuery = false;
    //      this.expandAllTopic();
    //      if (this.currentTopic) {
    //        this.$nextTick(() => {
    //          this.scrollToCurrentTopic();
    //        });
    //      }
    //    })
    //    .catch(() => {
    //      this.loadingQuery = false;
    //    });
    //},
    openFile(event) {
      console.log("file to open: ", event);
      this.displayFileViewer = true;
      this.fileToOpen = event;
    },
  },
  mounted() {
    //this.queryItems();
    if (this.followUpId) this.connectToFollowUpChannel(this.followUpId);

    if (this.currentTopic && this.currentTopic.follow_up_id)
      this.connectToFollowUpChannel(this.currentTopic.follow_up_id);
  },
  watch: {
    showOnlyCurrentTopicItems() {
      // Scroll to top when user cliqued on display only current item
      if (this.currentTopic && this.showOnlyCurrentTopicItems)
        this.$nextTick(() => {
          this.scrollToCurrentTopic();
        });
    },
    filteredFeedItemsByMeeting() {
      // Expand current topic panel when update
      this.filteredFeedItemsByMeeting.forEach((ffim, i) => {
        if (this.currentTopic && ffim.topic_id == this.currentTopic.id) {
          if (this.expandedTopics.indexOf(i) == -1) this.expandedTopics.push(i);
        }
      });
    },
  },
  beforeDestroy() {
    this.removeTopics(this.filterQuery);

    if (this.followUpId) this.disconnectFromFollowUpChannel(this.followUpId);

    if (this.currentTopic && this.currentTopic.follow_up_id)
      this.disconnectFromFollowUpChannel(this.currentTopic.follow_up_id);
  },
};
</script>


<style>
.mobileFeedItemsExpansion .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
