<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :class="{'px-2': !mobileVersion, 'mx-1': !mobileVersion, 'px-0': mobileVersion, 'mx-1': mobileVersion}"
        min-width="26px"
        :text="!active"
        :color="color"
        depressed
        @click="$emit('btnClic')"
        small
      >
        <v-icon size="22" color="#585858">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "textEditorToolbarButton",

  props: {
    color: {
      type: String,
      default: "mid-grey",
    },
    icon: {
      type: String,
      default: "?",
    },
    active: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    mobileVersion: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>
