<template>
  <v-card flat>
    <!-- TABS - TOPIC OR FOLLOW UP -->
    <v-container fluid class="pa-6 pt-2">
      <v-tabs
        height="36px"
        background-color="transparent"
        color="button_blue"
        v-model="selectedTab"
        left
      >
        <v-tab href="#newTopic">{{ $t("meetings.newTopic") }}</v-tab>

        <v-tab href="#fromFollowup">{{ $t("meetings.fromFollowup") }}</v-tab>
      </v-tabs>
    </v-container>

    <v-card-text :class="{ 'pa-0': dense }">
      <v-tabs-items v-model="selectedTab">
        <!-- New topic -->
        <v-tab-item value="newTopic" class="px-4">
          <v-text-field
            v-model="name"
            :label="$t('misc.title')"
            placeholder=""
            filled
            dense
            autocomplete="off"
          />

          <v-textarea
            v-model="description"
            :label="$t('misc.description')"
            placeholder=""
            filled
            dense
            autocomplete="off"
            rows="2"
          />

          <v-text-field
            v-if="meeting.timed_topics"
            v-model.number="duration"
            :label="$t('meetings.durationMinutes')"
            placeholder=""
            filled
            dense
            autocomplete="off"
            type="number"
            :rules="[cantBeNegative(true), cantBeNull(meeting.timed_topics)]"
            hide-details="auto"
            min="0"
            step="1"
            :disabled="!meeting.timed_topics"
            :hint="
              !meeting.timed_topics ? $t('meetings.youNeedTimedTopics') : ''
            "
            :persistent-hint="!meeting.timed_topics"
            @change="duration = Math.round(duration)"
          />
          <v-text-field
            v-else
            :value="$t('meetings.noDuration')"
            :label="$t('meetings.durationMinutes')"
            placeholder="no duration"
            filled
            dense
            disabled
            :hint="$t('meetings.youNeedTimedTopics')"
            :persistent-hint="true"
          >
          </v-text-field>

          <v-autocomplete
            class="py-4"
            v-model="userId"
            :items="meetingUsers"
            :item-text="userName"
            item-value="id"
            dense
            filled
            :label="$t('meetings.topicSpeaker')"
            clearable
          >
            <template v-slot:item="data">
              <div class="mr-4">
                <userAvatar
                  :size="20"
                  :show_name_in_tooltip="false"
                  :first_name="data.item.first_name"
                  :last_name="data.item.last_name"
                  :avatar_url="data.item.avatar_url"
                  :email="data.item.email"
                />
              </div>
              {{ userName(data.item) }}
            </template>
          </v-autocomplete>
        </v-tab-item>

        <!-- From Followup -->
        <v-tab-item value="fromFollowup" class="px-4">
          <v-autocomplete
            :disabled="projectsLoading"
            :loading="projectsLoading"
            v-model="selectedProjectId"
            :items="projects"
            item-value="id"
            item-text="name"
            dense
            filled
            clearable
            :label="
              projectsLoading
                ? $t('followUps.loadingProjects')
                : $t('projects.filterProject')
            "
          >
            <template v-slot:item="data">
              <v-list-item-icon>
                <customIcon
                  :color="data.item.color"
                  name="project"
                  height="16"
                  style="position: relative; top: 5px"
                  ><iconProject
                /></customIcon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="selectedFollowupId"
            :items="filteredFollowups"
            item-text="name"
            item-value="id"
            dense
            filled
            :label="$t('navigationMenu.followUp')"
          >
            <template v-slot:item="data">
              <v-list-item-icon style="display: block">
                <customIcon color="#545c74" name="followup" height="12"
                  ><iconFollowup
                /></customIcon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ data.item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>

          <v-text-field
            v-if="meeting.timed_topics"
            v-model.number="duration"
            :label="$t('meetings.durationMinutes')"
            placeholder=""
            filled
            dense
            autocomplete="off"
            type="number"
            :rules="[cantBeNegative(true), cantBeNull(meeting.timed_topics)]"
            hide-details="auto"
            min="0"
            step="1"
            :disabled="!meeting.timed_topics"
            :hint="
              !meeting.timed_topics ? $t('meetings.youNeedTimedTopics') : ''
            "
            :persistent-hint="!meeting.timed_topics"
            @change="duration = Math.round(duration)"
          >
          </v-text-field>
          <v-text-field
            v-else
            :value="$t('meetings.noDuration')"
            :label="$t('meetings.durationMinutes')"
            placeholder="no duration"
            filled
            dense
            disabled
            :hint="$t('meetings.youNeedTimedTopics')"
            :persistent-hint="true"
          >
          </v-text-field>

          <v-autocomplete
            class="py-4"
            v-model="userId"
            :items="meetingUsers"
            :item-text="userName"
            item-value="id"
            dense
            filled
            :label="$t('meetings.topicSpeaker')"
            clearable
          >
            <template v-slot:item="data">
              <div class="mr-4">
                <userAvatar
                  :size="20"
                  :show_name_in_tooltip="false"
                  :first_name="data.item.first_name"
                  :last_name="data.item.last_name"
                  :avatar_url="data.item.avatar_url"
                  :email="data.item.email"
                />
              </div>
              {{ userName(data.item) }}
            </template>
          </v-autocomplete>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <!-- Back button that can emit if component is used as child of another component -->
      <v-btn
        v-if="displayBackButton"
        text
        @click="$emit('back')"
        color="almost_black"
        small
        depressed
      >
        <v-icon class="pr-1" small>mdi-arrow-left-thick</v-icon>
        {{ $t("misc.back") }}
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        depressed
        small
        text
        :disabled="!canSave"
        color="button_blue"
        @click="saveTopic(false)"
      >
        <v-icon class="pr-1" small>mdi-plus</v-icon>
        {{ $t("meetings.addAnotherTopic") }}
      </v-btn>

      <v-btn
        class="white--text"
        depressed
        small
        :disabled="!canSave"
        @click="saveTopic(true)"
        color="button_blue"
      >
        {{ $t("meetings.saveTopic") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FOLLOW_UP_INDEX from "@/graphql/follow_ups/index.gql";
import CREATE_TOPIC from "@/graphql/topics/create.gql";
import { mapActions, mapGetters } from "vuex";
import rules from "@/mixins/rules";
import meeting from "@/mixins/meeting";
import customIcon from "@/components/custom_icons/customIcon";
import iconFollowup from "@/components/custom_icons/iconFollowup";
import userAvatar from "@/components/shared/userAvatar";
import users from "@/mixins/users.js";
import PROJECT_INDEX from "@/graphql/projects/index.gql";

import iconProject from "@/components/custom_icons/iconProject";
export default {
  name: "addTopicOrSelectTemplate",
  mixins: [meeting, rules, users],
  components: {
    customIcon,
    iconFollowup,
    iconProject,
    userAvatar,
  },
  props: {
    displayBackButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    dense: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selectedTab: "newTopic",
      name: "",
      description: "",
      duration: 15,
      userId: "",
      selectedProjectId: "",
      projectsLoading: true,
      projects: [],
      selectedFollowupId: "",
      followups: [],
    };
  },
  computed: {
    ...mapGetters(["meeting", "meetingUsers", "selectedOrganisationId"]),
    canSave() {
      if (this.meeting.timed_topics && this.duration < 1) return false;

      if (this.selectedTab == "newTopic") return this.name != "";

      if (this.selectedTab == "fromFollowup")
        return this.selectedFollowupId != "";
    },
    filteredFollowups() {
      if (!this.selectedProjectId) return this.followups;
      else
        return this.followups.filter((f) => {
          return f.project_id == this.selectedProjectId;
        });
    },
  },
  methods: {
    ...mapActions(["getMeeting", "setSelectedTopicId"]),
    close() {
      this.resetFields();
      this.$emit("close");
    },
    resetFields() {
      this.name = "";
      this.description = "";
      this.selectedFollowupId = "";
      this.duration = 15;
      this.userId = "";
    },
    saveTopic(closeOnSave) {
      if (this.loading) return;

      this.loading = true;

      let variables = {};

      if (this.selectedTab === "newTopic") {
        variables = {
          subject: this.name,
          details: this.description,
          duration: this.duration,
          meeting_id: this.meeting.id,
          follow_up_id: "",
          user_id: this.userId,
        };
      }
      if (this.selectedTab == "fromFollowup") {
        variables = {
          duration: this.duration,
          meeting_id: this.meeting.id,
          follow_up_id: this.selectedFollowupId,
          user_id: this.userId,
        };
      }

      this.$apollo
        .mutate({
          mutation: CREATE_TOPIC,
          variables: variables,
        })
        .then(({ data }) => {
          this.loading = false;
          console.log(data.create_topic);
          this.$amplitude.getInstance().logEvent("Create topic", { name: this.name });

          this.getMeeting(this.meeting.id);

          if (closeOnSave) {
            this.close();
          }
          this.resetFields();
          this.setSelectedTopicId(data.create_topic.id);
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    getFollowups() {
      this.$apollo
        .query({
          query: FOLLOW_UP_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.followups = data.follow_ups;
        });
    },
    getProjects() {
      this.$apollo
        .query({
          query: PROJECT_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
          },
        })
        .then(({ data }) => {
          console.log("project gets");
          console.log(data);
          this.projects = data.projects;
          this.projectsLoading = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
  },
  mounted() {},
  created() {
    this.getProjects();
    this.getFollowups();
  },
  beforeDestroy() {},
};
</script>

<style scoped>
</style>

