<template>
  <div>
    <v-fade-transition>
      <div
        id="panelOverlay"
        v-if="panelDrawer"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0.8;
          z-index: 1;
        "
        @click="closePanel()"
      />
    </v-fade-transition>
    <!--  z-index: 2; -->
    <v-navigation-drawer
      v-model="sideBarDrawer"
      class="sidebarDrawer"
      color="timeline_blue"
      width="64"
      permanent
      floating
      stateless
    >
      <v-list
        dark
        height="100%"
        class="text-center dontCloseMeetingSidebarOnClick"
      >
        <v-list-item dark class="px-0 justify-center fill-height">
          <v-list-item-content class="text-center">
            <template v-for="panel in panels">
              <v-tooltip disabled right color="timeline_blue" :key="panel.name">
                <template v-slot:activator="{ on }">
                  <v-list-item
                    v-on="on"
                    @click="selectView(panel.name)"
                    :class="{ activePanel: selectedPanel == panel.name }"
                    class="pb-4"
                  >
                    <span
                      class="font-weigt-bold white--text"
                      style="
                        width: 100%;
                        position: absolute;
                        bottom: 16px;
                        left: 0;
                        font-size: 9px;
                      "
                      >{{ panel.label }}</span
                    >
                    <v-list-item-icon class="mx-auto">
                      <template v-if="panel.name == 'users'">
                        <v-badge
                          :content="meetingUsers.length"
                          :value="meetingUsers"
                          color="transparent"
                          overlap
                          bottom
                          right
                          offset-y="18"
                          offset-x="4"
                        >
                          <v-icon>{{ panel.icon }}</v-icon>
                        </v-badge>
                      </template>

                      <template v-else>
                        <v-icon>{{ panel.icon }}</v-icon></template
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <span>{{ panel.tooltip }}</span></v-tooltip
              >
            </template>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      :value="panelDrawer"
      class="panelDrawer"
      color="white"
      clipped
      stateless
      :width="selectedPanelWidth"
    >
      <v-window style="height: 100%" :value="selectedPanel">
        <v-window-item
          class="quickSlide"
          v-for="panel in panels"
          :key="panel.name + '_c'"
          :value="panel.name"
          style="height: 100%"
          eager
        >
          <component
            @updatedObjectives="updatedObjectives"
            @close="closePanel"
            @openNewTopicDialog="$emit('openNewTopicDialog')"
            v-bind:is="panel.component"
            v-bind:ref="panel.name + '_ref'"
          />
        </v-window-item>
      </v-window>
    </v-navigation-drawer>
  </div>
</template>

<script>
import participantsPanel from "./participants_panel.vue";
import objectivesPanel from "./objectives_panel.vue";
import agendaPanel from "./agenda_panel.vue";
import detailsPanel from "./details_panel.vue";
import minutePanel from "./minute_panel.vue";
import { mapGetters } from "vuex";

export default {
  name: "MeetingSideBar",
  mixins: [],
  components: {
    participantsPanel,
    objectivesPanel,
    agendaPanel,
    detailsPanel,
    minutePanel,
  },
  props: {},
  data() {
    return {
      sideBarDrawer: true,
      panelDrawer: false,
      selectedPanel: "",
      panels: [
        {
          name: "agenda",
          icon: "mdi-clipboard-list-outline",
          tooltip: this.$t("meetings.sidebar.agendaTooltip"),
          panelWidth: "440",
          component: "agendaPanel",
          label: this.$t("meetings.sidebar.agendaLabel"),
        },
        {
          name: "users",
          icon: "mdi-account-multiple-plus",
          tooltip: this.$t("meetings.sidebar.participantsTooltip"),
          panelWidth: "440",
          component: "participantsPanel",
          label: this.$t("meetings.sidebar.participantsLabel"),
        },
        {
          name: "objectives",
          icon: "mdi-format-list-checks",
          tooltip: this.$t("meetings.sidebar.objectivesTooltip"),
          panelWidth: "440",
          component: "objectivesPanel",
          label: this.$t("meetings.sidebar.objectivesLabel"),
        },
        {
          name: "details",
          icon: "mdi-text",
          tooltip:this.$t("meetings.sidebar.detailsTooltip"),
          panelWidth: "440",
          component: "detailsPanel",
          label: this.$t("meetings.sidebar.detailsLabel"),
        },
        {
          name: "minute",
          icon: "mdi-clipboard-text-outline",
          tooltip: this.$t("meetings.sidebar.minuteTooltip"),
          panelWidth: "100%",
          component: "minutePanel",
          label: this.$t("meetings.sidebar.minuteLabel"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["meetingUsers", "objectives", "selectedTopicId"]),
    selectedPanelWidth() {
      if (this.selectedPanel != "") {
        return this.panels.find((s) => s.name == this.selectedPanel).panelWidth;
      }
      return 320;
    },
  },
  methods: {
    updatedObjectives() {
      this.$emit("updatedObjectives");
    },
    excludedFromClickOutside() {
      console.log(
        "Excluded from click outside menu: ",
        document.querySelector(".dontCloseMeetingSidebarOnClick")
      );
      return [document.querySelector(".dontCloseMeetingSidebarOnClick")];
    },
    closePanel() {
      this.panelDrawer = false;
      this.selectedPanel = "";
      // DETECTION DE SI JLE FERME OU PAS
      console.log("close panel");
    },
    selectView(view_index) {
      if (view_index == this.selectedPanel) {
        this.closePanel();
      } else {
        this.panelDrawer = true;
        this.selectedPanel = view_index;
      }
      console.log("selectedPanel:", this.selectedPanel);
      console.log("this.panelDrawer:", this.panelDrawer);
    },
  },
  mounted() {},
  watch: {
    selectedTopicId() {
      this.closePanel();
    },
  },
};
</script>

<style scoped>
</style>

<style>
.activePanel {
  background-color: var(--v-timeline_lighter_blue);
  -webkit-box-shadow: inset 4px 0px 0px 0px white;
  -moz-box-shadow: inset 4px 0px 0px 0px white;
  box-shadow: inset 4px 0px 0px 0px white;
  color: white !important;
}
.sidebarDrawer {
  position: absolute !important;
  top: 96px !important;
  height: calc(100vh - 120px) !important;
  z-index: 5;
}
.panelDrawer {
  position: absolute !important;
  top: 96px !important;
  height: calc(100vh - 138px) !important;
  left: 64px;
  z-index: 1;
}
</style>

