<template>
  <v-list dense class="pa-0" color="light_grey">
    <v-list-item dense  :href="url.url" target="_blank" class="my-0">
      <v-list-item-icon   :class="{ 'mr-2': mobileVersion}">
       <v-icon :small="mobileVersion">mdi-link-variant</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="py-0">
        <v-list-item-title v-text="url.filename"></v-list-item-title>

        <v-list-item-subtitle v-text="url.url"></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action class="my-0">
        <v-btn icon small> <v-icon small color="grey"> mdi-content-copy </v-icon></v-btn>
           
      </v-list-item-action>
    </v-list-item>
  </v-list>

</template>

<script>
import date from "@/mixins/date";

export default {
  name: "assetUrlItem",
  mixins: [date],
  components: {},
  props: {
    url: {
      type: Object,
    },
     mobileVersion: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    
  },
  mounted() {},
};
</script>

 