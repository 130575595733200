<template>
  <v-container
    fluid
    class="pa-0 light_grey_background"
    style="height: calc(100vh - 72px); width: 100%; background-color: white"
  >
    <!-- Meeting title + status -->
    <v-system-bar dark app flat height="30" color="timeline_blue darken-1">
      <span class="white--text caption" v-if="meeting && meeting.title">{{
        meeting.title
      }}</span>
      <meetingStatusChip
        class="ml-2"
        style="transform: scale(0.8)"
        v-if="meeting && meeting.status"
        :meetingStatus="meeting.status"
      />
      <v-spacer></v-spacer>
      <template v-if="meeting.status !== 'pending'">
        <v-tooltip bottom color="button_blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              dark
              color="white"
              v-bind="attrs"
              v-on="on"
              x-small
              @click="restartMeeting()"
            >
              <v-icon size="22">mdi-rotate-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("meetings.restartMeeting") }}</span>
        </v-tooltip>
      </template>
    </v-system-bar>

    <!-- Topic selecteur -->
    <v-toolbar
      flat
      dense
      color="timeline_blue"
      class="px-0 drawerTeamsTopicToolbar"
    >
      <v-select
        dark
        solo
        full-width
        hide-details
        background-color="transparent"
        :items="meeting.topics"
        item-text="subject"
        item-value="id"
        :label="$t('meetings.meetingAgenda')"
        class="ma-0 pa-0"
        dense
        flat
        :value="selectedTopicId"
        @change="setSelectedTopicId($event)"
      >
        <template v-slot:item="data">
          <userAvatar
            v-if="data.item.user"
            :size="20"
            :show_name_in_tooltip="false"
            :first_name="data.item.user.first_name"
            :last_name="data.item.user.last_name"
            :avatar_url="data.item.user.avatar_url"
            :email="data.item.user.email"
          />
          <div v-else>
            <v-icon small color="button_blue" style="position: relative; left: 3px"
              >mdi-account-multiple</v-icon
            >
          </div>

          <v-list-item-subtitle class="pl-4" v-if="data.item.follow_up_id">
            {{ topicName(data.item) }}
          </v-list-item-subtitle>

          <v-list-item-subtitle class="pl-4" v-else>
            {{ data.item.subject }}
          </v-list-item-subtitle>

          <v-list-item-action class="mr-4">
            <span v-if="meeting.timed_topics" class="caption font-weight-bold"
              >{{ data.item.duration }}{{ $t("meetings.minutes") }}
            </span>
          </v-list-item-action>
        </template>

        <template v-slot:selection="data">
          <userAvatar
             v-if="data.item.user"
            :size="20"
            :show_name_in_tooltip="false"
            :first_name="data.item.user.first_name"
            :last_name="data.item.user.last_name"
            :avatar_url="data.item.user.avatar_url"
            :email="data.item.user.email"
          />
          <div v-else>
            <v-icon  small color="white" style="position: relative; left: 3px"
              >mdi-account-multiple</v-icon
            >
          </div>
          <v-list-item-subtitle class="pl-4" v-if="data.item.follow_up_id">
            {{ topicName(data.item) }}
          </v-list-item-subtitle>

          <v-list-item-subtitle class="pl-4" v-else>
            {{ data.item.subject }}
          </v-list-item-subtitle>
        </template>

        <template slot="append-outer">
          <v-btn
            small
            color="white"
            icon
            @click.prevent="startMeeting()"
            v-if="meeting.status === 'pending'"
            class="mr-3"
          >
            <v-icon size="28">mdi-play-circle</v-icon>
          </v-btn>

          <v-chip
            rounded
            small
            color="rgba(0,0,0,0.2)"
            class="caption font-weight-bold white--text"
            style="left: -12px"
            v-show="
              meeting.status === 'started' &&
              selectedTopicIndex === currentTopicIndex
            "
            @click.stop="isLastTopic ? endMeeting() : playNextTopic()"
          >
            {{ isLastTopic ? $t("misc.end") : $t("misc.next") }}
            <v-icon small class="pl-1" style="padding-bottom: 1px"
              >mdi-chevron-double-right</v-icon
            >
          </v-chip>
        </template>
      </v-select>
    </v-toolbar>

    <template v-if="meeting.topics.length && selectedTopicId">
      <v-window :value="selectedTopicId" style="height: 100%">
        <v-window-item
          v-for="topic in meeting.topics"
          :key="`topic-${topic.id}`"
          :value="topic.id"
          style="height: 100%"
        >
          <topicShowMobile :topic="topic" />
        </v-window-item>
      </v-window>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import meetingStatusChip from "./meetingStatusChip";
import userAvatar from "../shared/userAvatar";
import topicShowMobile from "../topics/showMobile";
import meeting from "@/mixins/meeting";

export default {
  name: "meeting_show_mobile",
  mixins: [meeting],
  data() {
    return {};
  },
  components: { userAvatar, meetingStatusChip, topicShowMobile },
  computed: {
    ...mapGetters([
      "meeting",
      "loadingMeeting",
      "meetingFetchFailed",
      "selectedTopicId",
      "selectedTopic",
      "currentUser",
      "selectedTopicIndex",
      "currentTopicIndex",
    ]),
    isLastTopic() {
      return this.currentTopicIndex === this.meeting.topics.length - 1;
    },
  },
  methods: {
    ...mapActions(["setSelectedTopicId"]),
  },
  watch: {},
  created() {},
  beforeDestroy() {},
};
</script>

<style>
.drawerTeamsTopicToolbar .v-toolbar__content {
  padding: 0;
}
</style>
