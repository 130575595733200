import UPDATE_MEETING from "@/graphql/meetings/update.gql";
import { mapActions, mapGetters } from 'vuex';
import users from "@/mixins/users.js";
export default {
  name: 'meeting',
  mixins: [users],
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(["meeting", "selectedTopicId", "currentTopicIndex"]),
    isMeetingStopped() {
      return (
        this.meeting.status === "paused" ||
        this.meeting.status === "pending" ||
        this.meeting.status === "finished"
      );
    },
  },
  methods: {
    ...mapActions(["setSelectedTopicId"]),
    topicSpeaker(topic) {
      if (topic.user)
        return this.userName(topic.user)
      return this.$t('misc.everyone');
    },
    playPause() {
      this.updateMeetingStatus(this.isMeetingStopped ? "started" : "paused");
    },
    startMeeting() {
      this.updateMeetingStatus("started");
    },
    playNextTopic() {
      if (this.isLastTopic) return;

      console.log('INTEREST HERER')
      console.log(this.meeting.topics[this.currentTopicIndex + 1].id)
      this.$apollo.mutate({
        mutation: UPDATE_MEETING,
        variables: {
          id: this.meeting.id,
          status: "started",
          current_topic_elapsed_seconds: 0,
          current_topic_id: this.meeting.topics[this.currentTopicIndex + 1].id,
        },
      });
    },
    endMeeting() {
      this.updateMeetingStatus("finished");
      this.setSelectedTopicId(null);
    },
    updateMeetingStatus(status, restart) {
      this.$apollo.mutate({
        mutation: UPDATE_MEETING,
        variables: { id: this.meeting.id, status: status },
      });
    },
    restartMeeting() {
      if (this.meeting.topics.length === 0) return;
      //this.meetingPaused = true;
      let vars = {
        id: this.meeting.id,
        status: "pending",
        current_topic_elapsed_seconds: 0,
        current_topic_id: this.meeting.topics[0].id,
      };
      this.$apollo.mutate({
        mutation: UPDATE_MEETING,
        variables: vars,
      });
    },
    setMeetingStatus() {
      /* Available status : pending canceled started paused finished */
      if (this.meeting.status === "started") {
        /* Set selected topic to current topic */
        this.setSelectedTopicId(this.meeting.current_topic_id);
      }
    },
    topicName(topic) {
      if (topic.follow_up_id) {
        if (topic.follow_up)
          return topic.follow_up.name;
        else
          return topic.follow_up_name;
      }
      return topic.subject;
    }
  },
  mounted() {
  },
  watch: {
    "meeting.status"() {
      this.setMeetingStatus();
    },
    "meeting.current_topic_id"() {
      this.setMeetingStatus();
    },
    meeting() {
      this.setMeetingStatus();
      console.log("meeting rehydrate");
    },
  },
}
