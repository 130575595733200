var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{attrs:{"disabled":_vm.dragging},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{staticClass:"pb-1 my-0 px-4",attrs:{"justify":"start","align":"start"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-2 py-1",style:('border-left: 4px solid ' +
          _vm.borderColor(_vm.item.feed_item_type) +
          ' !important'),attrs:{"tile":"","flat":"","color":hover &&
          (_vm.item.feed_item_type == 'task' ||
            _vm.item.feed_item_type == 'note' ||
            _vm.item.feed_item_type == 'decision')
            ? 'light_grey'
            : 'transparent'}},[(hover)?_c('v-card',{staticClass:"elevation-4",staticStyle:{"position":"absolute","right":"12px","top":"-8px","z-index":"2"}},[(
              _vm.item.feed_item_type == 'task' ||
              _vm.item.feed_item_type == 'note' ||
              _vm.item.feed_item_type == 'decision'
            )?_c('v-btn',{attrs:{"icon":"","small":"","color":"button_blue"},on:{"click":function($event){return _vm.$emit('editItem', _vm.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteFeedItem(_vm.item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e(),(
            _vm.item.feed_item_type == 'task' ||
            _vm.item.feed_item_type == 'note' ||
            _vm.item.feed_item_type == 'decision'
          )?_c('div',{class:_vm.wrapperClass(_vm.item.feed_item_type)},[(_vm.item.feed_item_type == 'task')?_c('div',[_c('v-simple-checkbox',{staticClass:"pa-0 ma-0",attrs:{"color":"item_task"},on:{"input":function($event){return _vm.toggleTaskDone($event)}},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1):_vm._e(),_c('div',{staticClass:"body-2 editor__content feed_item_html",class:{
              lineThrought: _vm.item.feed_item_type == 'task' && _vm.checkbox,
              caption: _vm.item.feed_item_type == 'task',
            },staticStyle:{"font-size":"0.9em !important"},domProps:{"innerHTML":_vm._s(_vm.item.content)}})]):_vm._e(),(_vm.item.feed_item_type == 'file')?[_c('fileItem',{attrs:{"mobileVersion":_vm.mobileVersion,"file":_vm.item},on:{"openFile":_vm.openFile}})]:_vm._e(),(_vm.item.feed_item_type == 'url')?[_c('assetUrlItem',{attrs:{"mobileVersion":_vm.mobileVersion,"url":_vm.item}})]:_vm._e()],2)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }