<template>
  <v-container justify-center class="pa-0 pb-1 pt-0">
    <draggable
      v-model="sortedFeedItems"
      @change="updateItemsOrder($event)"
      handle=".handle"
      @start="dragging = true"
      @end="dragging = false"
      v-bind="dragOptions"
    >
      <transition-group
        type="transition"
        :name="!dragging ? 'flip-list' : null"
      >
        <template v-if="!mobileVersion">
          <feedItem
            v-for="item in sortedFeedItems"
            :key="item.id"
            :item="item"
            @editItem="editItem"
            :dragging="dragging"
            @openFile="openFile"
            :mobileVersion="mobileVersion"
          />
        </template>
        <template v-if="mobileVersion">
          <feedItemMobile
            v-for="item in sortedFeedItems"
            :key="item.id"
            :item="item"
            @editItem="editItem"
            :dragging="dragging"
            @openFile="openFile"
            :mobileVersion="mobileVersion"
        /></template>
      </transition-group>
    </draggable>

    <v-dialog
      max-width="840"
      transition="dialog-top-transition"
      v-model="editItemDialog"
      persistent
      scrollable
      class="mx-0"
      :fullscreen="mobileVersion"
    >
      <feedItemEditor
        v-if="editItemDialog && !mobileVersion"
        :feed_item="itemToEdit"
        @closeEditor="closeFeedItemEditor"
        :mobileVersion="mobileVersion"
      />
      <feedItemEditorMobile
        v-if="editItemDialog && mobileVersion"
        :feed_item="itemToEdit"
        @closeEditor="closeFeedItemEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import feedItem from "@/components/feed_items/feed_item";
import feedItemMobile from "@/components/feed_items/feed_item_mobile";
import feedItemEditor from "@/components/feed_items/feed_item_editor";
import feedItemEditorMobile from "@/components/feed_items/feed_item_editor_mobile";
import draggable from "vuedraggable";
import UPDATE_FEED_ITEM from "@/graphql/feed_items/update.gql";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "feedItemsList",
  components: {
    feedItem,
    draggable,
    feedItemEditor,
    feedItemEditorMobile,
    feedItemMobile,
  },
  props: {
    topic_id: {
      type: String,
      required: true,
    },
    feed_items: {
      type: Array,
    },
    mobileVersion: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      editItemDialog: false,
      itemToEdit: null,
      dragging: false,
    };
  },
  computed: {
    ...mapGetters(["topicStoreRefresh"]),
    sortedFeedItems: {
      get() {
        this.topicStoreRefresh;
        console.log("sortedFeed items react ");
        return this.feed_items;
      },
      set(value) {
        console.log("set sortedFeed items trigger ");
        for (var i = 0; i < value.length; i++)
          value[i].position = i + this.minPosition;
        this.setTopicItems({ topic_id: this.topic_id, feed_items: value });
        console.log(value);
      },
    },
    minPosition() {
      let min = Infinity;
      this.sortedFeedItems.forEach((e) => {
        if (e.position < min) min = e.position;
      });
      return min;
    },
    dragOptions() {
      return {
        animation: 200,

        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    ...mapActions(["setTopicItems"]),
    openFile(event) {
      this.$emit("openFile", event);
    },
    updateItemsOrder({ added, removed, moved }) {
      if (!moved) return;

      this.$apollo
        .mutate({
          mutation: UPDATE_FEED_ITEM,
          variables: {
            id: moved.element.id,
            position: moved.newIndex + this.minPosition,
          },
        })
        .then(() => {})
        .catch((error) => {
          this.sendError(error);
        });
    },
    editItem(item) {
      this.editItemDialog = true;
      this.itemToEdit = item;
    },
    closeFeedItemEditor() {
      this.editItemDialog = false;
      this.itemToEdit = null;
    },
  },
};
</script>
<style  >
.ghost {
  opacity: 0.4;
}
</style>