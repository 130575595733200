<template>
  <v-container fluid style="height: 100%; overflow-y: hidden" class="py-0">
    <v-row
      wrap
      no-gutters
      style="height: 100%; max-width: 1280px"
      class="mx-auto justify-center text-xs-center"
    >
      <!-- <v-col
        :class="{ historyContainer: focusFileViewerCol }"
        cols="12"
        class="pt-0 transitionContainer shrink"
        style="height: 100%"
        v-if="!($vuetify.breakpoint.mdAndDown && showFileViewerCol)"
      > -->
      <!-- :class="{ historyContainer: focusFileViewerCol }"
        :cols="focusFileViewerCol ? 0 : 12"
        :lg="showFileViewerCol ? (focusFileViewerCol ? 0 : 6) : 8"
        :xl="showFileViewerCol ? (focusFileViewerCol ? 0 : 6) : 7" -->
      <template v-if="topic.not_visible">
        <v-container fill-height>
          <v-row wrap no-gutters style="max-width: 800px" class="mx-auto">
            <v-col cols="12" class="text-center align-center justify-center">
              <!-- <v-badge   avatar  overlap color="error" offset-x="24" offset-y="-12">
                <template v-slot:badge>
                 <v-icon size="24" color="grey lighten-1">mdi-lock-outline</v-icon>
                </template> -->
              <div class="pb-4">
                <userAvatar
                  :first_name="currentUser.first_name"
                  :last_name="currentUser.last_name"
                  :size="96"
                  :avatar_url="currentUser.avatar_url"
                  :email="currentUser.email"
                />
                 <v-icon color="amber darken-2" style="position: absolute;">mdi-lock</v-icon>
              </div>
              <h2 class="grey--text text--darken-1 pb-4">{{$t("misc.accessDenied")}}</h2>
               <p class="body-2 mb-2">{{ $t("followUps.notVisible") }}</p>
               <p class="body-2">{{ $t("followUps.askForInvitation") }}</p>
               <v-btn @click="reload()" text color="button_blue"><v-icon small left>mdi-refresh</v-icon>{{ $t('meetings.reload') }}</v-btn>
              <!-- </v-badge> -->
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-row>
          <v-col cols="12">
        <div>
          <v-icon size="14" class="pl-1" color="amber"> mdi-alert </v-icon>
          <span class="caption">{{ $t("followUps.notVisible") }}</span>
        </div>
        <div>
          <span class="caption">{{ $t("followUps.askForInvitation") }} <b>{{ topic.follow_up_name }}</b></span>
        </div>
        <div>
          <span class="caption" @click="reload">
            {{ $t('misc.then') }} <a>{{ $t('meetings.reload') }}</a>
          </span>
        </div>
          </v-col>
        </v-row> -->
      </template>
      <template v-else>
        <cardsIndex
          :followUpId="topic.follow_up_id"
          :topicId="topic.id"
          :showFileViewerCol="showFileViewerCol"
          :currentMeetingId="meetingId"
          @showFile="showFile"
        />
      </template>
      <!-- </v-col> -->
      <!-- FILE EDITOR -->
      <!-- <v-col
        cols="12"
        lg="6"
        :class="{ reducedDraftSection: !showFileViewerCol }"
        class="pt-0 transitionContainer"
        style="height: 100%"
      >
        <fileViewerColumn
          @setShowFileViewerCol="setShowFileViewerCol"
          @setFocusFileViewerCol="setFocusFileViewerCol"
          :showFileViewerCol="showFileViewerCol"
          :focusFileViewerCol="focusFileViewerCol"
          :fileToShow="fileToShow"
          :filesGroupedByCard="filesGroupedByCard"
          v-if="filesGroupedByCard.length && fileToShow"
          @close="close"

        /> -->
      <v-dialog
        v-model="showFileViewer"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        max-width="1280"
      >
        <fileViewerColumn
          :showFileViewerCol="showFileViewerCol"
          :focusFileViewerCol="focusFileViewerCol"
          :fileToShow="fileToShow"
          :filesGroupedByCard="filesGroupedByCard"
          v-if="filesGroupedByCard.length && fileToShow"
          @close="close"
        />
      </v-dialog>

      <!-- </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import cardsIndex from "@/components/cards/cards_index";
import fileViewerColumn from "../file_viewer/file_viewer_column.vue";
import { mapActions, mapGetters } from "vuex";
import userAvatar from "@/components/shared/userAvatar";

export default {
  name: "topicShow",
  components: {
    cardsIndex,
    fileViewerColumn,
    userAvatar,
  },
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFileViewerCol: false,
      focusFileViewerCol: false,
      refreshItems: 0,
      fileToShow: null,
      showFileViewer: false,
    };
  },
  computed: {
    ...mapGetters(["meetingId", "currentUser", "topicCards", "followUpCards"]),
    cards() {
      if (this.topic.follow_up_id)
        return this.followUpCards(this.topic.follow_up_id).sort(this.sortByCreatedAt);
      else return this.topicCards(this.topic.id).sort(this.sortByCreatedAt);
    },

    filesGroupedByCard() {
      let _cards = this.cards.map((t) => {
        return {
          card_id: t.id,
          card_name: t.title ? t.title : t._title,
          files: t.files,
          updated_at: t.updated_at,
        };
      });

      //filter out those who don't have any feed events inside.
      _cards = _cards.filter((t) => t.files.length > 0);

      // if (this.currentTopic && this.showOnlyCurrentTopicItems)
      //   return _cards.filter((t) => t.card_id == this.currentTopic.id);

      return _cards.sort(this.sortByCreatedAt);
    },
  },
  methods: {
    ...mapActions(["getMeeting", "setSelectedTopicId"]),
    reload() {
      this.getMeeting(this.meetingId).then(() => {
        this.setSelectedTopicId(this.topic.id);
      });
    },
    close() {
      this.fileToShow = null;
    },
    sortByCreatedAt(a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    },
    setShowFileViewerCol(val) {
      console.log(val);

      this.showFileViewerCol = val;
      if (!this.showFileViewerCol) {
        this.focusFileViewerCol = false;
      }
    },
    setFocusFileViewerCol(val) {
      console.log(val);
      this.focusFileViewerCol = !this.focusFileViewerCol;
      this.showFileViewerCol = true;
    },
    showFile(file) {
      this.fileToShow = file;
      console.log("Propagation on 3lvls working !!");
    },
  },
  watch: {
    "$vuetify.breakpoint.mdAndDown": function (newVal, oldVal) {
      if (newVal == true) {
        if (this.showFileViewerCol && !this.focusFileViewerCol) {
          this.showFileViewerCol = false;
        }
      }
    },
    fileToShow() {
      if (!this.fileToShow) this.showFileViewer = false;
      else this.showFileViewer = true;
    },
    showFileViewer() {
      if (!this.showFileViewer) this.fileToShow = null;
    },
  },
};
</script>

<style>
.reducedDraftSection {
  position: absolute;
  bottom: 8px;
  right: 0px;
  height: 48px !important;
  max-width: 600px;
  width: 600px;
}

.historyContainer {
  max-width: 0px !important;
}

.transitionContainer {
  transition: all 300ms ease-in-out, height 0s;
}
</style>

<style lang="scss">
p.is-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: lightgray;
  pointer-events: none;
  height: 0;
}
</style>