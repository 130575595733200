<template>
  <v-card flat color="white" height="100%">
    <v-toolbar dense flat color="transparent">
      <v-toolbar-title class="subtitle-1 almost_black--text"
        >

        <v-icon style="top: -1px"  size="20" color="almost_black"
          >mdi-playlist-edit</v-icon
        >

        {{ $t("textEditor.draft") }}</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        dark
        color="white"
        small
        depressed
        icon
        class="item_note--text mr-2"
        rounded
        @click="addDraftItem('note', draftItems.length)"
      >
        <v-icon small> mdi-note-plus </v-icon>
      </v-btn>
      <v-btn
        dark
        color="white"
        small
        depressed
        icon
        class="item_decision--text mr-2"
        rounded
        @click="addDraftItem('decision', draftItems.length)"
      >
        <v-icon small> mdi-account-voice </v-icon>
      </v-btn>
      <v-btn
        dark
        color="white"
        small
        depressed
        icon
        class="item_task--text mr-2"
        rounded
        @click="addDraftItem('task', draftItems.length)"
      >
        <v-icon small> mdi-checkbox-marked-outline </v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <div
      class="pa-8"
      v-if="draftItems.length == 0"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100% - 96px);
      "
    >
      <v-img
        height="100%"
        max-height="200"
        src="/images/addEvents.jpg"
        style="opacity: 0.8"
      />

      <div class="text-center pt-6" style="width: 100%">
        <span class="dark_grey--text">{{ $t("textEditor.draftEmpty") }}</span>
        <br />
        <span class="caption">{{ $t("textEditor.startByAdding") }}</span>

        <v-container fluid class="pt-8">
          <v-row wrap no-gutters>
            <v-col class="text-center">
              <v-btn
                dark
                color="white"
                small
                depressed
                outlined
                class="item_note--text mr-2"
                rounded
                @click="addDraftItem('note', draftItems.length)"
              >
                <v-icon small class="pr-2"> mdi-note-plus </v-icon>
                {{ $t("feedItems.newNote") }}
              </v-btn></v-col
            >
          </v-row>

          <v-row wrap no-gutters>
            <v-col class="text-center">
              <v-btn
                dark
                color="white"
                small
                depressed
                outlined
                class="item_decision--text mr-2"
                rounded
                @click="addDraftItem('decision', draftItems.length)"
              >
                <v-icon small class="pr-2"> mdi-account-voice </v-icon>
                {{ $t("feedItems.newDecision") }}
              </v-btn></v-col
            ></v-row
          >

          <v-row wrap no-gutters>
            <v-col class="text-center">
              <v-btn
                dark
                color="white"
                small
                depressed
                outlined
                class="item_task--text mr-2"
                rounded
                @click="addDraftItem('task', draftItems.length)"
              >
                <v-icon small class="pr-2">
                  mdi-checkbox-marked-outline
                </v-icon>
                {{ $t("feedItems.newTask") }}
              </v-btn></v-col
            >
          </v-row>
        </v-container>
      </div>
    </div>

    <v-card
      v-else
      flat
      class="pt-4 pb-16"
      :style="'overflow-y: auto; height: calc(100% - '+(focusedEditor != null ? '148' : '104') +'px);'"
    >
      <!-- Draft Items -->
      <draggable
        v-model="draftItems"
        handle=".handle"
        @start="startDrag()"
        @end="endDrag()"
        v-bind="dragOptions"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <template v-for="(draftItem, draftItemIndex) in draftItems">
            <v-hover v-slot="{ hover }" :key="'draftItem_' + draftItemIndex">
              <div style="display: flex; min-height: 56px" class="px-0 py-1">
                <div style="width: 28px; flex-shrink: 0" class="">
                  <!-- Drag Handle -->
                  <v-btn
                    small
                    icon
                    class="handle"
                    color="mid_grey darken-1"
                    v-show="hover && !drag"
                  >
                    <v-icon>mdi-drag-horizontal</v-icon></v-btn
                  >

                  <!-- Add item below menu -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        color="mid_grey darken-1"
                        v-bind="attrs"
                        v-on="on"
                        v-show="hover && !drag"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="itemToAdd in itemsToAddBelow"
                        :key="'addBelow_' + itemsData[itemToAdd].type"
                        @click="
                          addDraftItem(
                            itemsData[itemToAdd].type,
                            draftItemIndex + 1
                          )
                        "
                      >
                        <v-list-item-icon class="mr-1">
                          <v-icon
                            :color="itemsData[itemToAdd].iconColor"
                            small
                            >{{ itemsData[itemToAdd].icon }}</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemsData[itemToAdd].textClass"
                            >{{
                              itemsData[itemToAdd].textAddBelow
                            }}</v-list-item-title
                          ></v-list-item-content
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div style="flex: 1" class="">
                  <v-row no-gutters wrap class="ma-0">
                    <v-col cols="12">
                      <v-row wrap no-gutters>
                        <div
                          :class="
                            itemsData[draftItem.feed_item_type].borderClass +
                            ' itemChip'
                          "
                        ></div>
                        <v-chip
                          v-if="!draftItem.error_message"
                          :color="
                            itemsData[draftItem.feed_item_type].chipBackground
                          "
                          :class="
                            itemsData[draftItem.feed_item_type].iconColor +
                            '--text ml-2 mr-0 px-2'
                          "
                          small
                          label
                          @click="toggleTypeChip(draftItem)"
                        >
                          {{ itemsData[draftItem.feed_item_type].name }}
                        </v-chip>

                        <template v-if="draftItem.error_message">
                          <v-chip class="ml-2" small color="error" label
                            ><v-icon small class="pr-2">mdi-alert</v-icon>
                            {{ draftItem.error_message }}</v-chip
                          >
                        </template>

                        <v-spacer></v-spacer>
                        <template v-if="draftItem.users.length">
                          <userAvatarGroup
                            :limit="2"
                            :users="draftItem.users"
                            :size="20"
                            :disabledMenu="true"
                          />
                        </template>

                        <!-- Assign task -->
                        <v-menu
                          offset-y
                          :close-on-content-click="false"
                          :nudge-width="200"
                          max-height="400px"
                        >
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  small
                                  icon
                                  v-show="draftItem.feed_item_type == 'task'"
                                  rounded
                                  style="top: -2px"
                                  color="mid_grey darken-1"
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon small>mdi-account-plus</v-icon>
                                </v-btn>
                              </template>

                              <span> {{ $t("textEditor.assignTask") }}</span>
                            </v-tooltip>
                          </template>
                          <v-card flat>
                            <v-subheader
                              >{{ $t("textEditor.assignTask") }}:</v-subheader
                            >

                            <searchAndSelectUsers
                              :selectedUsersIds="
                                draftItem.users.map((u) => u.id)
                              "
                              :selectedMessage="$t('textEditor.assignedToTask')"
                              @onUserSelected="
                                addUserToDraftItem(draftItem, ...arguments)
                              "
                              @onUserUnselected="
                                removeUserFromDraftItem(draftItem, ...arguments)
                              "
                            />
                            <v-list dense two-lines class="py-0">
                              <userItem
                                v-for="taskUser in draftItem.users"
                                :key="taskUser.id"
                                :user="taskUser"
                              >
                                <template v-slot:action>
                                  <v-btn
                                    icon
                                    @click="
                                      removeUserFromDraftItem(
                                        draftItem,
                                        taskUser
                                      )
                                    "
                                  >
                                    <v-icon color="error" small
                                      >mdi-account-minus</v-icon
                                    >
                                  </v-btn>
                                </template>
                              </userItem></v-list
                            >
                          </v-card>
                        </v-menu>

                        <!-- Due Date -->
                        <v-menu
                          v-model="draftItem.showTaskDueDateMenu"
                          offset-y
                          ref="menu"
                          :close-on-content-click="false"
                          :return-value.sync="draftItem.due_date"
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  small
                                  :icon="!draftItem.due_date"
                                  rounded
                                  depressed
                                  text
                                  v-show="draftItem.feed_item_type == 'task'"
                                  style="top: -2px"
                                  color="mid_grey darken-1"
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon
                                    small
                                    :class="{
                                      'pr-1': draftItem.due_date,
                                    }"
                                    >mdi-calendar-check</v-icon
                                  >
                                  {{
                                    draftItem.due_date
                                      ? friendlyDate(draftItem.due_date)
                                      : ""
                                  }}
                                </v-btn>
                              </template>

                              <span> {{ $t("textEditor.dueDate") }}</span>
                            </v-tooltip>
                          </template>
                          <v-date-picker
                            v-model="draftItem.due_date"
                            no-title
                            scrollable
                            :min="todaysDate"
                            @change="
                                $refs.menu[draftItemIndex].save(
                                  draftItem.due_date
                                )
                              "
                          >
                            <!-- <v-spacer></v-spacer>
                            <v-btn
                              text
                              small
                              @click="draftItem.showTaskDueDateMenu = false"
                            >
                              {{ $t("misc.cancel") }}
                            </v-btn>
                            <v-btn
                              small
                              depressed
                              class="white--text"
                              color="button_blue"
                              @click="
                                $refs.menu[draftItemIndex].save(
                                  draftItem.due_date
                                )
                              "
                            >
                              {{ $t("misc.ok") }}
                            </v-btn> -->
                          </v-date-picker>
                        </v-menu>
                      </v-row>
                    </v-col>

                    <!-- <span v-if="draftItem.error_message" style="color: red;lol">
                      {{ draftItem.error_message }} , a toi de jouer qual -_-'
                    </span> -->
                  </v-row>
                  <v-row
                    wrap
                    no-gutters
                    class="my-2 mx-3"
                    style="word-break: break-word"
                  >
                    <div
                      v-if="draftItem.feed_item_type == 'task'"
                      style="width: 32px; flex-shrink: 0"
                      class=""
                    >
                      <v-simple-checkbox
                        v-model="draftItem.done"
                        color="item_task"
                        class="shrink py-0 ma-0 pr-3"
                        style="display: inline-block"
                      ></v-simple-checkbox>
                    </div>
                    <div style="flex: 1;" class="pt-1">
                      <editor-content
                        class="editor__content"
                        style=" font-size: 0.8em"
                        :class="{
                          lineThrought: draftItem.done,
                          caption: draftItem.feed_item_type == 'task',
                        }"
                        :editor="draftItem.editor"
                        :key="'editor_' + draftItem.uuid"
                      >
                      </editor-content>
                    </div>
                  </v-row>
                </div>
                <div style="width: 26px; flex-shrink: 0">
                  <!-- delete and publish menu -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        icon
                        color="mid_grey darken-1"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="publishItem(draftItemIndex)">
                        <v-list-item-icon class="mr-1">
                          <v-icon small>mdi-share-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >{{
                              itemsData[draftItem.feed_item_type].publishItem
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="deleteItem(draftItemIndex)">
                        <v-list-item-icon class="mr-1">
                          <v-icon color="error" small>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="error--text"
                            >{{ $t("misc.delete") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </v-hover>
          </template>
        </transition-group>
      </draggable>
    </v-card>

    <editor-menu-bar
      class="my-2"
      :editor="focusedEditor"
      v-slot="{ commands, isActive }"
    >
      <div>
        <textEditorToolbarButton
          mobileVersion
          :tooltip="$t('textEditor.bold')"
          :active="isActive.bold()"
          icon="mdi-format-bold"
          @btnClic="commands.bold"
        />

        <textEditorToolbarButton
          mobileVersion
          :tooltip="$t('textEditor.italic')"
          :active="isActive.italic()"
          icon="mdi-format-italic"
          @btnClic="commands.italic"
        />

        <textEditorToolbarButton
          mobileVersion
          :tooltip="$t('textEditor.underline')"
          :active="isActive.underline()"
          icon="mdi-format-underline"
          @btnClic="commands.underline"
        />

        <template
          v-if="
            focusedEditor.options.itemType == 'note' ||
            focusedEditor.options.itemType == 'decision'
          "
        >
          <textEditorToolbarButton
            mobileVersion
            :tooltip="$t('textEditor.bulletedList')"
            :active="isActive.bullet_list()"
            icon="mdi-format-list-bulleted"
            @btnClic="commands.bullet_list"
          />
          <textEditorToolbarButton
            mobileVersion
            :tooltip="$t('textEditor.numberedList')"
            :active="isActive.ordered_list()"
            icon="mdi-format-list-numbered"
            @btnClic="commands.ordered_list"
          />
          <textEditorToolbarButton
            mobileVersion
            :tooltip="$t('textEditor.title')"
            :active="isActive.heading({ level: 3 })"
            icon="mdi-format-title"
            @btnClic="commands.heading({ level: 3 })"
          />

          <textEditorToolbarButton
            mobileVersion
            :tooltip="$t('textEditor.highlight')"
            :active="isActive.highlight()"
            icon="mdi-format-color-highlight"
            color="#ffee58"
            @btnClic="commands.highlight({ color: '#ffee58' })"
          />

          <!-- Text color if needed -->
          <textEditorToolbarButton
            mobileVersion
            :tooltip="$t('textEditor.table')"
            :active="false"
            icon="mdi-table"
            @btnClic="
              commands.createTable({
                rowsCount: 3,
                colsCount: 3,
                withHeaderRow: false,
              })
            "
          />

          <v-card
            v-if="isActive.table()"
            flat
            style="
              position: fixed;
              transform: translate(0px, -70px);
              width: 300px;
            "
          >
            <v-card-text class="px-0 py-2 text-center">
              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.deleteTable')"
                :active="false"
                icon="mdi-table-remove"
                @btnClic="commands.deleteTable"
              />

              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.addColumnBefore')"
                :active="false"
                icon="mdi-table-column-plus-before"
                @btnClic="commands.addColumnBefore"
              />

              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.addColumnAfter')"
                :active="false"
                icon="mdi-table-column-plus-after"
                @btnClic="commands.addColumnAfter"
              />

              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.deleteColumn')"
                :active="false"
                icon="mdi-table-column-remove"
                @btnClic="commands.deleteColumn"
              />

              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.addRowBefore')"
                :active="false"
                icon="mdi-table-row-plus-before"
                @btnClic="commands.addRowBefore"
              />

              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.addRowAfter')"
                :active="false"
                icon="mdi-table-row-plus-after"
                @btnClic="commands.addRowAfter"
              />

              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.deleteRow')"
                :active="false"
                icon="mdi-table-row-remove"
                @btnClic="commands.deleteRow"
              />

              <textEditorToolbarButton
                mobileVersion
                :tooltip="$t('textEditor.toggleCellMerge')"
                :active="false"
                icon="mdi-table-merge-cells"
                @btnClic="commands.toggleCellMerge"
              />
            </v-card-text>
          </v-card>
        </template>
      </div>
    </editor-menu-bar>
    <v-divider></v-divider>
    <v-toolbar
      class="pt-0"

      dense
      flat
      color="transparent"
    >

      <v-btn

        small
        depressed
        rounded
       text
        color="almost_black"
        @click="$emit('close')"

      >
        <v-icon small class="pr-2">mdi-arrow-left</v-icon>
        {{ $t("misc.back") }}
      </v-btn>


      <v-spacer></v-spacer>
      <v-btn
        v-if="draftItems.length"
        small
        depressed
        rounded
        class="white--text"
        color="button_blue"
        @click="publishAll()"
        :disabled="loadingPublish"
      >
        <v-icon small class="pr-2">mdi-share-all</v-icon>
        {{ $t("textEditor.publishAll") }}
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
// Custom Js plugins
// import editorHighlighting from "../../plugins/editorHighlighting.js";
// import editorTextColor from "../../plugins/editorTextColor.js";
import searchAndSelectUsers from "./../users/searchAndSelectUsers";
import textEditorToolbarButton from "../shared/textEditorToolbarButton";
import userItem from "@/components/users/userItem";
import userAvatarGroup from "@/components/shared/userAvatarGroup";
import { Editor, EditorContent } from "@tiptap/vue-2";
// import {
//   Placeholder,
//   Blockquote,
//   CodeBlock,
//   HardBreak,
//   Heading,
//   HorizontalRule,
//   OrderedList,
//   BulletList,
//   ListItem,
//   TodoItem,
//   TodoList,
//   Bold,
//   Code,
//   Italic,
//   Link,
//   Strike,
//   Underline,
//   History,
//   Table,
//   TableHeader,
//   TableCell,
//   TableRow,
// } from "tiptap-extensions";

import date from "@/mixins/date";
const uuidv4 = require("uuid/v4");
import BULK_CREATE_FEED_ITEMS from "@/graphql/feed_items/bulk_create.gql";
import CREATE_FEED_ITEM from "@/graphql/feed_items/create.gql";
import { mapGetters } from "vuex";

export default {
  name: "feed_items_draft",
  mixins: [date],
  components: {
    draggable,
    EditorContent,
    textEditorToolbarButton,
    searchAndSelectUsers,
    userItem,
    userAvatarGroup,
  },
  sockets: {
    updateServerInfo(server) {},
  },
  props: {
    displayDraftSection: {
      type: Boolean,
      required: false,
    },
    displayOnlyDraft: {
      type: Boolean,
      required: false,
    },
    newFeedItemType: {
      type: String,
      default: "note"
    }
  },
  data() {
    return {
      loadingPublish: false,
      todaysDate: new Date().toISOString(),
      focusedEditor: null,
      drag: false,
      draftItems: [],
      failedItems: [],
      itemsToAddBelow: ["note", "decision", "task"],
      itemsData: {
        note: {
          type: "note",
          icon: "mdi-note-plus",
          iconColor: "item_note",
          textClass: "item_note--text",
          textAddBelow: this.$t("textEditor.addNoteBelow"),
          name: this.$t("feedItems.note"),
          borderClass: "itemNote",
          chipBackground: "item_note_background",
          writeYourNote: this.$t("textEditor.writeYourNoteHere"),
          publishItem: this.$t("textEditor.publishNote"),
        },
        decision: {
          type: "decision",
          icon: "mdi-account-voice",
          iconColor: "item_decision",
          textClass: "item_decision--text",
          textAddBelow: this.$t("textEditor.addDecisionBelow"),
          name: this.$t("feedItems.decision"),
          borderClass: "itemDecision",
          chipBackground: "item_decision_background",
          writeYourNote: this.$t("textEditor.writeYourDecisionHere"),
          publishItem: this.$t("textEditor.publishDecision"),
        },
        task: {
          type: "task",
          icon: "mdi-checkbox-marked-outline",
          iconColor: "item_task",
          textClass: "item_task--text",
          textAddBelow: this.$t("textEditor.addTaskBelow"),
          name: this.$t("feedItems.task"),
          borderClass: "itemTask",
          chipBackground: "item_task_background",
          writeYourNote: this.$t("textEditor.writeYourTaskHere"),
          publishItem: this.$t("textEditor.publishTask"),
        },
      },
      aftiveDraftItemIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["selectedTopic"]),
    failedItemIds() {
      return this.failedItems.map((e) => e.front_id);
    },
    displayLoadHistory() {
      return true;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    addUserToDraftItem(draftItem, user) {
      draftItem.users.push(user);
    },
    removeUserFromDraftItem(draftItem, user) {
      var removeIndex = draftItem.users.map((u) => u.id).indexOf(user.id);
      draftItem.users.splice(removeIndex, 1);
    },
    setEditorsEditable(editable) {
      this.draftItems.forEach((di) => {
        di.editor.setOptions({ editable: editable });
      });
    },
    startDrag() {
      this.drag = true;
      // Prevent dragging content on editors
      this.setEditorsEditable(false);

      //Close all menus opened
      this.draftItems.forEach((di) => {
        di.showTaskDueDateMenu = false;
      });
    },
    endDrag() {
      this.drag = false;
      this.setEditorsEditable(true);
    },
    toggleTypeChip(draftItem) {
      if (draftItem.feed_item_type == "decision")
        draftItem.feed_item_type = "note";
      else if (draftItem.feed_item_type == "note")
        draftItem.feed_item_type = "decision";
    },
    publishItem(index) {
      console.log(this.draftItems[index].editor.getHTML());
      if (this.loadingPublish) return;

      this.loadingPublish = true;
      let di = this.draftItems[index];
      let variables = this.itemFromDraftItem(di);
      console.log("variables: ", variables);
      this.$apollo
        .mutate({
          mutation: CREATE_FEED_ITEM,
          variables: this.itemFromDraftItem(di),
        })
        .then(({ data }) => {
          this.loadingPublish = false;
          this.deleteItem(index);
        })
        .catch((error) => {
          this.draftItems[index].error_message = error.graphQLErrors
            .map((e) => e.message)
            .join(", ");
          this.loadingPublish = false;
          console.log(error);
        });
    },
    publishAll() {
      if (this.loadingPublish) return;

      let items = [];
      this.draftItems.forEach((di) => {
        items.push(this.itemFromDraftItem(di));
      });

      this.loadingPublish = true;
      console.log(items);
      this.$apollo
        .mutate({
          mutation: BULK_CREATE_FEED_ITEMS,
          variables: { feed_items: items },
        })
        .then(({ data }) => {
          this.loadingPublish = false;
          this.failedItems = data.bulk_create_feed_item.errors.map((e) =>
            JSON.parse(e)
          );
          this.deleteSuccessSetFailures();
          if (this.failedItems.length == 0) this.$emit("close");
        })
        .catch((error) => {
          this.loadingPublish = false;
          this.sendError(error);
          console.log(error);
        });
    },
    itemFromDraftItem(di) {
      let ret = {
        feed_item_type: di.feed_item_type,
        done: di.done,
        due_date: new Date(di.due_date).getTime(),
        content: di.editor.getHTML(),
        topic_id: this.selectedTopic.id,
        front_id: di.uuid,
        user_ids: di.users.map((e) => e.id),
      };

      // if (di.feed_item_type == "task") {
      //   ret.user_ids = di.users.map(u => u.id);
      // }

      return ret;
    },
    deleteSuccessSetFailures() {
      for (let i = 0; i < this.draftItems.length; i++) {
        if (!this.failedItemIds.includes(this.draftItems[i].uuid)) {
          // Success
          this.draftItems[i].editor.destroy();
        } else {
          // Error
          let item = this.failedItems.find(
            (e) => e.front_id == this.draftItems[i].uuid
          );
          this.draftItems[i].error_message = item ? item.error : null;
        }
      }
      this.draftItems = this.draftItems.filter((e) =>
        this.failedItemIds.includes(e.uuid)
      );
      this.focusedEditor = null;
    },
    deleteItem(index) {
      // DONT FORGET TO DESTROY EDITOR ON ITEM DESTROY
      this.draftItems[index].editor.destroy();
      this.draftItems.splice(index, 1);
      this.focusedEditor = null;
    },
    addDraftItem(type, position_index) {
      if (!this.displayDraftSection) this.$emit("setDisplayDraftSection", true);
      this.draftItems.splice(
        position_index,
        0,
        this.returnEmptyDraftItem(type)
      );
    },
    editorExtensionsPresets(type) {
      var extensions = [
        new Placeholder({
          emptyEditorClass: "is-editor-empty",
          emptyNodeClass: "is-empty",
          emptyNodeText: this.itemsData[type].writeYourNote,
          showOnlyWhenEditable: false,
          showOnlyCurrent: false,
        }),
        new Bold(),
        new Italic(),
        new Underline(),
      ];

      var additionnalExtensions = [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Code(),
        new Strike(),
        new History(),
        new editorHighlighting(),
        new editorTextColor(),
        new Table({
          resizable: true,
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
      ];
      if (type == "task") return extensions;
      else return extensions.concat(additionnalExtensions);
    },
    returnEmptyDraftItem(type) {
      var t_id = uuidv4();
      var obj = {
        feed_item_type: type,
        content: "",
        done: false,
        due_date: null,
        uuid: t_id,
        users: [],
        editor: new Editor({
          itemType: type,
          autoFocus: true,
          onFocus: () => {
            this.focusedEditor = this.draftItems.find(
              (d) => d.uuid == t_id
            ).editor;
          },
          extensions: this.editorExtensionsPresets(type),
        }),
      };

      return obj;
    },
    toggleDisplayDraftSection() {
      this.$emit("setDisplayDraftSection", !this.displayDraftSection);
    },
    toggleDisplayOnlyDraft() {
      this.$emit("setDisplayOnlyDraft", !this.displayDraftSection);
    },
  },
  created() {
    // console.log("mobile pad", this.newFeedItemType)
    // if (this.newFeedItemType == "note") {
    //   this.addDraftItem("note", this.draftItems.length);
    // }

    // if (this.newFeedItemType == "task") {
    //   this.addDraftItem("task", this.draftItems.length);
    // }

    // if (this.newFeedItemType == "decision") {
    //   this.addDraftItem("decision", this.draftItems.length);
    // }
  },
  beforeDestroy() {
    this.draftItems.forEach((d) => {
      d.editor.destroy();
    });
  },
};
</script>

<style>
.ghost {
  opacity: 0.4;
  background-color: #e3eefb;
}

.v-card--link:before {
  background: none;
}

.v-card--link {
  cursor: auto;
}

.flip-list {
  transition: transform 0.3s;
}
</style>

<style lang="scss">
p.is-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: lightgray;
  pointer-events: none;
  height: 0;
}
</style>
