<template>
  <div style="width: 100%; height: 100%">
    <div class="timelineGridWrapper">
      <!-- PLAY - RESTART -->
      <div class="text-center d-flex align-center justify-center">
        <!-- PLAY -->
        <!-- <template v-if="meeting.status == 'pending' && meeting.topics.length">
          <v-tooltip bottom color="timeline_blue">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                icon
                v-bind="attrs"
                v-on="on"
                @click.prevent="startMeeting()"
              >
                <v-icon size="28">mdi-play</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("meetings.startNow") }}</span>
          </v-tooltip>
        </template> -->

        <!-- RESTART -->
        <!-- <template v-if="meeting.status != 'pending' && meeting.topics.length">
          <v-tooltip bottom color="timeline_blue">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="white"
                v-bind="attrs"
                v-on="on"
                @click="restartMeeting()"
              >
                <v-icon size="28">mdi-rotate-left</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("meetings.restartMeeting") }}</span>
          </v-tooltip>
        </template> -->
      </div>

      <!-- TIMELINE -->
      <div
        v-dragscroll="!dragTopic"
        class="timelineItemsWrapper"
        id="timelineItemsWrapper"
        v-resize="onTimelineItemsWrapperResize"
      >
        <v-row
          wrap
          no-gutters
          style="flex-wrap: nowrap"
          id="timelineItemsWrapperChild"
        >
          <draggable
            v-model="topics"
            @start="dragTopic = true"
            @end="dragTopic = false"
            class="row pa-0 ma-0 wrap no-gutters timelineTopic draggable"
            group="timelineTopics"
            v-bind="dragOptions"
            style="display: contents"
            handle=".handle"
            id="timelineItemsDraggable"
            @change="updateTopicsOrder($event)"
          >
            <transition-group
              class="
                row
                pa-0
                ma-0
                wrap
                no-gutters
                timelineTopic
                transitionGroup
              "
              type="transition"
              style="display: contents"
              :name="!dragTopic ? 'flip-list' : null"
            >
              <!-- topic wrapper -->
              <v-col
                v-for="(topic, topicIndex) in topics"
                :key="topic.id"
                class="timelineTopic"
                :style="'max-width: ' + timelineTopicWidth(topic) + '%'"
              >
                <!-- Topic bubble -->
                <v-row wrap no-gutters dense style="">
                  <v-list
                    shaped
                    rounded
                    dense
                    style="
                      border-radius: 19px !important;
                      width: min-content;
                      max-width: 90%;
                    "
                    :class="{ topicDone: topicIndex < currentTopicIndex }"
                    class="pa-0"
                    :color="timelineTopicColor(topic.id, topicIndex)"
                    dark
                  >
                    <!--  :light="timelineTopicLightMode(topic.id, topicIndex)" -->
                    <v-hover :disabled="dragTopic" v-slot="{ hover }">
                      <template>
                        <v-list-item
                          class="py-0 topicBubble"
                          shaped
                          :class="{
                            currentTopic: currentTopicIndex == topicIndex,
                          }"
                          @click="setSelectedTopicId(topic.id)"
                          @contextmenu.prevent="openTopicDialog(topic)"
                        >
                          <div style="position: relative; left: -8px">
                            <div v-show="!hover">
                              <userAvatar
                                v-if="topic.user"
                                :size="24"
                                :show_name_in_tooltip="false"
                                :first_name="topic.user.first_name"
                                :last_name="topic.user.last_name"
                                :avatar_url="topic.user.avatar_url"
                                :email="topic.user.email"
                              />
                              <div v-else>
                                <v-icon style="position: relative; left: 3px">
                                  mdi-account-multiple
                                </v-icon>
                              </div>
                            </div>
                            <!-- -->
                            <v-btn
                              width="24"
                              height="24"
                              small
                              icon
                              class="handle"
                              style="cursor: grab"
                              color="mid_grey darken-1"
                              @mousedown="dragTopic = true"
                              @mouseup="dragTopic = false"
                              v-show="hover"
                            >
                              <v-icon>mdi-drag-horizontal</v-icon></v-btn
                            >
                          </div>

                          <v-list-item-content class="pl-1 py-0">
                            <v-list-item-title class="mb-0">
                              <v-tooltip top color="timeline_blue">
                                <template v-slot:activator="{ on }">
                                  <span v-on="on">
                                    <!-- <customIcon
                                      v-show="topic.follow_up_id"
                                      color="white"
                                      name="followup"
                                      height="12"
                                      style="position: relative; top: 2px"
                                      ><iconFollowup /></customIcon
                                    > -->
                                    {{
                                      topic.follow_up_id
                                        ? topicName(topic)
                                        : topic.subject
                                    }}</span
                                  >
                                </template>

                                <span>{{
                                  topic.follow_up_id
                                    ? topicName(topic)
                                    : topic.subject
                                }}</span>
                              </v-tooltip>

                              <v-tooltip
                                top
                                color="timeline_blue"
                                v-if="topic.not_visible"
                              >
                                <template v-slot:activator="{ on }">
                                  <span v-on="on">
                                    <v-icon
                                      size="14"
                                      class="pl-1"
                                      color="amber"
                                    >
                                      mdi-alert
                                    </v-icon>
                                  </span>
                                </template>
                                <span>{{ $t("followUps.notVisible") }}</span>
                              </v-tooltip>
                              <!-- {{ topic.position }} -->
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class=""
                              style="font-size: 11px"
                            >

                              {{ topicSpeaker(topic) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="my-0">
                            <!-- <v-chip
                              rounded
                              small
                              style="position: relative; left: 8px"
                              color="rgba(0,0,0,0.2)"
                              class="caption font-weight-bold white--text"
                              v-show="topicIndex === currentTopicIndex"
                              @click.stop="
                                isLastTopic ? endMeeting() : playNextTopic()
                              "
                            >
                              {{
                                isLastTopic ? $t("misc.end") : $t("misc.next")
                              }}
                              <v-icon
                                small
                                class="pl-1"
                                style="padding-bottom: 1px"
                                >mdi-chevron-double-right</v-icon
                              >
                            </v-chip> -->
                            <v-btn
                              small
                              icon
                              style="cursor: pointer; width: auto"
                              color="white"
                              @click.stop="openTopicDialog(topic)"
                              v-show="hover"
                            >
                              <v-icon small class="ma-0">mdi-pencil</v-icon>
                            </v-btn>

                            <!-- <template v-if="isMeetingStopped == false">
                             <v-tooltip color="white" right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="ma-0 pa-0"
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    small
                                    height="24"
                                    v-show="topicIndex == currentTopicIndex"
                                    @click.stop="
                                      isLastTopic
                                        ? endMeeting()
                                        : playNextTopic()
                                    "
                                  >
                                    <v-icon size="22">mdi-check</v-icon>
                                  </v-btn>
                                </template>
                                <span class="almost_black--text">{{
                                  $t("meetings.setTopicAsDone")
                                }}</span>
                              </v-tooltip>

                              <v-btn
                                class="ma-0 pa-0"
                                style="top: 8px"
                                @click.stop=""
                                icon
                                small
                                height="24"
                                v-if="
                                  topicIndex != currentTopicIndex &&
                                  !timelineTopicLightMode(topic.id, topicIndex)
                                "
                                :color="
                                  timelineTopicLightMode(topic.id, topicIndex)
                                    ? 'almost_black'
                                    : 'success'
                                "
                              >
                                <v-icon size="22">mdi-check</v-icon>
                              </v-btn>

                            </template> -->
                            <!-- <span  v-if="topicIndex === currentTopicIndex" class="white--text font-weight-bold caption">Current Topic</span> -->
                          </v-list-item-action>
                        </v-list-item>
                        <!-- Go to next topic -->
                      </template>
                    </v-hover>
                  </v-list>
                </v-row>

                <!-- WIP -->
                <!-- <v-progress-linear
                  value="100"
                  class="timeline-progress"
                  color="timeline_lighter_blue"
                /> -->
                <v-progress-linear
                  height="3"
                  v-if="
                    meeting.status === 'finished' ||
                    meeting.status === 'archived' ||
                    meeting.status === 'canceled'
                  "
                  :value="meeting.status == 'canceled' ? 0 : 100"
                  class="timeline-progress"
                  color="button_blue"
                />
                <v-progress-linear
                  height="3"
                  v-else
                  :value="topicProgressBarValue(topic, topicIndex)"
                  class="timeline-progress"
                  :color="topicProgressBarColor(topic, topicIndex)"
                  background-color="timeline_lighter_blue darken-1"
                  :stream="
                    topicIndex > currentTopicIndex &&
                    topicProgressBarValue(topic, topicIndex) != 0
                  "
                  :buffer-value="
                    topicIndex > currentTopicIndex &&
                    topicProgressBarValue(topic, topicIndex) != 0
                      ? 0
                      : 100
                  "
                  :indeterminate="
                    !meeting.timed_topics &&
                    topicIndex == currentTopicIndex &&
                    meeting.status == 'started'
                  "
                />

                <!-- MEETING IS PENDING or CANCELED-->
                <!-- <v-progress-linear
                  v-if="
                    meeting.status === 'pending' || meeting.status === 'cancel'
                  "
                  value="100"
                  class="timeline-progress"
                  color="timeline_lighter_blue"
                /> -->

                <!-- MEETING IS FINISHED -->
                <!-- <v-progress-linear
                  v-if="meeting.status === 'finished'"
                  value="100"
                  class="timeline-progress"
                  color="button_blue"
                /> -->

                <!-- MEETING IS STARTED OR PAUSED -->
                <!-- PREVIOUS TOPIC -->
                <!-- <v-progress-linear
                  v-if="
                    (meeting.status === 'started' ||
                      meeting.status === 'paused') &&
                    currentTopicIndex > topicIndex
                  "
                  value="100"
                  class="timeline-progress"
                  :color="
                    meeting.status === 'paused'
                      ? 'timeline_lighter_blue'
                      : 'button_blue'
                  "
                /> -->
                <!-- CURRENT TOPIC -->
                <!-- <v-progress-linear
                  v-if="
                    (meeting.status === 'started' ||
                      meeting.status === 'paused') &&
                    currentTopicIndex === topicIndex
                  "
                  v-model="percentageDone"
                  class="timeline-progress"
                  :indeterminate="meeting.status === 'paused'"
                  :color="
                    meeting.status === 'paused'
                      ? 'timeline_lighter_blue'
                      : 'button_blue'
                  "
                /> -->

                <!-- NEXT TOPICS -->
                <!-- <v-progress-linear
                  v-if="
                    (meeting.status === 'started' ||
                      meeting.status === 'paused') &&
                    currentTopicIndex < topicIndex
                  "
                  :indeterminate="meeting.status === 'paused'"
                  value="0"
                  class="timeline-progress"
                  :color="
                    isMeetingStopped ? 'timeline_lighter_blue' : 'button_blue'
                  "
                /> -->

                <div style="position: relative">
                  <!-- Dot start -->
                  <div class="dotStart"></div>
                  <!-- Dot End -->
                  <div
                    v-if="topicIndex == meeting.topics.length - 1"
                    class="dotEnd"
                  ></div>

                  <!-- Topic Start Time -->
                  <span
                    class="
                      animateOpacity
                      topicStartTime
                      caption
                      font-weight-bold
                      white--text
                    "
                    :class="{
                      blinkElement:
                        topicProgressBarValue(topic, topicIndex) != 0 &&
                        topicIndex == currentTopicIndex + 1,
                      invisibleElement:
                        topicIndex != 0 &&
                        meeting.topics.length - 1 &&
                        !timelineHovered,
                    }"
                    >{{
                      meeting.timed_topics || topicIndex == 0
                        ? topicStartAt(topic, topicIndex)
                        : ""
                    }}
                  </span>

                  <!-- Meeting end time (using topics durations sum) -->
                  <!-- Displayed only on the last topic -->
                  <template v-if="topicIndex == meeting.topics.length - 1">
                    <v-menu
                      open-on-hover
                      bottom
                      offset-x
                      offset-y
                      :disabled="!meetingEndConflict()"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- MEETING END TIME AND ALERTE -->
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="
                            meetingCalculatedEndtime
                            caption
                            font-weight-bold
                            white--text
                          "
                          >{{ meetingEndTime() }}
                          <v-icon
                            size="14"
                            class="pl-1"
                            color="amber"
                            v-if="meetingEndConflict()"
                          >
                            mdi-alert
                          </v-icon>
                        </span>
                      </template>
                      <!-- MENU CONFLICT -->
                      <v-card width="560">
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon small class="pr-2" color="amber">
                                  mdi-alert
                                </v-icon>
                                {{
                                  $t("meetings.meetingEndTimeConflict")
                                }}</v-list-item-title
                              >
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                color="button_blue"
                                rounded
                                depressed
                                small
                                class="white--text"
                                @click="openMeetingAgenda()"
                              >
                                {{ $t("meetings.meetingAgenda") }}
                                <v-icon class="pl-2" small color="white">
                                  mdi-clipboard-list-outline
                                </v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>

                        <v-card-text class="pt-4">
                          <v-row wrap no-gutters class="mb-8">
                            <!-- MEETING DURATION -->
                            <v-col class="text-center">
                              <v-row
                                wrap
                                no-gutters
                                class="pa-0 ma-0 justify-center"
                              >
                                <span class="title font-weight-bold">
                                  {{ meetingEndByMeetingDuration() }}
                                </span>
                              </v-row>

                              <v-row
                                wrap
                                no-gutters
                                class="pa-0 ma-0 justify-center"
                              >
                                <span class="caption font-weight-bold">
                                  {{ $t("meetings.meetingDuration") }}:
                                  <span class="caption font-weight-bold ml-1">
                                    {{
                                      meeting.duration + $t("meetings.minutes")
                                    }}
                                  </span>
                                </span>
                              </v-row>

                              <v-btn
                                small
                                color="almost_black"
                                depressed
                                outlined
                                class="mt-4"
                                @click="
                                  updateMeetingStatusDuration(
                                    topicsTotalDurations()
                                  )
                                "
                              >
                                {{
                                  increaseMeetingDurationString(
                                    topicsTotalDurations()
                                  )
                                }}
                              </v-btn>
                            </v-col>

                            <!-- ICON MIDDLE -->
                            <v-col
                              class="d-flex text-center"
                              shrink
                              justify="center"
                              align="center"
                            >
                              <v-icon large color="mid_grey" class="mx-auto">
                                mdi-arrow-right-bold
                              </v-icon>
                            </v-col>

                            <!-- TOPICS DURATION -->
                            <v-col class="text-center">
                              <v-row
                                wrap
                                no-gutters
                                class="pa-0 ma-0 justify-center"
                              >
                                <span
                                  class="title error--text font-weight-bold"
                                >
                                  {{ meetingEndByTopicsDurations() }}
                                </span>
                              </v-row>

                              <v-row
                                wrap
                                no-gutters
                                class="pa-0 ma-0 justify-center"
                              >
                                <span class="caption font-weight-bold">
                                  {{ $t("meetings.topicsTotalDuration") }}:
                                  <span
                                    class="caption font-weight-bold error--text"
                                  >
                                    {{
                                      topicsTotalDurations() +
                                      $t("meetings.minutes")
                                    }}
                                  </span>
                                </span>
                              </v-row>
                              <v-btn
                                small
                                outlined
                                color="almost_black"
                                depressed
                                class="mt-4"
                                @click="openMeetingAgenda()"
                              >
                                {{
                                  shrinkAllTopicDurationsString(
                                    meeting.duration
                                  )
                                }}
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row
                            wrap
                            no-gutters
                            class="text-center caption pt-2"
                          >
                            <v-col cols="12">
                              <v-alert
                                color="amber darken-2"
                                dark
                                dense
                                icon="mdi-information"
                                border="left"
                                class="caption"
                              >
                                {{
                                  $t(
                                    "meetings.meetingEndTimeConflictDescription"
                                  )
                                }}</v-alert
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>

                  <!-- Topic duration button -->
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      rounded
                      text
                      x-small
                      class="topicDuration caption font-weight-bold white--text"
                      @click="editTopicDuration(topic)"
                      v-show="meeting.timed_topics"
                    >
                      <v-icon
                        size="13"
                        class="pr-1"
                        style="padding-bottom: 1px"
                        >{{
                          hover ? "mdi-pencil" : "mdi-progress-clock"
                        }}</v-icon
                      >
                      {{ topic.duration }}{{ $t("meetings.minutes") }}
                    </v-btn>
                  </v-hover>
                </div>
              </v-col>
            </transition-group>
          </draggable>
        </v-row>
      </div>

      <!-- ADD TOPIC -->
      <div
        class="
          timeline_lighter_blue
          white--text
          text-center
          d-flex
          align-center
          justify-center
        "
      >
        <v-tooltip bottom color="timeline_blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="timeline_lighter_blue white--text"
              depressed
              tile
              @click="openNewTopicDialog"
              v-bind="attrs"
              v-on="on"
              width="100%"
              height="100%"
            >
              <v-icon size="18">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("followUps.addTopicsToMeeting") }}</span>
        </v-tooltip>
      </div>
    </div>

    <!-- NEW TOPIC DIALOG -->
    <!-- <v-dialog v-model="newTopicDialog" :max-width="480" persistent>
      <template v-slot:default>
        <v-card color="white">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ $t("meetings.newTopic") }}</span>
            <v-btn icon @click="newTopicDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-0">
            <topicFormFields
              v-if="newTopicDialog"
              :displayBackButton="false"
              @close="newTopicDialog = false"
            />
          </v-card-text>
        </v-card>
      </template>
    </v-dialog> -->

    <!-- UPDATE TOPIC DURATION DIALOG -->
    <v-dialog v-model="updateTopicDuration_dialog" :max-width="320" persistent>
      <template v-slot:default>
        <v-card color="white">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ $t("meetings.topicUpdate") }}</span>
            <v-btn icon @click="updateTopicDuration_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-0">
            <v-card-text class="px-4 pb-6">
              <v-text-field
                v-model.number="updateTopicDuration_settings.duration"
                :label="$t('meetings.durationMinutes')"
                placeholder=""
                filled
                dense
                autocomplete="off"
                type="number"
                :rules="[
                  cantBeNegative(true),
                  cantBeNull(meeting.timed_topics),
                ]"
                hide-details="auto"
                min="0"
                step="1"
                :disabled="!meeting.timed_topics"
                :hint="
                  !meeting.timed_topics ? $t('meetings.youNeedTimedTopics') : ''
                "
                :persistent-hint="!meeting.timed_topics"
                @change="
                  updateTopicDuration_settings.duration = Math.round(
                    updateTopicDuration_settings.duration
                  )
                "
            /></v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                text
                @click="updateTopicDuration_dialog = false"
                color="almost_black"
                small
                depressed
              >
                {{ $t("misc.cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="white--text"
                depressed
                small
                :disabled="
                  !canSaveUpdateDuration || updateTopicDuration_loading
                "
                @click="updateTopicDuration"
                color="button_blue"
                :loading="updateTopicDuration_loading"
              >
                {{ $t("meetings.saveTopic") }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userAvatar from "../shared/userAvatar";
import date from "@/mixins/date.js";
import draggable from "vuedraggable";
import { dragscroll } from "vue-dragscroll";
import UPDATE_MEETING from "@/graphql/meetings/update.gql";
import rules from "@/mixins/rules";
import UPDATE_TOPIC from "@/graphql/topics/update.gql";
import topicFormFields from "@/components/topics/topicFormFields";
import meeting from "@/mixins/meeting";
import customIcon from "@/components/custom_icons/customIcon";
import iconFollowup from "@/components/custom_icons/iconFollowup";
import selectOrCreateFollowup from "@/components/followups/selectOrCreateFollowup";

export default {
  name: "meetingTimeline",
  mixins: [date, rules, meeting],
  directives: {
    dragscroll,
  },
  data() {
    return {
      passedTime: 0,
      passedTimeInterval: null,
      dragTopic: false,
      percentageDone: 0,
      currentTopicDone: false,
      newTopicDialog: false,
      updateTopicDuration_dialog: false,
      updateTopicDuration_loading: false,
      updateTopicDuration_settings: {
        topic_id: "",
        duration: 15,
      },
    };
  },
  props: {
    meetingWelcomeScreen: {
      type: Boolean,
      default: true,
    },
    timelineHovered: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    userAvatar,
    draggable,
    topicFormFields,
    customIcon,
    iconFollowup,
    selectOrCreateFollowup,
  },
  computed: {
    ...mapGetters([
      "meeting",
      "loadingMeeting",
      "meetingFetchFailed",
      "selectedTopic",
      "selectedTopicId",
      "selectedTopicIndex",
      "currentTopicIndex",
      "topicsRefresh",
    ]),
    topics: {
      get() {
        this.topicsRefresh;
        return this.meeting.topics;
      },
      set(value) {
        let topics_positions = [];

        for (var i = 0; i < value.length; i++)
          topics_positions.push({
            id: value[i].id,
            position: i + this.minPosition,
          });

        this.setTopicsPositions({ topics_positions: topics_positions });
      },
    },
    dragOptions() {
      return {
        animation: 200,

        disabled: false,
        ghostClass: "ghost",
      };
    },
    isLastTopic() {
      return this.currentTopicIndex === this.meeting.topics.length - 1;
    },
    canSaveUpdateDuration() {
      if (
        this.meeting.timed_topics &&
        this.updateTopicDuration_settings.duration < 1
      )
        return false;
      return true;
    },
    minPosition() {
      let min = Infinity;
      this.meeting.topics.forEach((e) => {
        if (e.position < min) min = e.position;
      });
      return min;
    },
    meetingStartDate() {
      if (this.meeting.started_at && this.meeting.status !== "pending")
        return new Date(this.UTCDate(this.meeting.started_at));
      return new Date(this.UTCDate(this.meeting.starts_at));
    },
  },
  methods: {
    ...mapActions([
      "setSelectedTopicId",
      "setTopicsPositions",
      "destroyResourceWithDialog",
    ]),
    deleteTopic(topicId) {
      this.destroyResourceWithDialog({
        //this.openDestroyDialog({
        id: topicId,
        model: "topic",
        onDestroy: () => {
          this.editTopicDialog = false;
        },
        //title: this.$t("meetings.topicRemoveAlertTitle"),
        //message: this.$t("meetings.topicRemoveAlertMessage"),
      });
    },
    openTopicDialog(topic) {
      this.$emit("openTopicDialog", topic);
    },
    topicProgressBarColor(topic, topicIndex) {
      if (
        !this.meeting.timed_topics &&
        topicIndex == this.currentTopicIndex &&
        (this.meeting.status == "started" || this.meeting.status == "paused")
      )
        return "rgba(255,255,255,0.08)";
      return topicIndex > this.currentTopicIndex &&
        this.topicProgressBarValue(topic, topicIndex) != 0
        ? "amber darken-2"
        : "button_blue";
    },
    topicProgressBarValue(topic, topicIndex) {
      // Non timed topics
      if (this.meeting.timed_topics == false) {
        return topicIndex < this.currentTopicIndex ? 100 : 0;
      } else {
        var totalPreviousTopicsDuration = this.meeting.topics
          .map((t) => t.duration * 60)
          .slice(0, topicIndex)
          .reduce((pv, cv) => pv + cv, 0);

        var topicProgressBarValue =
          ((this.passedTime - totalPreviousTopicsDuration) /
            (topic.duration * 60)) *
          100;

        return Math.max(0, Math.min(100, topicProgressBarValue));
      }
    },
    initPassedTimeInterval() {
      this.passedTimeInterval = setInterval(() => {
        var now = Date.now();
        var start = new Date(this.meeting.started_at).getTime();

        // No progress if meeting not started yet
        if (this.meeting.status == "pending") now = start;
        this.passedTime = Math.round((now - start) / 1000);
      }, 1000);
    },
    increaseMeetingDurationString(duration) {
      return this.$t("meetings.increaseMeetingDurationTo").replace(
        "<minutes>",
        duration
      );
    },
    shrinkAllTopicDurationsString(duration) {
      return this.$t("meetings.shrinkAllTopicDurations").replace(
        "<minutes>",
        duration
      );
    },
    meetingEndTime() {
      if (this.meeting.timed_topics) {
        return this.meetingEndByTopicsDurations();
      }
      if (this.meeting.duration) return this.meetingEndByMeetingDuration();

      return "";
    },
    topicsTotalDurations() {
      return this.meeting.topics
        .map((t) => t.duration)
        .reduce((pv, cv) => pv + cv, 0);
    },
    meetingEndByTopicsDurations() {
      var ttd = this.topicsTotalDurations();
      return this.addMinutesToTime(this.meetingStartDate, ttd);
    },
    meetingEndByMeetingDuration() {
      return this.addMinutesToTime(
        this.meetingStartDate,
        this.meeting.duration
      );
    },
    meetingEndConflict() {
      // Detect if there is a conflict between topics duration and meeting duration
      if (this.meeting.timed_topics) {
        if (this.meeting.duration) {
          if (this.meeting.duration < this.topicsTotalDurations()) return true;
        }
      }
      return false;
    },
    openMeetingAgenda() {
      this.$emit("openMeetingAgenda");
    },
    onTimelineItemsWrapperResize() {
      // const el_child = document.getElementById("timelineItemsWrapper");
      // if (el_child) {
      //   this.showAvatars
      // }
    },
    editTopicDuration(topic) {
      this.updateTopicDuration_dialog = true;
      this.updateTopicDuration_settings = {
        topic_id: topic.id,
        duration: topic.duration,
      };
    },
    updateMeetingStatusDuration(duration) {
      this.$apollo
        .mutate({
          mutation: UPDATE_MEETING,
          variables: { id: this.meeting.id, duration: duration },
        })
        .then(({ data }) => {
          this.sendSuccess(
            this.$t("meetings.meetingDurationUpdated").replace(
              "<minutes>",
              duration
            )
          );
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    timelineTopicWidth(topic) {
      return 100 / this.meeting.topics.length;
    },
    timelineTopicDoneButtonDisabled(topic_id, index) {},
    timelineTopicColor(topic_id, index) {
      if (topic_id === this.selectedTopicId) return "#4a619e";

      if (this.currentTopicIndex == index) return "#27355a";

      return "timeline_blue";
    },
    timelineTopicLightMode(topic_id, index) {
      if (topic_id === this.selectedTopicId) return false;

      return index > this.currentTopicIndex ? true : false;
    },
    openNewTopicDialog() {
      this.$emit("openNewTopicDialog");
    },
    topicStartAt(topic, topicIndex) {
      var duration_sum = this.meeting.topics
        .map((t) => t.duration)
        .slice(0, topicIndex)
        .reduce((pv, cv) => pv + cv, 0);

      return this.hoursMinutes(
        this.meetingStartDate.getTime() + duration_sum * 60000
      );
    },
    updateTopicDuration() {
      this.updateTopicDuration_loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_TOPIC,
          variables: {
            id: this.updateTopicDuration_settings.topic_id,
            duration: this.updateTopicDuration_settings.duration,
          },
        })
        .then(({ data }) => {
          this.updateTopicDuration_loading = false;
          this.updateTopicDuration_dialog = false;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.updateTopicDuration_loading = false;
        });
    },
    updateTopicsOrder({ added, removed, moved }) {
      if (!moved) return;
      this.$apollo
        .mutate({
          mutation: UPDATE_TOPIC,
          variables: {
            id: moved.element.id,
            position: moved.newIndex + this.minPosition,
          },
        })
        .then(() => {})
        .catch((error) => {
          this.sendError(error);
        });
    },
    topicDuration() {
      if (!this.meeting.current_topic_id) return -1;
      return (
        this.meeting.topics.find((e) => e.id === this.meeting.current_topic_id)
          .duration * 60
      );
    },
  },
  created() {
    this.setMeetingStatus();
    this.initPassedTimeInterval();
  },
  beforeDestroy() {
    clearInterval(this.passedTimeInterval);
  },
};
</script>

<style  scoped>
.timelineGridWrapper {
  display: grid;
  grid-template-columns: 56px 1fr 56px;
  grid-gap: 0px;
  width: 100%;
  height: 100%;
}

.timeline-progress {
  top: 100%;
  position: sticky;
}

.timelineTopic {
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  height: 52px;
  min-width: 200px;
}
.hoverOnTopic {
  min-width: content;
}

.timelineWelcomeTopic {
  height: 52px;
}
.timelineTopicName {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dotStart {
  width: 2px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 6px;
  left: 0px;
}
.dotEnd {
  width: 2px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 6px;
  left: 100%;
}
.topicStartTime {
  position: absolute;
  top: 14px;
  left: 0px;
}

.meetingCalculatedEndtime {
  position: absolute;
  top: 14px;
  right: 0px;
}

.topicDuration {
  position: absolute;
  top: 15px;
  left: calc(50% - 22px);
  opacity: 0.5;
}

.topicDuration:hover {
  opacity: 1;
}

.goToNextButton {
  position: absolute;
  top: 20px;
  right: 8px;
}

.flip-list {
  transition: transform 1s;
}
.ghost {
  opacity: 0.4;
  background-color: transparent;
}
.clock {
  position: absolute;
  right: 6px;
  top: 2px;
}
.timelineItemsWrapper {
  display: inline;
  overflow: overlay;
  margin-top: 14px;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: grab;
  padding-right: 1em;
}
.timelineItemsWrapper:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: grabbing;
}

.topicDone {
  opacity: 0.35;
}

.topicBubble {
  height: 38px;
  max-height: 38px;
  min-height: 38px;
}

.currentTopic {
  /* background-color: #27355a !important; */
}
</style>

<style>
</style>
