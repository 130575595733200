<template>
  <v-dialog v-model="dialog" width="640" scrollable>
    <v-card>
      <v-sheet dark class="px-4 pb-2 pt-2 button_blue darken-3">
        <v-container class="pa-0">
          <v-row
            wrap
            no-gutters
            class="pl-1 justify-space-between align-center"
          >
            <span>
              <v-btn v-if="selectedStep == 2" icon @click="selectedStep = 1">
                <v-icon>mdi-arrow-left-bold</v-icon> </v-btn
              >{{ formTitle }}</span
            >
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>

          <v-expand-transition>
            <v-row wrap no-gutters v-show="selectedStep == 1">
              <v-text-field
                dark
                v-model="search"
                :label="$t('followUps.followUpName')"
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="py-2"
                @input="handleSearch"
              ></v-text-field>
            </v-row>
          </v-expand-transition>
        </v-container>
      </v-sheet>

      <v-card-text class="pt-4">
        <v-window v-model="selectedStep">
          <v-window-item :value="1">
            <template v-if="followups.length">
              <v-treeview
                :items="followUpsTree"
                :search="search"
                :filter="treeFilter"
                :open.sync="treeViewOpened"
                activatable
                open-on-click
                dense
                ref="tree"
                :open-all="search != ''"
                return-object
                @update:active="handleClic($event)"
              >
                <template v-slot:label="{ item }">
                  <v-list-item dense>
                    <v-list-item-icon class="mr-1" v-if="item.children">
                      <v-icon
                        v-text="item.icon"
                        :class="item.iconColor + '--text'"
                        small
                      ></v-icon>
                    </v-list-item-icon>

                    <v-list-item-icon
                      class="mr-1"
                      v-else
                      style="align-items: center; padding-bottom: 4px"
                    >
                      <userAvatar
                        :size="16"
                        :show_name_in_tooltip="true"
                        :first_name="item.data.user.first_name"
                        :last_name="item.data.user.last_name"
                        :avatar_url="item.data.user.avatar_url"
                        :email="item.data.user.email"
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="!item.children" class="my-0">
                      <!-- <v-btn
                    depressed
                    class="grey--text text--darken-1"
                    color="light_grey"
                    small
                    ><v-icon small left>mdi-plus</v-icon
                    >{{ $t("followUps.addTopicToMeeting") }}</v-btn
                  > -->
                      <v-chip
                        v-if="item.data.project_id"
                        small
                        label
                        color="grey lighten-4"
                      >
                        <v-icon
                          size="13"
                          class="mr-2"
                          :color="item.data.project_color"
                          >mdi-folder</v-icon
                        >
                        {{ item.data.project_name }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-treeview>

              <!-- <json-viewer :value="followUpsTree" /> -->
            </template>
          </v-window-item>
          <v-window-item :value="2">
            <v-subheader class="pl-1">
              {{ $t("followUps.newFollowupTitle") }}
            </v-subheader>
            <v-text-field
              v-model="newFollowupName"
              :label="$t('misc.title')"
              solo
              filled
              dense
              flat
              autocomplete="off"
            />

            <v-subheader class="pl-1">
              {{ $t("followUps.addToProject") }}
            </v-subheader>

            <v-autocomplete
              v-model="newFollowUpProjectId"
              :items="projects"
              item-value="id"
              item-text="name"
              dense
              filled
              clearable
              :label="$t('projects.project')"
            >
              <template v-slot:item="data">
                <v-list-item-icon>
                  <customIcon
                    :class="data.item.color + '--text'"
                    name="project"
                    height="16"
                    style="position: relative; top: 5px"
                    ><iconProject
                  /></customIcon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{ data.item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-window-item>
        </v-window>

        <!-- Visibility message -->
        <v-alert
          type="info"
          elevation="0"
          class="mx-0 mt-2 mb-2 caption"
          color="purple_alert"
        >
          <span class="caption purple_alert_text--text">
            {{ $t("followUps.followUpVisibleMsgAddTopic") }}
          </span>

          <template slot="prepend">
            <v-icon class="mr-4" color="purple_alert_text"
              >mdi-information</v-icon
            >
          </template>
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="dialog = false" color="almost_black">
          <v-icon class="pr-1" small>mdi-close</v-icon>
          {{ $t("misc.close") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          @click="goToStep2()"
          color="button_blue"
          class="white--text"
          v-if="selectedStep == 1"
        >
          <v-icon class="pr-1" small>mdi-plus</v-icon>
          {{ $t("followUps.createANewFollowUp") }}
        </v-btn>

        <v-btn
          depressed
          :disabled="!newFollowupName"
          @click="saveTopic('')"
          color="success"
          class="white--text"
          v-if="selectedStep == 2"
        >
          <v-icon class="pr-1" small>mdi-plus</v-icon>
          {{ $t("followUps.createANewFollowUp") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userAvatar from "../shared/userAvatar";
import CREATE_TOPIC from "@/graphql/topics/create.gql";
import UPDATE_FOLLOW_UP from "@/graphql/follow_ups/update.gql";
import FOLLOW_UP_INDEX from "@/graphql/follow_ups/index.gql";
import { mapActions, mapGetters } from "vuex";
import customIcon from "@/components/custom_icons/customIcon";
import iconProject from "@/components/custom_icons/iconProject";
import PROJECT_INDEX from "@/graphql/projects/index.gql";

export default {
  name: "selectOrCreateFollowup",
  mixins: [],
  components: {
    userAvatar,
    customIcon,
    iconProject,
  },
  props: {},
  data() {
    return {
      dialog: false,
      search: "",
      loading: false,
      followups: [],
      projects: [],
      treeViewOpened: [],
      treeViewAllOpened: false,
      treeViewLastOpened: [],
      selectedStep: 1,
      newFollowupName: "",
      newFollowUpProjectId: null,
    };
  },
  computed: {
    ...mapGetters([
      "meeting",
      "meetingUsers",
      "selectedOrganisationId",
      "currentUser",
    ]),
    formTitle() {
      if (this.selectedStep == 1) {
        return this.$t("followUps.addTopicsToMeeting");
      }

      if (this.selectedStep == 2) {
        return this.$t("followUps.newFollowup");
      }
    },
    treeFilter() {
      return false
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    availableFollowUps() {
      // return followups which are not already added to current meeting
      const follow_up_ids = this.meeting.topics.map((t) => t.follow_up_id);

      return this.followups.filter((f) => {
        return follow_up_ids.includes(f.id) == false;
      });
    },
    followUpsTree() {
      var tree = [];

      // Tree will have the following structure:
      // UNIQUE IDS FOR EACH NODES ARE **VERY** IMPORTANT
      // Recents ->[x], My Followups ->[x], Shared with me ->[x], Projects ->[Project 1 ->[x], Project 2 ->[x] ...]

      // RECENT FOLLOWUPS
      const showOnlyN = 10;
      var recentFollowups = [
        ...this.availableFollowUps.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.updated_at);
        }),
      ];

      if (recentFollowups.length > showOnlyN)
        recentFollowups.length = showOnlyN;

      if (recentFollowups.length) {
        tree.push({
          id: "recentlyUsed",
          name: this.$t("followUps.recentlyUsed"),
          icon: "mdi-history",
          iconColor: "grey",
          children: recentFollowups.map((f) => {
            return {
              id: f.id + "recentlyUsed",
              name: f.name,
              data: f,
            };
          }),
        });
      }

      // MY FOLLOWUPS
      var myFollowups = this.availableFollowUps.filter((f) => {
        return f.user_id == this.currentUser.id;
      });
      if (myFollowups.length) {
        tree.push({
          id: "mySubjects",
          name: this.$t("followUps.filters.mySubjects"),
          icon: "mdi-folder-text-outline",
          iconColor: "grey",
          children: myFollowups.map((f) => {
            return {
              id: f.id + "mySubjects",
              name: f.name,
              data: f,
            };
          }),
        });
      }

      // SHARED WITH ME
      var sharedWithMe = this.availableFollowUps.filter((f) => {
        return f.user_id != this.currentUser.id;
      });
      if (sharedWithMe.length) {
        tree.push({
          id: "sharedWithMe",
          name: this.$t("followUps.filters.sharedWithMe"),
          icon: "mdi-share-variant",
          iconColor: "grey",
          children: sharedWithMe.map((f) => {
            return {
              id: f.id + "sharedWithMe",
              name: f.name,
              data: f,
            };
          }),
        });
      }

      // PROJECTS
      // ROOT PROJECT NODE
      var projectTree = {
        id: "projects",
        name: this.$t("projects.projects"),
        icon: "mdi-folder-multiple",
        iconColor: "grey",
        children: [],
      };

      var p_data = null;

      this.projects.forEach((p) => {
        p_data = this.availableFollowUps.filter((f) => {
          return f.project_id == p.id;
        });
        if (p_data.length) {
          projectTree.children.push({
            id: p.id,
            name: p.name,
            icon: "mdi-folder",
            iconColor: p.color,
            children: p_data.map((f) => {
              return {
                id: f.id + p.id,
                name: f.name,
                data: f,
              };
            }),
          });
        }
      });

      if (projectTree.children.length) tree.push(projectTree);

      return tree;
    },
  },
  methods: {
    ...mapActions(["addTopic"]),
    handleSearch: function (val) {
      if (val) {
        // if search -> open all nodes, save the previous state of nodes
        if (!this.treeViewAllOpened) {
          console.log("treeViewAllOpened TRUE");
          this.treeViewLastOpened = this.treeViewOpened;
          this.treeViewAllOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        console.log("treeViewAllOpened FALSE");
        // ell close all nodes and re open the saved ones before the search to get back to previous state (pré search)
        this.$refs.tree.updateAll(false);
        this.treeViewAllOpened = false;
        this.treeViewOpened = this.treeViewLastOpened;
      }
    },

    handleClic($event) {
      console.log("Handle click on node", $event);
      // if (!$event.children) {
      // Not a parent node -> This is a follow-up
      if ($event.length && !$event[0].children)
        this.saveTopic($event[0].data.id);
      // }
    },
    goToStep2() {
      this.selectedStep = 2;
      this.newFollowupName = this.search;
    },
    saveTopic(follow_up_id) {
      if (this.loading) return;

      this.loading = true;

      let variables = {};

      // New followup
      if (this.selectedStep == 2 && !follow_up_id) {
        variables = {
          subject: this.newFollowupName,
          details: "",
          duration: 15,
          meeting_id: this.meeting.id,
          follow_up_id: "",
          // user_id: this.currentUser.id,
          project_id: this.newFollowUpProjectId,
        };
      }
      // Selected in tree view
      else if (follow_up_id) {
        variables = {
          duration: 15,
          meeting_id: this.meeting.id,
          follow_up_id: follow_up_id,
          // user_id: this.currentUser.id,
        };
      }

      this.$apollo
        .mutate({
          mutation: CREATE_TOPIC,
          variables: variables,
        })
        .then(({ data }) => {
          this.loading = false;
          this.sendSuccess(this.$t("followUps.addedToMeeting"));
          // console.log(data.create_topic);
          //this.getMeeting(this.meeting.id);
          //this.addTopic(data.create_topic)
          if (!follow_up_id) {
            // It was a new followup, closing form.
            this.dialog = false;
          }
          // this.resetFields();
          // this.setSelectedTopicId(data.create_topic.id)
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    getFollowups() {
      this.$apollo
        .query({
          query: FOLLOW_UP_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
          },
        })
        .then(({ data }) => {
          this.followups = data.follow_ups;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    getProjects() {
      this.$apollo
        .query({
          query: PROJECT_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
          },
        })
        .then(({ data }) => {
          this.projects = data.projects;
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    resetForm() {
      this.selectedStep = 1;
      this.search = "";
      this.treeViewOpened = [];
      this.newFollowupName = "";
      this.newFollowUpProjectId = null;
    },
  },
  created() {
    this.getFollowups();
    this.getProjects();
    this.resetForm();
  },
  watch: {
    dialog() {
      this.resetForm();
    },
  },
};
</script>
