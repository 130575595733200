<template>
  <v-card flat style="height: 100%">
    <v-subheader style="width: 100%" class="font-weight-bold">
      <v-icon small class="pr-2">mdi-text</v-icon>
      {{ $t("meetings.meetingInfos") }}
      <v-spacer></v-spacer>
      <v-btn
        depressed
        text
        color="almost_black"
        @click="startEdition()"
        class="caption"
        small
        rounded
        v-if="!editMode"
      >
        {{ $t("meetings.editMeetingInfos") }}
        <v-icon color="button_blue" class="pl-2" size="14"
          >mdi-pencil</v-icon
        ></v-btn
      >

      <v-btn
        v-else
        depressed
        color="button_blue"
        c
        class="white--text caption"
        @click="cancelEdition()"
        small
        rounded
      >
        {{ $t("meetings.editDone") }}
        <v-icon class="pl-2" size="14">mdi-pencil</v-icon></v-btn
      ></v-subheader
    >

    <v-card-text
      style="overflow-y: auto; height: calc(100% - 48px)"
      class="px-4 pt-0"
    >
      <!-- Title -->
      <v-col cols="12" class="pb-4">
        <v-subheader style="height: 40px" class="pl-0 caption font-weight-bold">
          <v-icon small class="pr-2">mdi-text-short</v-icon
          >{{ $t("meetings.meetingTitle") }}</v-subheader
        >

        <v-col v-if="!editMode" class="py-0 px-6">
          <span>
            {{ meeting.title ? meeting.title : $t("meetings.meetingTitle") }}
          </span></v-col
        >
        <v-text-field
          hide-details
          v-else
          v-model="meetingDetails.title"
          dense
          solo
          filled
          :rows="3"
          :placeholder="$t('meetings.meetingTitle')"
          flat
          auto-grow
          @blur="updateMeeting('title', meetingDetails.title)"
        />
      </v-col>

      <!-- Description -->
      <v-col cols="12" class="pb-4">
        <v-subheader style="height: 40px" class="pl-0 caption font-weight-bold">
          <v-icon small class="pr-2">mdi-text</v-icon
          >{{ $t("meetings.description") }}</v-subheader
        >

        <v-col v-if="!editMode" class="py-0 px-6">
          <span>
            {{
              meeting.instructions
                ? meeting.instructions
                : $t("meetings.descriptionNotDefined")
            }}
          </span></v-col
        >
        <v-textarea
          hide-details
          v-else
          v-model="meetingDetails.instructions"
          dense
          solo
          filled
          :rows="3"
          :placeholder="$t('meetings.descriptionDesc')"
          flat
          auto-grow
          @blur="updateMeeting('instructions', meetingDetails.instructions)"
        />
      </v-col>

      <!-- Location -->
      <v-col cols="12" class="mb-16">
        <v-subheader style="height: 40px" class="pl-0 caption font-weight-bold">
          <v-icon small class="pr-2">mdi-map-marker</v-icon>
          {{ $t("meetings.location") }}
        </v-subheader>

        <v-col v-if="!editMode" class="py-0 px-6">
          <span>
            {{
              meeting.location
                ? meeting.location
                : $t("meetings.locationNotDefined")
            }}
          </span></v-col
        >
        <v-text-field
          hide-details
          v-else
          v-model="meetingDetails.location"
          dense
          solo
          filled
          :placeholder="$t('meetings.locationDesc')"
          flat
          @blur="updateMeeting('location', meetingDetails.location)"
        />
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import UPDATE_MEETING from "@/graphql/meetings/update.gql";

export default {
  name: "detailsPanel",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      editMode: false,
      meetingDetails: {
        location: "",
        instructions: "",
        title: "",
      },
    };
  },
  computed: {
    ...mapGetters(["meeting"]),
  },
  methods: {
    resetDefaultData() {
      this.meetingDetails.title = this.meeting.title;
      this.meetingDetails.instructions = this.meeting.instructions;
      this.meetingDetails.location = this.meeting.location;
    },
    cancelEdition() {
      this.editMode = false;
      this.resetDefaultData();
    },
    startEdition() {
      this.editMode = true;
      this.resetDefaultData();
    },
    updateMeeting(field, value) {
      if (this.loading) return;
      if (this.meeting[field] === value) return;
      this.loading = true;
      let vars = { id: this.meeting.id };
      vars[field] = value;
      this.$apollo
        .mutate({
          mutation: UPDATE_MEETING,
          variables: vars,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.sendError(e);
          console.log(e);
        });
    },
  },
  mounted() {
    this.resetDefaultData();
  },
  watch: {},
};
</script>

<style scoped>
</style>

<style>
</style>

