<template>
  <v-card flat style="height: 100%">


    <v-subheader class="font-weight-bold">
      <v-icon small class="pr-2">mdi-account-multiple</v-icon>
      {{ $t("meetings.participants") }} ({{ meetingUsers.length }})
    </v-subheader>
    <v-row no-gutters wrap class="px-4">
      <searchAndSelectUsers
        :disabledIds="[currentUser.id]"
        :selectedUsersIds="
          meeting && meeting.meeting_users
            ? meeting.meeting_users.map((mu) => mu.user.id)
            : []
        "
        @onUserSelected="addMeetingUser"
        @onUserUnselected="eventMeetingUserUnselected"
        :selectedMessage="$t('meetings.invitedToMeeting')"
      />
    </v-row>

    <v-card-text
      v-if="meeting && meeting.meeting_users && meeting.meeting_users.length"
      style="overflow-y: auto; height: calc(100% - 186px)"
      class="d-flex align-center flex-column pa-0"
    >
      <v-list dense avatar class="py-0" style="width: 100%">
        <userItem
          v-for="meeting_user in meeting.meeting_users"
          :key="meeting_user.id"
          :user="meeting_user.user"
          dense
        >
          <template v-slot:action>
            <v-tooltip right color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="meeting_user.user.id != currentUser.id"
                  icon
                  @click="removeUserFromMeeting(meeting_user)"
                  :disabled="userQueryLoading"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="error" small>mdi-account-minus</v-icon>
                </v-btn>

                <v-btn
                  v-if="meeting_user.user.id === currentUser.id"
                  icon
                  @click="leaveMeeting(meeting_user)"
                  :disabled="userQueryLoading"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="amber darken-2" small> mdi-exit-run </v-icon>
                </v-btn>
              </template>
              <span>{{
                meeting_user.user.id === currentUser.id
                  ? $t("meetings.leave")
                  : $t("meetings.removeUser")
              }}</span>
            </v-tooltip>
          </template>
        </userItem>
      </v-list>
    </v-card-text>

     <v-row no-gutters wrap class="px-4">
       <v-col cols="12">  <!-- Visibility message -->
      <v-alert
        type="info"
        elevation="0"
        class="mx-0 mt-2 mb-2 caption"
        color="purple_alert"
      >
        <span class="caption purple_alert_text--text">
          {{ $t("followUps.followUpVisibleMsgAddMember") }}
        </span>

        <template slot="prepend">
          <v-icon class="mr-4" color="purple_alert_text"
            >mdi-information</v-icon
          >
        </template>
      </v-alert></v-col>

    </v-row>

  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import searchAndSelectUsers from "@/components/users/searchAndSelectUsers";
import userItem from "@/components/users/userItem";
import CREATE_MEETING_USER from "@/graphql/meeting_users/create.gql";
import * as api from "@/services/no-meetings.api";
export default {
  name: "participantsPanel",
  mixins: [],
  components: { searchAndSelectUsers, userItem },
  props: {},
  data() {
    return {
      userQueryLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "meeting",
      "meetingUsers",
      "meetingFetchFailed",
    ]),
    selectedUsersIds() {
      return this.meeting.meeting_users.map((u) => u.user.id);
    },
  },
  methods: {
    ...mapActions(["openDestroyDialog"]),

    leaveMeeting(meeting_user) {
      this.openDestroyDialog({
        id: meeting_user.id,
        model: "meeting_user",
        onDestroy: () => {
          this.$router.push({ name: "meetings_index" });
        },
        title: this.$t("meetings.leaveAlertTitle"),
        message: this.$t("meetings.leaveAlertMessage"),
      });
    },
    async removeUserFromMeeting(meeting_user) {
      console.log(" remove meeting user " + meeting_user.id);
      this.loading = true;
      try {
        //this.meetingSettings.objectives.splice(index, 1);
        this.loading = true;
        const { data } = await api.destroy(meeting_user.id, "meeting_user");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.sendError(err);
        console.error(err);
      }
    },
    addMeetingUser(user) {
      if (this.userQueryLoading) return;
      this.userQueryLoading = true;
      console.log("Create meeting user" + user);
      this.$apollo
        .mutate({
          mutation: CREATE_MEETING_USER,
          variables: {
            meeting_id: this.meeting.id,
            user_id: user.id,
          },
        })
        .then((data) => {
          this.userQueryLoading = false;
          console.log("meeting user created" + data.meeting_user.id);
        })
        .catch(() => {
          this.userQueryLoading = false;
        });
    },
    eventMeetingUserUnselected(user) {
      let meeting_user = this.meeting.meeting_users.find(
        (e) => e.user.id == user.id
      );
      if (!meeting_user) return;

      /* TODO. handle remove himself */
      if (meeting_user.user.id === this.currentUser.id) return;

      this.removeUserFromMeeting(meeting_user);
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
</style>

<style>
</style>

