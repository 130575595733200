<template>
  <v-card>
    <!-- Form Title and close button -->
    <v-card-title class="title font-weight-regular justify-space-between">
      <span>{{ $t("templates.loadTemplate") }}</span>
      <v-btn icon @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <template v-if="!loading">
      <v-card-text>
        <v-row wrap no-gutters>
          <v-col cols="12" v-if="templates.length === 0">
            <span class="caption">{{ $t("templates.empty") }}</span>
          </v-col>
          <v-col cols="12" class="px-2" v-else>
            <v-autocomplete
              :disabled="loading"
              :loading="loading"
              v-model="selectedTemplateId"
              :items="templates"
              item-value="id"
              item-text="name"
              dense
              filled
              :label="
                loading
                  ? $t('templates.loadingTemplates')
                  : $t('templates.selectTemplate')
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <template v-else>
      <!--  Loading -->

      <v-card-text class="text-xs-center d-flex justify-center pa-8">
        <v-row wrap no-gutters>
          <v-col class="text-xs-center d-flex justify-center" cols="12"
            ><v-progress-circular
              color="button_blue"
              indeterminate
              size="48"
              width="4"
            ></v-progress-circular
          ></v-col>
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-title class="pa-0 pt-4">
              {{ $t("templates.loadingTemplates") }}
            </v-card-title></v-col
          >
          <v-col class="text-xs-center d-flex justify-center" cols="12">
            <v-card-subtitle class="pa-0">
              {{ $t("misc.pleaseWait") }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        text
        @click="closeDialog()"
        color="almost_black"
        :disabled="loading"
      >
        <v-icon class="pr-1" small>mdi-close</v-icon>
        {{ $t("misc.cancel") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!canApplyTemplate || loading"
        color="success"
        class="white--text"
        depressed
        @click="applyTemplate"
      >
        {{ $t("templates.loadTemplate") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import APPLY_TEMPLATE_MEETING from "@/graphql/meetings/apply_template.gql";
import MEETING_TEMPLATES_INDEX from "@/graphql/meeting_templates/paginated_index.gql";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "meetingForm",
  props: ["miniVariant"],
  data() {
    return {
      loading: false,
      templates: [],
      selectedTemplateId: "",
    };
  },
  computed: {
    ...mapGetters(["meetingId", "selectedOrganisationId"]),
    canApplyTemplate() {
      return !!this.selectedTemplateId;
    },
  },
  methods: {
    ...mapActions(["getMeeting"]),
    closeDialog() {
      this.$emit("closeDialog");
    },
    applyTemplate() {
      if (this.loading) return;
      this.loading = true;
      console.log("applying template vasr: ", {
        id: this.meetingId,
        meeting_template_id: this.selectedTemplateId,
      });
      this.$apollo
        .mutate({
          mutation: APPLY_TEMPLATE_MEETING,
          variables: {
            id: this.meetingId,
            meeting_template_id: this.selectedTemplateId,
          },
        })
        .then(({ data }) => {
          this.closeDialog();
          this.loading = false;
          this.getMeeting(this.meetingId);
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
          this.loading = false;
        });
    },
    loadTemplates() {
      this.loading = true;
      console.log("loading templates");
      this.$apollo
        .query({
          query: MEETING_TEMPLATES_INDEX,
          variables: {
            filter: `organisation_id == '${this.selectedOrganisationId}'`,
            page: 1,
            per_page: -1,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.templates = data.paginated_meeting_templates.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadTemplates();
  },
};
</script>
