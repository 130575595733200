<template>
  <v-card
    style="height: calc(100vh - 96px); width: calc(100% - 56px)"
    class="pa-0"
    color="light_grey"
  >
    <v-row no-gutters wrap style="height: 100%">
      <v-col cols="auto">
         <meetingMinuteEditor @updateMinuteOptions="updateMinuteOptions" />
      </v-col>
      <v-col>
        <minute @close="$emit('close')" ref="minute" :meeting="meeting" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import meetingMinuteEditor from "@/components/minutes/meetingMinuteEditor";
import minute from "@/components/minutes/minute";

export default {
  name: "minutePanel",
  mixins: [],
  components: {
    meetingMinuteEditor,
    minute,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["meeting"]),
  },
  methods: {
      updateMinuteOptions(event) {
      this.$refs.minute.minuteSettings.show_logo = event.show_logo;
      this.$refs.minute.minuteSettings.show_summary_decisions =
        event.show_summary_decisions;
      this.$refs.minute.minuteSettings.show_summary_tasks =
        event.show_summary_tasks;
      this.$refs.minute.minuteSettings.show_meeting_objectives =
        event.show_meeting_objectives;
      this.$refs.minute.minuteSettings.show_participants_list =
        event.show_participants_list;
      this.$refs.minute.minuteSettings.show_attendees_record =
        event.show_attendees_record;
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>
</style>

<style>
</style>

