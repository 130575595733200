<template>
  <v-card tile style="width: 320px; height: 100%;" >
    <v-subheader   class="font-weight-bold">
      <v-icon small class="pr-2">mdi-clipboard-text-outline</v-icon>
      {{ $t("meetingMinutes.minuteSettings") }}</v-subheader
    >
    <v-list dense nav flat class="py-0">
      <!-- LOGO -->

      <v-list-item class="minutesSettingsListItemHeader mt-0 pt-2">
        <v-list-item-content class="py-0">
          <v-list-item-title class="font-weight-bold">
            {{ $t("meetingMinutes.companyLogo") }}
          </v-list-item-title>
          <!-- <v-list-item-subtitle class="caption">
            <i>salomon_logo.png</i>
          </v-list-item-subtitle> -->
        </v-list-item-content>
        <v-list-item-action style="align-items: center; flex-direction: row">
          <!-- <v-btn x-small button_blue text color="button_blue">
            <v-icon x-small class="pr-1">mdi-cloud-upload</v-icon>
            {{ $t("meetingMinutes.importLogo") }}
          </v-btn> -->
          <v-switch
            dense
            inset
            v-model="minuteSettings.show_logo"
            class="getcollective_switch"
            :disabled="noOrganisationLogo"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <!-- SUMMARY TITLE -->
      <v-list-item class="minutesSettingsListItemHeader mt-4 pt-2">
        <v-list-item-content class="py-0">
          <v-list-item-title class="font-weight-bold">
            {{ $t("meetingMinutes.summary") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- DECISIONS -->
      <v-list-item class="minutesSettingsListItem">
        <v-list-item-content class="py-0">
          <v-list-item-title>
            {{ $t("meetingMinutes.decisions") }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            dense
            inset
            v-model="minuteSettings.show_summary_decisions"
            class="getcollective_switch"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <!-- TASKS -->
      <v-list-item class="minutesSettingsListItem">
        <v-list-item-content class="py-0">
          <v-list-item-title>
            {{ $t("meetingMinutes.tasks") }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            dense
            inset
            v-model="minuteSettings.show_summary_tasks"
            class="getcollective_switch"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <!-- PARTITIPANTS TITLE -->
      <v-list-item class="minutesSettingsListItemHeader mt-4 pt-2">
        <v-list-item-content class="py-0">
          <v-list-item-title class="font-weight-bold">
            {{ $t("meetingMinutes.participants") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- PARTITIPANTS LIST -->
      <v-list-item class="minutesSettingsListItem">
        <v-list-item-content class="py-0">
          <v-list-item-title>
            {{ $t("meetingMinutes.list") }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            dense
            inset
            v-model="minuteSettings.show_participants_list"
            class="getcollective_switch"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <!-- DETAILS TITLE -->
      <v-list-item class="minutesSettingsListItemHeader mt-4 pt-2">
        <v-list-item-content class="py-0">
          <v-list-item-title class="font-weight-bold">
            {{ $t("meetingMinutes.meetingDetails") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- OBJECTIVES -->
      <v-list-item class="minutesSettingsListItem">
        <v-list-item-content class="py-0">
          <v-list-item-title>
            {{ $t("meetingMinutes.objectives") }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            dense
            inset
            v-model="minuteSettings.show_meeting_objectives"
            class="getcollective_switch"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>

      <!-- ATTENDEES -->
      <!-- <v-list-item class="minutesSettingsListItem">
          <v-list-item-content class="py-0">
            <v-list-item-title>
              {{ $t("meetingMinutes.attendees") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
              dense
              inset
              v-model="minuteSettings.show_attendees_record"
              class="getcollective_switch"
            ></v-switch>
          </v-list-item-action>
        </v-list-item> -->
    </v-list>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import date from "@/mixins/date.js";

export default {
  name: "meetingMinuteEditor",
  mixins: [date],
  data() {
    return {
      minuteSettings: {
        show_logo: true,
        show_summary_decisions: true,
        show_summary_tasks: true,
        show_meeting_objectives: false,
        show_participants_list: true,
        show_attendees_record: true,
      },
    };
  },
  props: {},
  components: {},
  computed: {
    ...mapGetters([
      "meeting",
      "loadingMeeting",
      "meetingFetchFailed",
      "selectedTopicId",
      "selectedTopic",
      "noOrganisationLogo"
    ])
  },
  methods: {
    emitUpdate() {
      this.$emit("updateMinuteOptions", this.minuteSettings);
    },
  },
  watch: {
    "minuteSettings.show_logo"() {
      this.emitUpdate();
    },
    "minuteSettings.show_summary_decisions"() {
      this.emitUpdate();
    },
    "minuteSettings.show_summary_tasks"() {
      this.emitUpdate();
    },
    "minuteSettings.show_meeting_objectives"() {
      this.emitUpdate();
    },
    "minuteSettings.show_participants_list"() {
      this.emitUpdate();
    },
    "minuteSettings.show_attendees_record"() {
      this.emitUpdate();
    },
  },
  created() {
    this.minuteSettings.show_logo = !this.noOrganisationLogo;
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.minutesSettingsListItem {
  max-height: 28px;
  height: 28px;
  min-height: 28px;
  margin-bottom: 0px;
}
.minutesSettingsListItemHeader {
  max-height: 32px;
  height: 32px;
  min-height: 32px;
  margin-bottom: 0px;
}
</style>

